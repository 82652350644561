.quick-questions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
}

.question {
    margin-top: 20px;
    margin-bottom: 20px;
  }

.question-wrapper > img {
    width: 20px;
    position: absolute;
    right: 15px;
    pointer-events: none;
}

.question-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    position: relative;
    margin-left: 50px;
    margin-right: 50px;
}

.question-wrapper > select {
    font-size: 16px;
    color: black !important;
    text-decoration: none;
}



.question > label {
    font-size: 16px;
    color: grey !important;
    text-align: center;
}

.select-dropdown {
    font-family: 'Arial', sans-serif;
    border: transparent;
    border-radius: 12px;
    padding: 10px 5px 10px 20px;
    background-color: #ffffff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: border-color 0.3s ease;
    cursor: pointer;
    position: relative; /* Neu hinzugefügt */
    width: 500px; /* Beispielbreite, an Ihre Bedürfnisse anpassen */
    height: 70px;
    outline: none;
  }

  @media (max-width: 700px) {
    .select-dropdown {
        font-family: 'Arial', sans-serif;
        border: transparent;
        border-radius: 13px;
        padding: 10px 30px 10px 20px;
        background-color: #ffffff;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        transition: border-color 0.3s ease;
        cursor: pointer;
        position: relative; /* Neu hinzugefügt */
        width: 90vw; /* Beispielbreite, an Ihre Bedürfnisse anpassen */
        height: 70px;
        outline: none;
      }
      .quick-questions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-bottom: 70px;
    }
    
  }