.container_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: white;

}


.container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px;
    margin: 0 50px;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    background-color: rgb(255, 255, 255);
    z-index: 5;
    min-height: 600px;

}


@keyframes BackgroundShimmer {
    0% {
        background-position: 100% 0;
    }
    50% {
        background-position: 0 100%;
    }
    100% {
        background-position: 100% 0;
    }
}

.container::before, 
.container::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 20px;
    z-index: 0;
    margin-left: 20px;
    margin-right: 20px;
    background: inherit;  /* Erben Sie den Hintergrund vom Eltern-Container */
    clip-path: polygon(0% 100%, 10% 0, 20% 100%, 30% 0, 40% 100%, 50% 0, 60% 100%, 70% 0, 80% 100%, 90% 0, 100% 100%);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.container::after {
    top: unset;
    bottom: -10px;
    transform: rotate(180deg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;


}

.container-invalid {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}

.container-invalid > h1 {
    text-align: center;
    font-size: 32px;
    color: rgb(235, 67, 67);
}


.container h1, 
.container .message, 
.container button {
    margin-bottom: 20px;
}





.container-element-wrapper > h1 {
    display: flex;
    align-items: center;
    font-size: 2.50em;
    margin-top: 30px;
    font-family: 'Voyage';
    text-align: center;
}


@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.container-element-wrapper > h1 {
    display: flex;
    align-items: center;
    font-size: 2.50em;
    margin-top: 30px;
    font-family: 'Voyage';
    text-align: center;
    color: #b3b3b3; /* Base silver color */
    background: linear-gradient(120deg, #808080, #e0e0e0, #808080);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: shimmer 2s infinite;
}



.container > h1 > span {
    font-size: 32px;
    font-family: 'Voyage';
    margin-top: 30px;
    font-weight: 400;
    color: #616161;
    color: #b3b3b3; /* Base silver color */
    background: linear-gradient(120deg, #808080, #e0e0e0, #808080);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: shimmer 2s infinite;
}






.container-element-wrapper > h3 {
    font-size: 32px;
    font-family: 'Jost';
    margin-top: 30px;
    font-weight: 400;
    color: #616161;
    text-align: center;

}

.container-element-wrapper > p {
    font-size: 16px;
    margin-bottom: 30px;
    font-weight: 300;
    margin-top: 10px;
    color: #7e7e7e;
}

.container-element-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 400px;
}


/* CSS */
.container-element-wrapper > button {
  background-color: #76c457;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 30px;
}

.container-element-wrapper > button:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}

.container-element-wrapper > img {
    margin-top: 20px;
    width: 200px !important;
    height: 200px !important;
    object-fit: cover;
    border-radius: 100px;
}

h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.message {
    font-size: 18px;
    color: #555;
}

.error {
    color: #d43f00;
}

.success {
    color: #28a745;
}


@media (max-width: 600px) {
    
   
.container-element-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    min-height: 600px;
} 

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}
}