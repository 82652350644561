.agency-menu {
    display: flex;
    justify-content: space-between;
    margin: 20px 1% 20px 1%;
}

.agency-menu-item {
    flex: 1;
    margin: 0 10px;
    padding: 20px;
    border-radius: 15px;
    text-decoration: none;
    height: 100px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(131, 131, 131, 0.08) 0px 0px 0px 1px;
}

.agency-menu-item.active {
    background-color: var(--blue);
    color: white;
    border: 1px solid var(--blue);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.agency-menu-item.active .agency-menu-icon {
    filter: invert(1);
    border: 1px solid black;
}

.agency-menu-item.active  .agency-menu-inner-bottom-font {
    color: rgb(216, 216, 216);

}

.agency-menu-inner-bottom-font {
    font-size: 13px;
    color: rgb(99, 99, 99);
}


.agency-menu-content {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.agency-menu-icon {
    width: 42px !important;
    height: 42px !important;
    padding: 8px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.01) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.agency-menu-icon > img {
    width: 20px !important;
    height: 20px !important;
}

.agency-menu-content > div > h3 {
    font-family: 'Jost';
    font-size: 13px;
}

.agency-menu-content > div > h4 {
    font-family: 'Jost';
    font-size: 10px;
    font-weight: 500;
    color: white;
    background-color: rgb(243, 70, 70);
    width: fit-content;
    padding: 2px 10px;
    margin-bottom: 5px;
    border-radius: 100px;
}

.agency-menu-content > div > div  > h3 {
    font-family: 'Jost';
    font-size: 13px;
}

.agency-menu-content > div > div > h4 {
    font-family: 'Jost';
    font-size: 8px;
    font-weight: 600;
    color: white;
    background-color: var(--blue);
    width: fit-content;
    padding: 2px 10px;
    margin-bottom: 5px;
    border-radius: 100px;
}



.agency-menu-content > div > div > h5 {
    font-family: 'Jost';
    font-size: 12px;
    position: absolute;
    top: 0px;
    padding: 10px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 20px;
    background-color: rgb(243, 70, 70);
    border-radius: 100px;
    right: -7px;
}

.agency-menu-inner {
    display: flex;
    align-items: center;
    justify-content: center;
}




.agency-menu-content > div >  div > img {
 
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 100px;
    margin-right: 10px;
}