/* ImageGallery.css */

.image-gallery-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 100%;
    margin: auto;
    overflow: hidden;
}

.image-gallery-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 100%; /* 1:1 aspect ratio */
    overflow: hidden;
}

.image-gallery-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slide-image {
    object-fit: cover;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    min-height: 405px;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-button-prev-2, .swiper-button-next-2 {
    position: absolute;
    top: 42%;
    z-index: 10;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}

.swiper-button-prev-2 {
    left: 10px;
}

.swiper-button-prev-2 > img {
    transform: rotate(180deg);
}

.swiper-button-next-2 {
    right: 10px;
}

.swiper-button-prev-2 img, .swiper-button-next-2 img {
    width: 30px;
    height: 30px;
    filter: invert(1);
}
