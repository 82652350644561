/* ServicePage.css */

.service-page-container {
    margin-top: 120px;

}

.service-page-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.service-item {
    border-bottom: 1px solid rgba(221, 221, 221, 0.664);
    padding: 10px 0;
    margin-bottom: 20px;
    border: 1px solid rgb(236, 236, 236);
    padding: 20px;
    margin: 20px;
    border-radius: 25px;
    display: flex;
    height: 450px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.service-item-middle > p {
    margin-bottom: 10px;
}


.service-item-top-category {
    padding: 4px 10px;
    border: 1px solid var(--blue);
    width: fit-content;
    border-radius: 100px;
    color: var(--blue);
    font-size: 10px;
    margin-top: 20px;
}

.service-delivery-item.selected {
    border: 1px solid #007bff;
    background-color: #ffffff;
}


.service-item-left {
    width: 38%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10 !important;

}

.service-item-middle {
    width: 35%;
    z-index: 10 !important;

    height: 100%;
}

.service-contact-expert input[type="checkbox"] {
    appearance: none;
    width: 28px;
    height: 28px;
    border: 1px solid rgb(109, 109, 109);
    border-radius: 10px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    margin-left: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.service-contact-expert input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background-color: var(--blue);
    border-radius: 100px;
    transform: scale(0);
    transition: transform 0.2s ease-in-out;
}

.service-contact-expert input[type="checkbox"]:checked::before {
    transform: scale(1);
}


.service-contact-expert {
    display: flex;
    align-items: center;
    justify-content: left;
}

.service-contact-expert > div > h2 {
    font-size: 13px;
    font-family: 'Jost';
    font-weight: 500;
    margin-bottom: 3px;
}

.service-contact-expert > div > p {
    font-size: 10px;
    margin-top: 2px;
    padding: 3px 7px;
    width: fit-content;
    background-color: black;
    border-radius: 100px;
    color: white;
} 

.service-contact-expert > img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 10px;
    margin-left: 0px;
    border-radius: 100px;
}

.service-item-right {
    width: 240px;
    display: flex;
    align-items: flex-start;
    font-family: 'Jost';
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.add-service-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-service-button-container > p {
    color: white;
    margin-left: 10px;
}

.add-service-button-container > img {
    width: 30px;
    padding: 7px;
    margin-left: 40px;
    margin-right: -5px;
    border-radius: 100px;
    filter: invert(1);
    background-color: rgba(29, 29, 29, 0.315);
}

.service-item-right > hr {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    height: 1px;
    border: none;
    background-color: rgb(255, 255, 255);
}

.category-service-page {
    margin-left: 2%;
    font-family: 'Jost';
}

.service-item-right > h4 {
    font-family: 'Jost';
    font-size: 13px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 10px;
    color: grey;
}

.service-item-right > div {
    width: 100%;
}

.service-contact-expert > input:hover {
    cursor: pointer;
}

.service-delivery-item {
    border-radius: 10px;
    border: 1px solid white;
    padding: 7px 15px;
    margin: 10px 0;
    min-width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.service-delivery-item:hover {
    cursor: pointer;
}

.service-delivery-item > span {
    font-size: 12px;
}

.service-delivery-item > div {
    display: flex;
    flex-direction: column;
}

.service-delivery-item > img {
    width: 20px;
}

.service-item-right >  div > button {
    background-color: var(--blue);
    position: absolute;
    bottom: 0;
    right: 0;
    height: 50px;
    width: 240px;
    color: white;
    font-family: 'Jost';
    font-size: 15px;


}

.service-features-inside {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 34px;
}

.service-features-inside > img {
    width: 15px;
}

.service-features-2 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-family: 'Jost';

}

.service-delivery-item > div > span {
    font-size: 14px;
    font-family: 'Jost';
    font-weight: 500;

}


.service-delivery-item > div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 7%;
    justify-content: center;
    margin-right: 10px;
}

.service-delivery-item > span {
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: right;
}

.service-delivery-item > div > p {
    color: grey !important;
    font-size: 12px;
    margin-top: -2px;
}

.service-item-middle h3 {
    margin: 10px 0 10px 0px;
    font-size: 22px;
    color: rgb(0, 0, 0);
    font-family: 'Jost';
}

.service-features-inside > p {
    font-size: 13px;
    color: #777;
    margin-left: 10px;
    font-weight: 500;
}
