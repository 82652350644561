
.digital-brandbook {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.digital-brandbook > h1 {
    font-size: 48px;
    font-family: 'Jost';
    margin-top: 130px;
}

.digital-brandbook-container {
    padding: 20px;
}

.digital-brandbook-wrapper-1 {
    border: 1px solid rgb(235, 235, 235);
    margin-top: 20px;
    border-radius: 25px;
    margin-bottom: 20px;
    padding: 20px 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.brandbook-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brandbook-content {
    margin-top: 20px;
}

.brandbook-logo {
    text-align: center;
    margin-bottom: 20px;
    width: 100px;
}

.brandbook-section {
    margin-bottom: 20px;
}

.brandbook-section h2, .brandbook-section h3 {
    margin-bottom: 10px;
}

.brandbook-section input {
    display: block;
    margin-bottom: 10px;
}

.brandbook-section div {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}

.no-brandbook-message {
    text-align: center;
    margin-top: 50px;
}

.brandbook-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
}

.brandbook-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}





@media (max-width: 1050px) {
    .digital-brandbook-wrapper-1 {
        flex-direction: column;
    }
}