.memories {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    margin-left: 20px;
    overflow-anchor: none;
}

.memories.is-loaded {
    opacity: 1;
}

.loading-spinner {
    display: none;
    /* Weitere Stile für Ihren Spinner */
}

#fake-loading-spinner-2 {
    margin-top: -97px !important;
    background-color: rgba(255, 255, 255, 0);
}

#fake-loading-spinner-premium {
    margin-top: -94px !important;
    background-color: rgb(255, 255, 255);
    display: none;
}

#fake-loading-spinner-2 > h1 {
    margin-top: -275px !important;
    margin-left: 15px;
}
.memories.is-loading .loading-spinner {
    display: block;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;  /* Dies nimmt den gesamten Bildschirm in Anspruch. */
    width: 100%;    /* Ändern Sie dies entsprechend Ihren Anforderungen. */
    position: absolute; /* Damit der Spinner über der .memories-Komponente angezeigt wird. */
    z-index: 1000;  /* Ein hoher z-index, um sicherzustellen, dass der Spinner im Vordergrund ist. */
    background-color: rgba(255, 255, 255, 0.8); /* Ein leicht transparenter Hintergrund */
}

.spinner-circle {
    /* Beispiel Spinner-Stil */
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 5px solid #000;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.grid-container {
    width: 100%; 
    margin: 0 auto;

}
.video-item video {
    max-width: 100%;  /* Stellt sicher, dass das Video seine Containerbreite nicht überschreitet */
    height: auto;     /* Behält die ursprünglichen Proportionen des Videos bei */
    display: block;
    margin: 0 auto;  
    border-radius: 15px; /* Zentriert das Video im Container */
}

.play-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 47%;
    left: 47%;
    width: 45px;
    background-color: rgba(255, 255, 255, 0.315);
}

.video-item:hover .play-button {
    background-color:rgba(255, 255, 255, 0.623) !important; /* Ersetzen Sie dies durch die gewünschte Farbe beim Überfahren */
}

.video-item:hover {
    cursor: pointer;
}

.play-button:hover {
    transform: scale(1.03);
}

.play-button-1 {
    width: 35px !important;
    padding: 10px;
}

.pause-button-1 {
    width: 35px !important;
    padding: 10px;
}

.fullscreen-media {
    display: block;
    max-height: 90%;
    border-radius: 15px !important;
    min-height: 90vh;
    animation: fade-in-2 100ms; 
}


 


@keyframes fade-in-2 {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.fullscreen-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgb(201, 86, 86);
    color: white;
}

.fullscreen-next {
 position: absolute;
 right: 20px;
 bottom: 50%;
 width: 50px;
 height: 50px;
 background-color: rgba(235, 235, 235, 0.774);
}

.fullscreen-next:hover {
    background-color: rgb(235, 235, 235);
}

.fullscreen-next > img {
    width: 15px !important;
    margin-top: 5px;
}

.fullscreen-prev > img {
    max-width: 15px !important;
    margin-top: 2px;
    transform: rotate(180deg);
}


.fullscreen-prev {
    position: absolute;
    left: 20px;
    bottom: 50%;
    width: 50px;
    height: 50px;
    background-color: rgba(235, 235, 235, 0.774);
   }
   
   .fullscreen-prev:hover {
       background-color: rgb(235, 235, 235);
   }

.creator-fullscreen {
    position: absolute;
    left: 4.0%;
    bottom: 4.5%; /* oder der gewünschte Abstand vom unteren Rand */
    color: white;
    padding: 10px;

}

.creator-fullscreen-video {
    position: absolute;
    left: 1.0%;
    bottom:4.5%; /* oder der gewünschte Abstand vom unteren Rand */
    color: white;
    padding: 10px;
}

#video-fullscreen {
    bottom: 13%;
    left: 0%;
}

.creator-fullscreen > div > img {
    height: 17px;
    margin-right: 7px;
    padding-top: 3px;
    padding-bottom: 3px;
    filter: invert(1);
}

.creator-fullscreen > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    margin: 10px;
    border-radius: 100px;
    background-color: rgba(112, 111, 111, 0.377);
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 500;
    width: fit-content;
    height: 30px;
    backdrop-filter: blur(20px);
    animation: fade-in 0.5s;
    z-index: 20;
}


.media-item img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 15px;
    transition: ease-in-out 100ms;
    object-fit: cover;
}

.media-item img:hover {
    cursor: pointer;
    transform: scale(1.005);
}

.media-item .creator {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0,0,0,0.5);
    color: rgb(235, 235, 235);
    padding: 4px 8px;
    font-size: 11px;
    border-radius: 100px;
    font-weight: 500;
}
.download-btn {
    position: absolute;
    top: 8px;
    left: 8px;
    display: none; /* hide by default */
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-decoration: none;
    font-size: 12px;
    border-radius: 100px;
    height: 35px;
}

.media-item:hover .download-btn {
    display: block;
}
.grid_download_button {
    filter: invert(1);
    width: 15px !important;
    height: 15px;
    border-radius: 0px !important;
    margin: 10px;
}



.media-item {
    width: calc(25% - 16px); /* adjust width based on your design preferences */
    margin-bottom: 16px; /* adjust based on your design preferences */
    position: relative;   
    min-height: 200px;



}

.favorite-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;      /* Abstand von der oberen Kante des Medienartikels */
    right: 5px;    /* Abstand von der rechten Kante des Medienartikels */
    cursor: pointer;
    z-index: 10;    
    padding: 4px 4px;
    border-radius: 100px;
    transition: 200ms ease-in-out;
}

#pop-up-icon {
    top: 7%; 
    right: 6%;
    width: 50px;
    animation: fade-in 0.5s; 

}

#video-download {
    left: 0% !important;
}

#pop-up-icon-2 {
    top: 7%; 
    right: 1%;
    width: 50px;
    animation: fade-in 0.5s; 

}



.favorite-icon:hover {
    background-color: rgba(247, 247, 247, 0.103);
}

.heart-white {
    width: 23px !important;
    height: 20px !important;
    opacity: 0.4;
    transition: smooth;

}

.heart-white:hover {
    opacity: 0.8;
}

.heart-round {
    width: 32px !important;
    height: 32px !important;
    margin-top: -5px !important;
    margin-right: -5px;
    
}

@keyframes complexZoomRotateMove {
    0%, 100% {
        transform: scale(1.3) rotate(0deg) translate(0, 0);
    }
    25% {
        transform: scale(1) rotate(5deg) translate(10px, 10px);
    }
    50% {
        transform: scale(1.1) rotate(-5deg) translate(-10px, 10px);
    }
    75% {
        transform: scale(1) rotate(5deg) translate(10px, -10px);
    }
}


@keyframes gentleMoveAndZoom {
    0% {
        transform: scale(1) translate(0, 0);
    }
    25% {
        transform: scale(1.02) translate(10px, 5px);
    }
    50% {
        transform: scale(1.04) translate(-10px, -5px);
    }
    75% {
        transform: scale(1.02) translate(5px, 10px);
    }
    100% {
        transform: scale(1) translate(0, 0);
    }
}




.background_images_floating {
    animation: complexZoomRotateMove 40s infinite;
}
.fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5000;
    cursor: pointer;
}

.fullscreen-modal img {
    max-width: 90%; /* some margin to the edges */
    max-height: 90%; 
    border-radius: 1px; /* optional: for rounded corners */
    object-fit: cover;
}

.downloads-popup {
    z-index: 100;
    height: 12px;
    max-width: 15px;
    margin-right: 6px;
    filter: invert(1);
    margin-bottom: -1px;
}


#video-modal {
    left: 3%;
}

.fullscreen-modal .download-btn-modal {
    position: absolute;
    top: 7%;
    left: 7%;
    background-color: rgba(95, 95, 95, 0.26);
    color: rgb(255, 255, 255);
    padding: 5px 15px;
    border-radius: 100px;
    text-decoration: none;
    transition: ease-in-out 100ms;
    font-size: 14px;
    backdrop-filter: blur(20px);
    animation: fade-in 0.5s;
    border: 1px solid rgb(180, 180, 180);
    
}

.download-btn-modal:hover {
    transform: scale(1.02);
}

.media-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100vh;
        max-width: 100vw;

    }


.no-surveys-message {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 90vh;
    margin-top: -100px;
    background-color: white;
}


.no-surveys-text {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 750px;
    animation: fade-in 2s;
    font-size: 42px;
    text-align: left;
    padding: 50px 100px;
    z-index: 100 !important;
    border-radius: 35px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;}

.no-surveys-text > p {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 300;
}

.no-surveys-text > h2 {
    text-align: center;
    font-size: 52px;
}

.no-surveys-text > img {
    width: 50px;
    margin-bottom: 40px;
}

.no-surveys-message > img {
    position: absolute;
    height: 100%;
    height: 450px;
    object-fit: cover;
    margin-top: 30px;
    z-index: 1;
}

.button-start {
    padding: 10px 30px;
    background-color: var(--blue);
    text-decoration: none;
    color: white;
    border-radius: 100px;
    font-size: 18px;
    width: fit-content;
    margin-top: 48px;
    transition: 200ms ease-in-out;
    z-index: 10;
}

.button-start:hover {
    transform: scale(1.02);
}






/* Medium devices (tablets) */
@media (min-width: 768px) {
    .image-item {
        width: calc(50% - 16px);
    }
    .video-item {
        width: calc(50% - 16px);
    }
      
}

/* Large devices (desktops) */
@media (min-width: 992px) {
    .image-item {
        width: calc(33.33% - 16px);
    }
    .video-item {
        width: calc(33.33% - 16px);
    }
}

/* Extra large devices (large desktops) */
@media (min-width: 1200px) {
    .image-item {
        width: calc(25% - 16px);
    }
    .video-item {
        width: calc(25% - 16px);
    }
  
}



@media (max-width: 600px) {

    .memories {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .media-item {
        width: 100%; /* Setzt die Breite auf 100% für mobile Ansichten */
        margin-left: 0; /* Entfernt den linken Abstand */
        
}
}