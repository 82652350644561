/* Hauptcontainer */
.user-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    margin: 0 auto;
}

/* Einzelnes Feld */
.field-container {
    width: 500px; /* Beispielbreite, an Ihre Bedürfnisse anpassen */
    height: 60px; border: 1px solid rgb(196, 196, 196);
 margin: 10px;
 border-radius: 12px;
 padding-right: 0px;
 box-sizing: border-box; 
 background-color: rgb(255, 255, 255) !important;
 /* Stellt sicher, dass Padding und Border in der Gesamtbreite und -höhe enthalten sind */
}

input {
    outline: none;
    box-shadow: none;
    
}


.text-input {
    font-size: 16px;
    font-family: 'Jost';
    letter-spacing: 0.8px;
}

.field-container:focus-within {
    border: 1px solid var(--blue); /* Beispiel für einen blauen Rand */
    box-shadow: 0 0 5px var(--blue)(52,152,219,0.5); /* Ein leichter Schatten für einen "Lift"-Effekt, optional */
}

.input-wrapper > input {
    width: 90%;
    height: 57px;
    border-radius: 12px;
    border: none; 
    padding-left: 20px;
    background-color: white !important;
}

.input-wrapper > img {
    width: 14px;
    margin-bottom: -2px !important;
    margin-left: 20px !important;
}  



.checkbox-container {
    width: 530px;
    
}

.agb-checkbox-container {
    margin-top: 10px !important;
    width: 530px;

}

.privacy-checkbox-container {
    margin-top: -15px;
    width: 530px;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 15px;

}




.checkbox-input {
    display: none; /* Checkbox ausblenden */
}

.agb-name {
    letter-spacing: 1px;
    color: grey;
    margin: 4px;
}

.agb-text {
    margin-left: 30px;
}

.agb-text-1 {
    margin-left: 30px;
}

.checkbox-label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    margin-left: 15px;
    margin-bottom: 20px;
}

.checkbox-label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 100px;
}

.checkbox-input:checked + .checkbox-label:after {
    content: "";
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-color: var(--blue);
    border-radius: 100px;
}

@media (max-width: 1000px) {
    .field-container {
     width: 90%; 
    }
    .checkbox-container {
        width: 92%;
        margin-top: 10px;
        
    }
    .input-wrapper > img {
        width: 14px;
        margin-bottom: -2px !important;
        margin-left: 0px !important;
    }
    
    .user-data-container {
        margin-bottom: 70px;
    }

    .checkbox-container:last-child {
        margin-bottom: 120px;
    }

    .agb-checkbox-container {
        width: 95%;
    }

    .privacy-checkbox-container {
        width: 95%;
    }
}
