/* DeliveryModal.css */

.delivery-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: 'Jost';
}

.delivery-modal-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    width: 90%;
    max-width: 600px;
    position: relative;
    text-align: center;
}

.delivery-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.delivery-modal-close > img {
    width: 28px;
    rotate: 45deg;
    margin-right: -10px;
}

.delivery-modal-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-family: 'Jost';
}

.delivery-modal-hr {
    border: none;
    height: 1px;
    background-color: rgb(230, 230, 230);
    margin-bottom: 20px;
}

.delivery-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.delivery-details,
.strategy-call {
    display: flex;
    align-items: center;
    margin-right: 20px;
    text-align: left;
    margin-left: 20px;
}

.delivery-details img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.strategy-call img {
    border-radius: 100px;
    width: 35px;
    height: 35px;
    margin-right: 15px;
}

.delivery-time,
.strategy-call-title {
    font-size: 16px;
    font-weight: bold;
}

.delivery-days,
.strategy-call-duration {
    font-size: 12px !important;
    color: #555;
    margin-top: -2px !important;
}

.offer-description-label {
    display: block;
    text-align: left;
    margin-bottom: 10px;
    font-size: 12px;
    color: #555;
}

.file-upload-input {
    display: none;
}

.offer-description-container {
    position: relative;
    margin-bottom: 20px;
}

.offer-description-input {
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 1px solid rgb(201, 201, 201);
    border-radius: 15px;
    resize: none;
    outline: none;
}

.file-upload-button {
    position: absolute;
    bottom: 10px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
}

.file-preview-image {
    width: 40px;
    position: absolute;
    height: 40px;
    object-fit: cover;
    border-radius: 8px;
    bottom: 15px;
    right: 45px;
}

.file-upload-button img {
    width: 20px;
    height: 20px;
}

.confirm-order-button {
    background-color: var(--blue);
    color: #fff;
    border: none;
    border-radius: 100px;
    height: 55px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: block;
    margin: -10px 0 0 0;
}

.confirm-order-button:hover {
    background-color: var(--blue);
}
