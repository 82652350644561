.page-builder-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 15px;
    letter-spacing: 0.5px;
}


.live-preview-button {
    padding: 7px 18px;
    background-color: var(--blue);
    border-radius: 7px;
    margin-right: 20px;
    color: white;
    text-decoration: none;
    transition: ease-in-out 100ms;
}

.live-preview-button:hover {
   transform: scale(1.02);
}

.page-nav-menu-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-nav-menu-left > img {
    width: 40px;
    object-fit: contain;
    margin-left: 25px;
    margin-right: 20px;
}

.page-nav-text-item {
display: flex;
margin-left: 20px;
align-items: center;

}

.page-nav-text-item:hover {
    cursor: pointer;
}

.hover-menu-comp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    border-radius: 15px;
    background-color: white;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#menu-item-1 {
    position: relative;
}

.hover-menu {
    position: absolute;
    top: 60px;
    left: 80px;
}

.page-nav-text-item  > div {
    display: flex;
}

.page-nav-text-item  > div > img {
    width: 15px;
    margin-left: 10px;
}

.hover-menu-comp-top {
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.create-new-page-link > h4 {
    font-family: 'Jost';
    margin-right: 15px;
    border-bottom: 1px solid rgb(224, 224, 224);
    padding-bottom: 5px;
    font-weight: 600;
    color: black;
    transition: ease-in-out 200ms;

}

.create-new-page-link:hover {
    transform: scale(1.02);
}

.hover-menu-comp-top > p {
    margin-left: 15px;
    color: rgb(77, 77, 77);
}


.hover-menu-comp-mid {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgb(255, 255, 255);
    transition: ease-in-out 200ms smooth;
    border-top: 1px solid rgb(255, 255, 255);


}

.hover-menu-comp-mid:hover {
    cursor: pointer;
    border-bottom: 1px solid rgb(230, 230, 230);
    border-top: 1px solid rgb(230, 230, 230);

}

.hover-menu-comp-mid:active {
    background-color: rgb(239, 248, 252);
}

.current-page {
background-color: rgb(239, 248, 252);
}

.hover-menu-comp-mid-left {
    margin-left: 20px;
    margin-right: 20px;
    width: 10%;
}

.hover-menu-comp-mid-left > img {
    width: 25px;
}

.hover-menu-comp-mid-middle {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 55%;
}

.hover-menu-comp-mid-middle > h5 {
    font-size: 12px;
    font-weight: 600;
}

.hover-menu-comp-mid-middle > p {
    font-size: 14px;
    font-weight: 300;
}

.hover-menu-comp-mid-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 10%;
    margin-left: 10px;
}

.hover-menu-comp-mid-right > img {
    width: 10px;
}

.hover-menu-comp-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    30% {
        transform: translateY(-25%);
        opacity: 0.5;
    }
    50% {
        transform: translateY(-10%);
        opacity: 0.7;
    }
    70% {
        transform: translateY(-5%);
        opacity: 0.9;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


.hover-menu-comp-mid {
    animation: slideDown 0.5s ease-out;
}

.current-page {
    animation: slideUp 0.3s ease-out;
}

.previous-page {
    animation: slideDown 0.3s ease-out;
    
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.hover-menu {
    animation: fadeIn 0.2s ease forwards;
    display: none; /* Initial nicht sichtbar */
}

.menu-right {
    display: flex;
}

.menu-right > div {
    margin-right: 20px;
    margin-top: 10px;
}

.status-text {
    margin-right: 10px;
    margin-top: 15px;
    font-size: 11px;
    text-transform: uppercase;
}
.switch {
    position: relative;
    display: inline-block;
    width: 44px; /* Reduziert von 60px auf 40px */
    height: 22px; /* Reduziert von 34px auf ca. 2/3 */
}

.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px; /* Reduziert von 26px */
    width: 18px; /* Reduziert von 26px */
    left: 2px; /* Angepasst für kleinere Größe */
    bottom: 2px; /* Angepasst für kleinere Größe */
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--blue);
}

input:checked + .slider:before {
    transform: translateX(22px); /* Angepasst für kleinere Größe */
}

/* Gestaltung für runde Slider */
.slider.round {
    border-radius: 22px; /* Angepasst für kleinere Größe */
}

.slider.round:before {
    border-radius: 50%;
}

.inactive {
    background-color: rgb(202, 202, 202) !important;
}

.live_page_button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.live_page_button  > img {
    width: 15px;
    margin-left: 9px;
    filter: invert(1);
    margin-top: 1px;
    opacity: 0.8;
}

.live_page_button > p {
    font-weight: 400;
    font-size: 14px;
    margin-top: 2px;
    color: rgba(255, 255, 255, 0.938);
}


