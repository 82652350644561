.bottom-purchase-container {
  display: flex;
  height: 65px;
  position: relative;
  margin-top: 80px;
  width: 100%;
  top: 0px;
  align-items: center;
  background-color: var(--blue);
  color: white;
  font-family: 'Jost';
  border-top: 1px solid rgb(221, 221, 221);
  padding: 0 20px;
  z-index: 1000;
  margin-bottom: -80px;
}

.bottom-purchase-left,
.bottom-purchase-center,
.bottom-purchase-right {
  flex: 1;
  width: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-purchase-left {
  justify-content: flex-start;
}

.bottom-purchase-right {
  justify-content: flex-end;
}

.time-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.time-value {
  font-size: 18px;
  font-weight: bold;
}

.time-label {
  font-size: 12px;
}

.bottom-purchase-center {
  text-align: center;
}

.bottom-purchase-center > span {
  font-size: 16px;
  font-weight: 500;
}