* {
  margin: 0;
  box-sizing: border-box;
}


:root {
  --blue: #6897B7; 
}

body {
  font-family: 'Jost' 'Noto Serif JP', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
  font-family: 'Voyage', sans-serif;
}

h5, h6, p, a {
  font-family: 'Jost', sans-serif;
}

table, input, textarea, select, button {
  font-family: 'Jost', sans-serif;
}

@font-face {
  font-family: 'Urbanist'; 
  src: url('./Fonts/Urbanist-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Voltaire'; 
  src: url('./Fonts/Voltaire.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tondu'; 
  src: url('./Fonts/Tondu.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Garet'; 
  src: url('./Fonts/Garet.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Caviar'; 
  src: url('./Fonts/Caviar.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Caviar'; 
  src: url('./Fonts/Caviar-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Mont'; 
  src: url('./Fonts/Mont.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Creato'; 
  src: url('./Fonts/Creato-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Creato'; 
  src: url('./Fonts/Creato-Thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Luis'; 
  src: url('./Fonts/Luis-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Luis'; 
  src: url('./Fonts/Luis-Thin.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Vera'; 
  src: url('./Fonts/Vera.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gontserrat'; 
  src: url('./Fonts/Gontserrat-Thin.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Gontserrat'; 
  src: url('./Fonts/Gontserrat-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gontserrat'; 
  src: url('./Fonts/Gontserrat-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Expansiva'; 
  src: url('./Fonts/Expansiva.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coolvetica'; 
  src: url('./Fonts/Coolvetica.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tallboy'; 
  src: url('./Fonts/Tallboy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arthead'; 
  src: url('./Fonts/Arthead.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Dream'; 
  src: url('./Fonts/Dream-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Dream'; 
  src: url('./Fonts/Dream-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Times'; 
  src: url('./Fonts/Times.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Rosehot'; 
  src: url('./Fonts/Rosehot.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Belgiano'; 
  src: url('./Fonts/Belgiano.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}




@font-face {
  font-family: 'Dela'; 
  src: url('./Fonts/DelaGothicOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gruppo'; 
  src: url('./Fonts/Gruppo-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Moonic'; 
  src: url('./Fonts/Moonic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'Valerie'; 
  src: url('./Fonts/ValerieAngel.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Classic'; 
  src: url('./Fonts/PPMori-SemiBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Palms'; 
  src: url('./Fonts/Cunia.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Boho'; 
  src: url('./Fonts/Chilia-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Jost'; 
  src: url('./Fonts/Jost-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Jost'; 
  src: url('./Fonts/Jost-ExtraLight.ttf') format('truetype');
  font-weight: 200; /* Assuming it's extra light */
  font-style: normal;
}

@font-face {
  font-family: 'Jost'; 
  src: url('./Fonts/Jost-Light.ttf') format('truetype');
  font-weight: 300; /* Assuming it's extra light */
  font-style: normal;
}

@font-face {
  font-family: 'Jost'; 
  src: url('./Fonts/Jost-Medium.ttf') format('truetype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Jost'; 
  src: url('./Fonts/Jost-SemiBold.ttf') format('truetype');
  font-weight: 600; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Jost'; 
  src: url('./Fonts/Jost-Bold.ttf') format('truetype');
  font-weight: 700; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Voyage'; 
  src: url('./Fonts/Voyage-Bold.woff2') format('woff2');
  font-weight: 700; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Bloque'; 
  src: url('./Fonts/Bloque.ttf') format('truetype');
  font-weight: 700; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Money'; 
  src: url('./Fonts/Money.ttf') format('truetype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Money'; 
  src: url('./Fonts/Money_2.ttf') format('truetype');
  font-weight: 600; /* Assuming it's medium */
  font-style: normal;
}


@font-face {
  font-family: 'Woody'; 
  src: url('./Fonts/Woody.ttf') format('truetype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Gong'; 
  src: url('./Fonts/Gong.ttf') format('truetype');
  font-weight: 600; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Posh'; 
  src: url('./Fonts/Posh.ttf') format('truetype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Posh'; 
  src: url('./Fonts/Posh_2.TTF') format('truetype');
  font-weight: 600; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Buda'; 
  src: url('./Fonts/Buda.ttf') format('truetype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Writer'; 
  src: url('./Fonts/Writer.otf') format('opentype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Curvy'; 
  src: url('./Fonts/Curvy.otf') format('opentype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Inter'; 
  src: url('./Fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Inter'; 
  src: url('./Fonts/Inter-Light.ttf') format('truetype');
  font-weight: 400; /* Assuming it's medium */
  font-style: normal;
}


@font-face {
  font-family: 'Inter'; 
  src: url('./Fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins'; 
  src: url('./Fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins'; 
  src: url('./Fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins'; 
  src: url('./Fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat'; 
  src: url('./Fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat'; 
  src: url('./Fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat'; 
  src: url('./Fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans'; 
  src: url('./Fonts/NunitoSans-SemiBold.ttf') format('truetype');
  font-weight: 500; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans'; 
  src: url('./Fonts/NunitoSans-Regular.ttf') format('truetype');
  font-weight: 400; /* Assuming it's medium */
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans'; 
  src: url('./Fonts/NunitoSans-Light.ttf') format('truetype');
  font-weight: 300; /* Assuming it's medium */
  font-style: normal;
}









