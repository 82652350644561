    
.survey_backend {
    opacity: 0;
    animation: fadeIn ease 2s;
    animation-fill-mode: forwards;
    min-height: 84vh;
    margin-bottom: 30px;
  }
  



  @keyframes fadeIn {
    0% {
        opacity: 0;
    }
    40% {
      opacity: 0;
  }
  
    100% {
        opacity: 1;
    }
  }