.no-agency-container {
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin: 50px auto;
    max-width: 600px;
}

.no-agency-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.no-agency-container p {
    font-size: 16px;
    margin-bottom: 30px;
}
