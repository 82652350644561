
  .login {
    font-family: 'Jost', sans-serif;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.548);
    position: relative;
  }

  .references {
    display: flex;
    position: fixed;
    bottom: 50px;
  }

  .references > p {
    margin: 0 30px;
    color: rgb(77, 77, 77);
    text-transform: uppercase;
    font-size: 13px;
  }

  .login {
    position: relative;
    width: 100%;
    height: 100vh;  /* Stellen Sie sicher, dass der Container die volle Höhe des Viewports einnimmt */
    overflow: hidden;
}

.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    z-index: -1; /* Damit das Video hinter Ihrem Inhalt bleibt */
}

/* In Ihrer SignUp.css */
.login-message.success {
  color: green;
}

.login-message.error {
  color: rgb(235, 130, 81);
}

.verify_mail_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.verify_mail_container > h1 {
  margin-top: 50px;
  color: rgb(102, 102, 102);
  margin-bottom: 20px;
  font-size: 42px;
  margin: 50px 30px 20px 30px;
}

.verify_mail_container > p {
  margin-bottom: 20px;
  margin: 30px;
  line-height: 200%;
  color: black;
}


.mail-icon {
  opacity: 0.6;
}

.lock-icon {
  opacity: 0.6;
}

.about-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(53, 53, 53);
  text-decoration: none;
  border-radius: 100px;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 140px;
  height: 35px;
  font-size: 14px;
}

.header-about-icon {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 45px;
}

@keyframes slideIn {
  0% {
      transform: translateY(0%);
      opacity: 0;
  }
  5% {
    transform: translateY(10%);
    opacity: 0.0;
}

  100% {
      transform: translateY(0);
      opacity: 1;
  }
}


.login-container {
    padding: 20px;
    border-radius: 28px;
    max-width: 400px;
    width: 100%;
    min-height: 500px;
    border: 1px solid rgb(255, 255, 255); 
    backdrop-filter: blur(1px);  /* Hier wird der Effekt angewendet */
    transform: translateY(100%); /* This moves the element off the screen at the bottom */
    transition: transform 0.5s ease-out; /* This adds a transition to the transform property */
    opacity: 0; /* This makes the element invisible */
    transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* This adds a transition to the opacity and transform properties */
    animation: slideIn 0.2s forwards;
    color: rgb(104, 104, 104);
    background-color: rgba(255, 255, 255, 0.753);
    box-shadow: rgba(50, 50, 93, 0.1) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;  }
  

  .login-btn:hover {
background-color: rgb(247, 247, 247) !important; 
  }
  
.login-header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 20px;
    font-size: 36px;
    background: linear-gradient(-45deg, #0b3746, var(--blue), #87CEEB);
    background-size: 200% 200%;
    color: var(--blue);
    -webkit-background-clip: text;
    background-clip: text;
    animation: gradientWave 5s infinite;
    
}

.signup-screen > p {
  color: var(--blue) !important;
}

.sign-in-mini-font {
  font-size: 12px;
  margin-bottom: 10px !important;
  color: rgb(206, 205, 205);
}

.toggle-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  position: absolute;
  top: -85px;  
  right: 120px;
}
.toggle-buttons > div {
  background-color: var(--blue);
  padding: 5px 10px;
  border-radius: 100px; 
  border: 1px solid rgb(255, 255, 255); 
  backdrop-filter: blur(10px);  /* Hier wird der Effekt angewendet */
  transform: translateY(100%); /* This moves the element off the screen at the bottom */
  transition: transform 0.5s ease-out; /* This adds a transition to the transform property */
  opacity: 0; /* This makes the element invisible */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* This adds a transition to the opacity and transform properties */
  animation: slideIn 2s forwards;
  color: white;
  background-color: rgba(255, 255, 255, 0.26);
}

.toggle_button_1.active {
  background-color: var(--blue);
  color: white; /* oder eine andere Hervorhebungsfarbe */
}

.toggle_button_1 {
  font-size: 12px;
  background-color: transparent;
  color: rgb(78, 78, 78);

}

  .signup-screen > p {
      text-align: center;
      margin-bottom: 30px;
  }
  
  .login-label {
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 12px;
    margin-left: 10px;
    color: rgb(110, 107, 107);
    text-transform: uppercase;
    letter-spacing: 0.5;
    color: var(--blue);
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    margin-left: 5px;
    font-weight: 400;
    color: rgb(29, 29, 29) !important;
  }

  .login-input::placeholder {
 color: rgb(102, 102, 102);
  }

  
  .login-btn-1 {
    width: 100%;
    background-color: var(--blue);
    color: rgb(233, 233, 233);
    border: none;
    padding: 12px 15px;
    border-radius: 100px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
    margin-bottom: 10px;
    margin-top: 20px;
    transition: ease-in-out 100ms;
  }

  .active-btn {
        width: 100%;
        background-color: var(--blue);
        color: white;
        border: none;
        padding: 12px 15px;
        border-radius: 100px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.2s;
        margin-bottom: 10px;
        margin-top: 20px;
        height: 50px;
        transition: ease-in-out 100ms;
      }
    
  .login-btn-2 > img {
    width: 30px;
    z-index: 1000;
  }

  .login-btn-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: rgb(54, 54, 54);
    border: 1px solid rgb(192, 192, 192);
    padding: 12px 15px;
    border-radius: 100px;
    cursor: pointer;
    height: 50px;
    font-size: 14px;
    transition: background-color 0.2s;
    margin-bottom: 10px;
    transition: ease-in-out 100ms;
    background-color: rgba(255, 255, 255, 0.527) !important;
  }

  .login-btn-3 {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    border: none; 
    padding: 12px 15px;
    border-radius: 10px;
    height: 50px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
    text-decoration: underline;
    margin-top: 20px;
    transition: ease-in-out 100ms;
    ;
  }

  .login-btn-4 {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    color: rgb(119, 119, 119);
    border: none; 
    padding: 12px 15px;
    height: 50px;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s;
    text-decoration: none;
    transition: ease-in-out 100ms;
    text-align: center;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 198px; 
    right: 45px;
  }

  .login-btn-5 {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    color: rgb(46, 46, 46);
    border: none; 
    padding: 12px 15px;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    text-decoration: underline !important;
    font-size: 12px;
    transition: background-color 0.2s;
    text-decoration: none;
    margin-top: 0px;
    height: 50px;
    transition: ease-in-out 100ms;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

  }

 


  .login-btn-1:hover {
    transform: scale(1.01);
    background-color: var(--blue) !important;
  }
  
  .login-btn-2:hover {
    transform: scale(1.01);
    background-color: rgba(255, 255, 255, 0.075) !important;
  }

  .login-btn-3:hover {
    transform: scale(1.01);
    background-color: rgba(255, 255, 255, 0) !important;
    color: white !important;
  }

  .login-input-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgb(243, 243, 243);
      height: 45px;
      margin-bottom: 12px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.527) !important;
      transition: ease-irgba(255, 255, 255, 0.863)

  }


  .login-input-wrapper-passive {
    background-color: rgba(255, 255, 255, 0);
}

  

  .login-input-wrapper:hover {
    background-color: rgba(255, 255, 255, 0.144);

  }
  
  .login-input-wrapper > img {
      width: 15px;
      margin-right: 15px;
  }

  .login-btn:hover {
    background-color: #0056b3;
  }
  
  .login-btn:disabled {
    background-color: #d1d1d1;
    cursor: not-allowed;
  }
  
  .login-message {
    color: rgb(218, 113, 113);
    text-align: center;
    margin-top: 20px;
  }
  
  .login-link {
    text-decoration: none;
    color: #007bff;
    display: inline-block;
    margin: 10px 0;
  }
  
  .login-link:hover {
    text-decoration: underline;
  }
  

  @media (max-width: 1180px) {
    .references {
      display: none;
    }
  } 
  

  @media (max-width: 500px) {

    .references {
      display: none;
    }

    .toggle-buttons {
      position: unset;
      margin-top: 10px;
    }

.login-container {
    padding: 20px;
    border-radius: 28px;
    box-shadow: 0px 0px 15px rgba(107, 106, 106, 0.1);
    max-width: 95vw;
    width: 100%;
    min-height: 500px;
    border: 1px solid lightgray; 
    backdrop-filter: blur(10px);  /* Hier wird der Effekt angewendet */
}

  }