.team-chat-list-container {
    display: flex;
    overflow-y: auto;
    height: 100vh;
}

.team-chat-sidebar {
    width: 300px;
    height: calc(100vh - 80px);
    background-color: #f5f5f5;
    border-right: 1px solid #ccc;
    padding: 10px 0;
    margin-top: 80px;
}

.team-chat-sidebar h2 {
    font-family: 'Jost';
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-top: 15px;
}

.team-chat-unread-count {
    margin-left: auto;
    background-color: rgb(253, 61, 61);
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
}

.team-no-chats-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    margin-top: 100px;
}

.team-chat-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.team-chat-list-item.selectivo {
    background-color: #dadada; /* Oder jede Farbe, die du möchtest, um den ausgewählten Chat hervorzuheben */
}

.team-chat-list-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    height: 80px;
    justify-content: left;
    width: 100%;
}

.team-chat-list-item:hover {
    cursor: pointer;
}

.team-chat-list-item a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

.team-chat-user-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;
}

.team-chat-user-name {
    font-family: 'Jost';
    font-size: 14px;
}

.team-loading-spinner-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.team-no-chats-message {
    text-align: center;
    margin-top: 50px;
}

.team-no-chats-message img {
    margin-bottom: 20px;
}

.team-no-chats-message h2 {
    margin-bottom: 10px;
}

.team-no-chats-message p {
    color: #666;
}
