.type-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.type-description:nth-child(4) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 150px;
}

.type-wrapper {
    display: flex;

}

.spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



.type-title > h2{
    font-size: 42px;
    margin-bottom: 20px;
}

.stepper-count {
    background-color: var(--blue);
    padding: 3px 8px;
    border-radius: 100px;
    color: white;
    margin-top: 45px;
    margin-bottom: 15px;
    font-size: 13px;
}

.type-description {
    display: flex;
    align-items: center;
    justify-content: top;
    text-align: center;
    flex-direction: column;
    font-size: 18px;
    margin: 30px;
    width: 380px;
    position: relative;

}

.type-preview-pic {
    width: 160px !important;
    margin-top: 30px;
    animation: subtleMoveAndZoom 20s infinite;
    scale: 1;
}

.type-preview-pic-3 {
    width: 160px !important;
    margin-top: 30px;
    animation: subtleMoveAndZoom3 20s infinite;
}

.type-preview-pic-2 {
    width: 160px !important;
    margin-top: 30px;
    animation: subtleMoveAndZoom2 20s infinite;
}

.type-description > h4 {
    font-size: 28px;
    font-family: 'Jost';
    font-weight: 600;
}

.type-description > p {
    font-size: 16px;
   margin: 20px 30px 20px 30px;
    color: grey;
    font-weight: 300;
}

.down-right-button {
    width: 50px;
    position: absolute;
    top: 170px;
    right: -40px;
    rotate: -90deg;
}


@keyframes subtleMoveAndZoom {
    0%, 100% {
        transform: scale(1.3) translate(0, 0) perspective(600px) rotateX(0deg) rotateY(0deg);
    }
    45% {
        transform: scale(1.2) translate(-10px, 5px) perspective(600px) rotateX(-5deg) rotateY(5deg);
    }
    60% {
        transform: scale(1.2) translate(10px, -5px) perspective(600px) rotateX(5deg) rotateY(-5deg);
    }
    95% {
        transform: scale(1.3) translate(5px, 10px) perspective(600px) rotateX(-5deg) rotateY(5deg);
    }
}


@keyframes subtleMoveAndZoom2 {
    0%, 100% {
        transform: scale(1.3) translate(0, 0) perspective(600px) rotateX(0deg) rotateY(0deg);
    }
    15% {
        transform: scale(1.2) translate(10px, 5px) perspective(600px) rotateX(-5deg) rotateY(5deg);
    }
    35% {
        transform: scale(1.2) translate(-10px, -5px) perspective(600px) rotateX(5deg) rotateY(-5deg);
    }
    75% {
        transform: scale(1.3) translate(5px, 10px) perspective(600px) rotateX(-5deg) rotateY(5deg);
    }
}


@keyframes subtleMoveAndZoom3 {
    0%, 100% {
        transform: scale(1.3) translate(0, 0) perspective(600px) rotateX(0deg) rotateY(0deg);
    }
    25% {
        transform: scale(1.2) translate(10px, 5px) perspective(600px) rotateX(-5deg) rotateY(5deg);
    }
    50% {
        transform: scale(1.2) translate(-10px, -5px) perspective(600px) rotateX(5deg) rotateY(-5deg);
    }
    75% {
        transform: scale(1.3) translate(5px, 10px) perspective(600px) rotateX(-5deg) rotateY(5deg);
    }
}




@media (max-width: 1300px) {

    .type-wrapper {
        display: flex;
        flex-direction: column;
    }
  
    .type-description > h4 {
        font-size: 28px;
        font-family: 'Jost';
        font-weight: 600;
    }

    .stepper-count {
        background-color: var(--blue);
        padding: 3px 10px;
        border-radius: 100px;
        color: white;
        margin-top: 45px;
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 400;
    }

    .type-description {
        margin: 10px;
        width: 360px;
    }

   

    .type-description > p {
        font-size: 15px;
        margin-bottom: 30px;
        margin-top: 10px;
        color: grey;
    }
    
    .down-right-button {
        width: 50px;
        position: relative;
        top: unset;
        margin-left: 0px;
        left: 0;
        rotate: 0deg;
        opacity: 0.2;
    }

    
    
}



@media (max-width: 900px) {
    .type-wrapper {
        display: flex;
        flex-direction: column;
    }


    .down-right-button {
        width: 50px;
        position: relative;
        top: unset;
        margin-left: 0px;
        left: 0;
        rotate: 0deg;
        opacity: 0.2;
    }


.type-wrapper:nth-child(2) {
    margin-bottom: 130px;

}
    
}