.delete_modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 30px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 1000; /* Stellt sicher, dass das Modal über anderen Elementen schwebt */
}

.delete_modal p {
    margin-bottom: 30px;
    font-size: 20px;
}

.delete_modal button {
    margin: 0 10px;
    width: 150px;
    height: 45px;
}
