

.chat-list-container > div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
    margin-top: 100px;
    padding: 15px;
}

.chat-list-container > div > h2 {
    font-family: 'Jost';
    margin-top: 30px;
}


.chat-messages {
    flex-grow: 1;
    padding: 20px;
}

.chat-sidebar-info > div {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    position: relative;
}

.chat-sidebar-info > div > span {
    background-color: var(--red);
    width: 20px;
    height: 20px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    right: 0;
}


.no-chats-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    margin-top: -100px;
    margin-left: 200px;
}

.no-chats-message > img {
 width: 150px;
}

.no-chats-message > p {
    font-family: 'Jost';
    margin-top: 0px;
    margin-bottom: 30px;
    color: rgb(199, 199, 199);
}

.no-chats-message > h2 {
    font-family: 'Wide', 'Jost';
}

.no-chats-message > a {
    font-family: 'Wide', 'Jost';
    padding: 0px 35px;
    border-radius: 13px;
    color: white;
    background-color: #282828;
    border: 1px solid rgb(66, 66, 66);
    font-family: 'Jost';
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.no-chats-message > a > p {
    font-size: 16px;
    letter-spacing: 0.5px;
}

.no-chats-message > a:hover {
    cursor: pointer;
}


.chat-sidebar-toggle-button {
    top: 40%;
    position: absolute;
    right: -20px;
    z-index: 1000;
    margin-left: -30px;
    border: none;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    background: none;
    font-size: 16px;
}

.chat-sidebar-toggle-button > img {
    width: 35px;
}

.sidebar-toggle-button:hover {
    background-color: #0056b3;
}

.dashboard-chat-sidebar.closed .chat-sidebar-info > div > span {
    top: -25px !important;
}

