.admin-config {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: -104vh;
    animation: fadeInSlideFromTop 1s backwards;
    z-index: 1;
    position: relative;
    top: 0;
}

@keyframes fadeInSlideFromTop {
    0% {
        opacity: 0;
        top: -10px;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.466); /* Dunkler Hintergrund */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(10px);
  }

  .modal-contento > h2 {
      font-family: 'Jost';
      font-weight: 500;    
      font-size: 48px;
      margin-top: 20px;
      margin-bottom: 0px;
      color: var(--blue) !important;
  }

  .modal-contento > p {
      margin-bottom: 30px;
      max-width: 500px;
      text-align: center;
  }

  .section-head-titles > p {
      display: flex;
      align-items: center;
      justify-content: center; 
      background-color: rgb(235, 235, 235);
      border-radius: 100px;
      margin-right: 10px;
      font-size: 12px;
      color: var(--blue);
      font-weight: 600;
      width: 25px;
      height: 25px;

  }

  .inspo-button {
      background-color: white !important;
      color: black !important;
  }

 
  
  .modal-contento {
    width: 90%;
    min-width: 900px;
    padding: 20px;
    border-radius: 35px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    overflow-y: auto; 
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: modalEnter 0.4s forwards; /* Apply the animation */
    height: 600px;
    color: var(--blue);


}

.settings-header-bar {
    background-color: rgb(248, 248, 248);
    width: 100vw;
    position: sticky;
    top: 0px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settings-header-bar > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    margin-left: 130px;
}

.settings-header-bar > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings-switch-bar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings-switch-bar > h2{
    margin-right: 10px;
    margin-left: 60px;
    font-family: Jost;
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 8px;
    color: var(--blue) !important;
}

.settings-switch-bar > h2:hover {
    cursor: pointer; 
}

.settings-switch-bar > span > h2 {
    margin-right: 10px;
    margin-left: 40px;
    font-family: Jost;
    font-size: 14px;
    font-weight: 400;
    color: grey;
}

.settings-header-bar > h3 {
    margin-top: 10px;
    font-size: 18px;
    font-family: 'Jost';
    margin-left: 30px;
    font-weight: 400;
}

.preview-link {
    margin-right: 15px;
    text-decoration: none;
    color: var(--blue);
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 15px;
    border-radius: 100px;
    border: 1px solid var(--blue);
    font-size: 14px;
    height: 44px;
    background-color: white;
}

.settings-button {
    background-color: var(--blue)!important;
    padding: 13px 25px !important;
    color: white;
    border-radius: 100px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-right: 3vw;
    width: 140px;
    transition: 200ms ease-in-out;
}

.settings-button-saved {
    background-color: rgb(67, 167, 67) !important;
    padding: 13px 25px !important;
    color: white;
    border-radius: 100px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-right: 3vw;
    width: 140px;
    transition: 200ms ease-in-out;
}

.back-menu {
    width: 100%;
    margin-left: 60px;
}

.back-menu > a {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 40px;
    font-size: 14px;
    color: grey;
    cursor: pointer;
}

.back-menu > a {
    text-decoration: none;
    color: grey;
}

.back-menu > a > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: rgb(231, 231, 231);
    width: 25px;
    height: 25px;
    margin-right: 20px;
}

.back-menu > a > div > img {
    width: 15px;
    rotate: 180deg;
}

.scroll-menu-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 26px;
}

.close-inspo-modal {
    position: absolute;
    right: 10px; 
    top: 10px;
    background-color: rgb(204, 47, 47) !important;
    border-radius: 100px !important;
}

.scroll-menu-header > h4 {
    font-family: 'Jost';
    width: 180px;
    margin-left: 30px;
    margin-right: 0px;
    font-weight: 400;
    font-size: 14px;
    color: grey;
}

.scroll-menu-header > hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: rgb(214, 214, 214);
}

.scroll-menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 260px;
    margin-right: 20px;
    overflow-y: auto;
    position: sticky;
    top: 0px;
    padding-top:25px;
    background-color: rgb(255, 255, 255);
    height: 100vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 101;
}


.prev-link-header-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upload-image-preview {
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.upload-image-preview > span {
    margin: 0px 20px; 
}

.upload-image-preview > img {
    width: 50px; 
    margin-bottom: 20px;
}

.fontSelector {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Hier definieren wir, dass jede Spalte mindestens 150px breit sein sollte. Wenn der Platz für eine weitere Spalte nicht ausreicht (wegen der `minmax`-Definition), wird die Anzahl der Spalten reduziert und eine neue Zeile erstellt. */
    gap: 10px; /* Abstand zwischen den Grid-Items */
    align-items: center; /* Vertikales Zentrieren der Inhalte */
    justify-items: center; /* Horizontales Zentrieren der Inhalte */
    margin-top: 20px;
}

.font-option {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #ddd;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 160px;
    text-align: center;
    font-size: 28px;
}

.font-option:hover {
    background-color: rgb(233, 233, 233);
}

#voyage {
    font-family: 'Voyage';
}

#urbanist {
    font-family: 'Urbanist';
}

#classic {
    font-family: 'Classic';
}

#palms {
    font-family: 'Palms';
}

#boho {
    font-family: 'Boho';
}

#Fatty {
    font-family: 'Dela' ;
}

#Maserati {
    font-family: 'Gruppo' ;
}

#Moon {
    font-family: 'Moonic' ;
}

#Headline {
    font-family: 'Poppins' ;
}

#Valerie {
    font-family: 'Valerie' ;
}



.font-select-wrapper {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin-top: 30px;
}

.font-select-wrapper > p {
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(122, 122, 122);
}

.font-option.selected {
    background-color: var(--blue); /* Sie können jede gewünschte Farbe setzen */
    color: white; /* Textfarbe, wenn der Font ausgewählt ist */
}




.menu > p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    background-color: rgb(245, 245, 245);
    height: 25px;
    width: 25px;
    border-radius: 100px;
}

.menu-active > p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    background-color: rgb(245, 245, 245);
    height: 25px;
    width: 25px;
    border-radius: 100px;
    color: white;
    background-color: var(--blue) !important;

}

.menu {
    display: flex;
    align-items: center;
    margin: 4px 0;
    background-color: transparent !important;
    color: grey!important;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 100%;
    margin-left: 25px;
    font-size: 12px;
    
}

.menu-active {
    display: flex;
    align-items: center;
    background-color: transparent !important;
    border-radius: 100px !important;
    color: var(--blue) !important;
    font-weight: 600;
    text-transform: uppercase !important;
    width: 100%;
    margin: 4px 0;
    margin-left: 25px;
    font-size: 12px;

}

.scroll-menu-sub {
    width: 100%;
}

.scroll-menu-sub-2 {
    width: 100%;
}




.scroll-menu-sub > a {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    height: 50px;
    border-top: 1px solid rgb(202, 202, 202); 
    border-bottom: 1px solid rgb(202, 202, 202); 
    font-size: 14px;
    text-decoration: none;
    margin-top: 40px;
}

.scroll-menu-sub-2 > a {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgb(202, 202, 202); 
    font-size: 14px;
    text-decoration: none;
}

.scroll-menu-sub-2 > a > p {
    width: 160px;
    color: rgb(99, 99, 99);
}

.scroll-menu-sub-2 > a:hover {
    cursor: pointer;
    background-color: rgb(250, 250, 250);
}

.scroll-menu-sub-2:hover > a > p {
    color: rgb(32, 32, 32);
}



.scroll-menu-sub > a > p {
    width: 160px;
    color: rgb(99, 99, 99);
}

.scroll-menu-sub > a:hover {
    cursor: pointer;
    background-color: rgb(250, 250, 250);
}

.scroll-menu-sub:hover > a > p {
    color: rgb(32, 32, 32);
}

.right-sub-arrow {
    height: 15px;
}

.right-sub-icon {
    height: 15px;
    margin-left: 30px;
    margin-right: 20px;
    opacity: 0.7;
}

.scroll-menu-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 20px;
}

.scroll-menu-bottom > p {
    margin-bottom: 15px;
    font-size: 14px;
    color: grey;
}

.scroll-menu-bottom > a {
    padding: 5px 15px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 100px;
    font-size: 14px;
    text-decoration: none;
    color: black

}

.menu-componenter {
    top: 0px !important;
    margin-left: 300px;
    margin-right: 100px;
    max-width: 1000px;
}

.menu-componenter > h2 {
    font-family: 'Jost';
    margin-top: 100px;
    font-size: 42px;
    margin-left: 20px;
}

.menu-componenter > p {
    font-size: 16px;
    margin-left: 20px;
    max-width: 700px;
    margin-top: 10px;
    font-weight: 300;
}

.question-container {
    position: relative;
}

.question-unlock-pro-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.question-right-pro {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding: 2px 7px;
    color: white;
    font-size: 13px;
    font-weight: 600;
    position: absolute;
    right: 10px;
    top: 10px;
    font-style: italic;
    border: 1px solid #f2f2f2;
    
}



.question-right-pro > p {
    animation: gradientAnimation 6s infinite;
    background: linear-gradient(90deg, black, silver, black);
    -webkit-background-clip: text;
background-clip: text;
    color: transparent;
    padding-right: 2px; /* Je nach Bedarf anpassen */

/* Animieren des Gradienten für den "Schimmer"-Effekt */
animation: gradientAnimation 6s infinite;

/* Die Animation soll sanft und kontinuierlich laufen */
animation-timing-function: linear;

/* Diese Zeile stellt sicher, dass der gesamte Hintergrund über den Text geht */
background-size: 400% 100%;
}


.question-right-pro-6 > p {
    animation: gradientAnimation 6s infinite;
    background: linear-gradient(90deg, black, silver, black);
    -webkit-background-clip: text;
background-clip: text;
    color: transparent;
    padding-right: 2px; /* Je nach Bedarf anpassen */

/* Animieren des Gradienten für den "Schimmer"-Effekt */
animation: gradientAnimation 6s infinite;

/* Die Animation soll sanft und kontinuierlich laufen */
animation-timing-function: linear;

/* Diese Zeile stellt sicher, dass der gesamte Hintergrund über den Text geht */
background-size: 400% 100%;
}

.question-right-pro-2 > p {
    animation: gradientAnimation 6s infinite;
    background: linear-gradient(90deg, black, silver, black);
    -webkit-background-clip: text;
background-clip: text;
    color: transparent;
    padding-right: 2px; /* Je nach Bedarf anpassen */

/* Animieren des Gradienten für den "Schimmer"-Effekt */
animation: gradientAnimation 6s infinite;

/* Die Animation soll sanft und kontinuierlich laufen */
animation-timing-function: linear;

/* Diese Zeile stellt sicher, dass der gesamte Hintergrund über den Text geht */
background-size: 400% 100%;
}

.question-right-pro-3 > p {
    animation: gradientAnimation 6s infinite;
    background: linear-gradient(90deg, black, silver, black);
    -webkit-background-clip: text;
background-clip: text;
    color: transparent;
    padding-right: 2px; /* Je nach Bedarf anpassen */

/* Animieren des Gradienten für den "Schimmer"-Effekt */
animation: gradientAnimation 6s infinite;

/* Die Animation soll sanft und kontinuierlich laufen */
animation-timing-function: linear;

/* Diese Zeile stellt sicher, dass der gesamte Hintergrund über den Text geht */
background-size: 400% 100%;
}

.question-right-pro-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-top: 7px;
    border-radius: 100px;
    padding: 2px 7px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    border: 1px solid #f2f2f2;
}

.question-right-pro-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-top: 7px;
    border-radius: 100px;
    padding: 2px 7px;
    color: white;
    font-size: 9px;
    font-weight: 600;
    font-style: italic;
    position: absolute;
    top: 242px;
    right: 63px;
    border: 1px solid #f2f2f2;

}


.question-right-pro-6 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-top: 7px;
    border-radius: 100px;
    padding: 2px 7px;
    color: white;
    font-size: 9px;
    font-weight: 600;
    font-style: italic;
    position: absolute;
    top: 10px;
    right: 20px;
    border: 1px solid #f2f2f2;
}

.question-right-pro-5 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-top: 7px;
    background-color: var(--blue);
    border-radius: 100px;
    padding: 2px 7px;
    color: white;
    font-size: 8px;
    font-weight: 600;
    font-style: italic;
    position: absolute;
    top: 32px;
    right: 73px;
}


.question-right-pro-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-top: 0px;
    background-color: var(--blue);
    border-radius: 100px;
    padding: 2px 7px;
    color: white;
    font-size: 9px;
    font-weight: 600;
    font-style: italic;
}

.question-right-pro-3 > img {
    width: 10px !important;
}

.question-right-pro-6 > img {
    width: 10px !important;
}

.question-right-pro-4 > img {
    width: 10px !important;
    margin-right: 4px;
}

.question-right-pro-5 > img {
    width: 6px !important;
    margin-right: 4px;
}


.question-unlock-pro-container  > h2  {
    font-family: 'Jost';
    margin-top: 20px;
    text-align: center;
}

.question-unlock-pro-container  > p {
    font-size: 14px;
    color: rgb(148, 147, 147);
}

.question-button-box {
    margin-top: 30px;
    margin-bottom: 20px;
}

.question-unlock-pro-container  > div > a {
    text-decoration: none;
    margin: 10px;
    padding: 10px 25px;
    background-color: var(--blue);
    color: white;
    border-radius: 100px;
    font-size: 14px;
}

#about-pro-button {
    background-color: white;
    border: 1px solid var(--blue) !important;
    color: var(--blue);
}


@keyframes gradientAnimation {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.question-unlock-pro-container > h2 > span {
    font-family: 'Jost';
    margin-top: 20px;
    font-style: italic;
        background: linear-gradient(90deg, transparent, silver, transparent);
        -webkit-background-clip: text;
    background-clip: text;
        color: transparent;
        padding-right: 2px; /* Je nach Bedarf anpassen */

    /* Animieren des Gradienten für den "Schimmer"-Effekt */
    animation: gradientAnimation 6s infinite;
    
    /* Die Animation soll sanft und kontinuierlich laufen */
    animation-timing-function: linear;
    
    /* Diese Zeile stellt sicher, dass der gesamte Hintergrund über den Text geht */
    background-size: 400% 100%;
}



.question-lock-div {
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    background-color: rgb(245, 245, 245);
    border-radius: 100px;
    margin-top: 10px;
}

.crown {
    width: 20px !important;
    margin-right: 3px;
}

.padlock {
    width: 40px;
    opacity: 0.5;
}



.upload_1 {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 100%; /* Einheitliche Breite */
    height: 220px; /* Einheitliche Höhe */
    border-radius: 10px;
    position: relative;
    text-align: left;
}

.upload_1:nth-child(1) {
    margin-right: 30px;
}

.upload_boxen {
    display: flex;
}

.custom-upload-box {
    display: flex; /* Umwandlung in Flex-Container */
    justify-content: center; /* Zentrieren des Inhalts horizontal */
    align-items: center; /* Zentrieren des Inhalts vertikal */
    width: 100%;
    height: 100%;
    border: 2px dashed #dddddd;
    cursor: pointer;
    overflow: hidden;
    transition: background-color 0.3s ease;
    border-radius: 20px;
}

.custom-upload-box:hover {
    background-color: rgb(240, 240, 240);
}

.upload_1 > label {
    text-transform: uppercase;
    font-size: 12px;
    color: grey;
    font-family: 'Jost';
}

.colorPicker {
    position: relative;

}

.colorPicker input[type="color"] {
    opacity: 0; /* Dies macht das ursprüngliche Eingabefeld unsichtbar */
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 120px;
    cursor: pointer;
    z-index: 2;
}


.colorPicker .custom-color-display {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: currentColor; /* Nutzt die aktuelle Farbe des Eingabefelds */
    position: relative;
    z-index: 1;
    border: 3px dashed #dddddd;

}

.colorPicker .custom-color-display-2 {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: currentColor; /* Nutzt die aktuelle Farbe des Eingabefelds */
    position: relative;
    z-index: 1;
    border: 3px dashed #dddddd;

}

.hex-feld {
    width: 100px !important;
    position: absolute;
    top: 42%;
    left: 130px;
}

.colorPicker > p {
    font-size: 12px;
    text-transform: uppercase;
    color: grey;
    margin-bottom: 10px;
    margin-top: 20px;

}



.object-type-boxes {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 20px;
    margin-top: 50px;
    margin-left: 20px;

}

.object-type-box {
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    text-align: center;
    height: 310px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 31%;
    border-radius: 35px;
    box-shadow: rgba(17, 12, 46, 0.02) 0px 48px 100px 0px;
    border: 2px dashed rgb(207, 207, 207);
}

.object-type-box > h4 {
    text-align: center; /* Text horizontal zentrieren */
    display: flex;
    justify-content: center; /* Horizontale Ausrichtung im Container zentrieren */
    align-items: center;
    font-family: 'Jost';
    font-size: 18px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 10px;
    height: 50px;
    margin-top: 10px;
    text-align: center;
    color: rgb(59, 59, 59);
}

.object-type-box-active > h4 {
    color: white !important;
}

.object-type-box > p {
    margin: 0 30px;
    font-size: 14px;
    color: grey;
}
.object-type-box > img {
    height: 100px;
    margin-top: 40px;
    margin-bottom: 20px;
    transition: 100ms ease-in-out;
}

.object-type-box-active > img {
    height: 120px;
    margin-top: 35px;
    margin-bottom: 5px;
}



.object-type-box-active {
    background-color: var(--blue);
    color: white !important;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 20px 50px 0px;
}

.object-type-box-active > p {
    color: white;
}

.edit-type-desc {
    width: 100%;
}

.edit-type-desc > h3 {
    font-size: 18px !important;
    margin-bottom: 15px;
}
.edit-type-desc-wrapper {
    display: flex;
    flex-direction: column;
}

.edit-type-desc-wrapper > p {
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 18px;
}


.edit-type-desc-wrapper > input {
    margin: 10px 0;
    height: 46px;
    border-radius: 10px;
   border: 1px solid #ccc;
   padding: 8px;
   padding-left: 15px;
   font-size: 13px;
}

#title {
background-color: rgb(245, 245, 245);
font-weight: 600;
width: 72%;
font-size: 15px;

}


.uploaded-image-preview {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.admin-mail {
    margin-top: 10px;
}

.admin-email-component > p {
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 20px;
    color: grey;

}

.admin-email-component {
    margin-top: 40px;
    display: none;
}

.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
}


.spinner-container > p {
    margin-top: 30px;
    animation: pulsating 1.5s infinite;
    color: grey;
}

@keyframes pulsating {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
        color: var(--blue);
    }
    100% {
        opacity: 1;
    }
}

.activate-survey {
    display: flex;
    align-items: center;
    margin-top: 10px;
    background-color: var(--blue) !important;
    box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
    border: 1px solid rgb(233, 233, 233) !important;    padding: 6px 10px;
    border-radius: 100px;
    margin-bottom: 10px;
    font-size: 14px;
    color: white;
}

.activate-survey input[type="checkbox"] {
    appearance: none; /* entfernt das Standardaussehen */
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
}

.activate-survey input[type="checkbox"]:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%; /* rund */
    background-color: rgba(238, 238, 238, 0.507); /* Hintergrundfarbe der Checkbox */
}

.activate-survey input[type="checkbox"]:checked:before {
    background-color: var(--blue); /* Farbe für das "Checked"-Zustand */
}

/* Optional: Ein Haken-Symbol für das "Checked"-Zustand */
.activate-survey input[type="checkbox"]:checked:after {
    content: "\2713"; /* Unicode für Haken */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: white;
}


.activate-survey > p {
    margin-left: 10px;
}

.uploaded-image-preview-2 {
    height: 200px;
    border-radius: 100px;
    width: 200px;
    object-fit: cover;
}


.uploaded-image-preview-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 200px;
    border-radius: 100px;
    width: 200px;
    object-fit: cover;
    font-size: 12px;
    text-transform: uppercase;
    color: grey;
}

.uploaded-image-preview-3:hover {
    cursor: pointer;
    background-color: #f1f1f1;
}


.config-section-bonus {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bonus-grid {
    display: flex;
    align-items: center;
    justify-content: center;
}





.upload-Files {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: green;
    height: 100px;
}


.scroll-menu button.active  {
background-color: red !important;
}



.admin-config h2, .admin-config h3 {
    color: #2c3e50;

}

.config-section {
    padding: 20px;
    margin-top: 30px;
    border-radius: 10px;
    background-color: #ffffff;
    width: 100%;
}

.config-section h3 {
    font-size: 24px;
    font-family: 'Jost';
    font-weight: 600;
    color: rgb(39, 39, 39);
}

.config-section-head  {
    height: 60px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 35px;

}

.config-section-head  > div {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

/* Input Felder Styling */
.admin-config input[type="text"], 
.admin-config input[type="number"],
.admin-config textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
    border-radius: 5px;
}



/* Checkboxen und Labels */
.user-data-field, .config-section label {
    display: flex;
    align-items: center;
}

.user-data-field label {
    flex: 1;
    margin-right: 15px;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Jost';
    color: rgb(39, 39, 39);
    margin-bottom: 0px;
}

/* Buttons */


.admin-config button {
    padding: 8px 15px;
    background-color: var(--blue);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
}

.templateBoxContainer {
    display: flex;
    gap: 10px;
}

.templateBox {
    padding: 10px 20px;
    border: 1px solid gray;
    cursor: pointer;
}

.templateBox.selected {
    background-color: #6897B7;
    color: white;
}


.spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 8px solid #000;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* Bonus Abschnitt */
.bonus-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 25px;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    margin: 20px;
    min-height: 500px;
    border-radius: 25px;
    min-width: 320px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 200ms ease-in-out;
}

.bonus-section:hover {
    transform: scale(1.01);
}

.custom-upload-box-2 {
    border: 2px dashed rgb(207, 207, 207);
    border-radius: 100px;
    padding: 3px;
}

.bonus-section + .bonus-section {
    margin-top: 20px;
}

.bonus-textarea {
    height: 110px;
    border-radius: 10px !important;
    background-color: rgba(255, 255, 255, 0.521);
    width: 90% !important;
    border: 1px solid rgb(216, 216, 216) !important;

}

.bonus-inputs {
    border: 1px solid rgb(216, 216, 216) !important;
    height: 42px;
    border-radius: 10px !important;
    width: 90% !important;
}


.bonus-inputs-fett {
    border: 1px solid rgb(216, 216, 216) !important;
    height: 42px;
    border-radius: 10px !important;
    width: 90% !important;
    font-weight: 600;
    margin-top: 20px;
}

.questions-inputs-fett {
    border: 1px solid rgb(88, 88, 88) !important;
    height: 42px;
    border-radius: 10px !important;
    font-weight: 600;
    width: 92% !important; 
    margin-top: 20px;
}


.questions-inputs-fett-desc {
    height: 46px;
    border-radius: 10px !important;
    font-weight: 500;
    width: 72% !important; 
    margin-top: 10px;
    font-size: 15px;
    align-self: start;
    padding-left: 15px !important;
    background-color: rgb(245, 245, 245);

}

.advanced-settings textarea {
    height: 100px;
    border-radius: 15px;
    padding-left: 15px !important;
}

.questions-inputs-value {
    width: 180px !important;
    margin-left: 10px;
    border-radius: 10px !important;

}

.question-container > button {
    background-color: transparent !important;
}

.questions-inputs {
    width: 380px !important;
    border-radius: 10px !important;

}

.add-button-img {
    width: 35px;
    transition: 200ms ease-in-out;
    color: white;
}

.add-button-img:hover {
    transform: scale(1.02);
}

.add-button {
    background-color: transparent !important;
}

.advanced-settings > p {
font-size: 18px !important;
font-weight: 500;
margin-top: 30px;
margin-bottom: 10px;
border-radius: 100px;
width: fit-content;
}


.input-value-fields {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.question-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px;
    border: 1px solid rgb(224, 224, 224);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.question-container-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 20px;

}


.inputs {
    display: flex;
}

.config-section-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.bonus-section {
    width: 100%;
    position: relative;
}

.bonus-top-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -10px;
}

.bonus-top-section > h4 {
    font-family: 'Jost';
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    color: grey;
    padding: 5px 10px ;
    border-radius: 100px;
    background-color: #8192dd;
    color: white;
    margin-top: 5px;
}

.config-upload-inputs {
    display: flex;
}

.number-input-wrapper {
    display: flex;
    align-items: center;
    width: 180px;
    flex-direction: column;
    margin-right: 30px;
    padding: 20px;
    border-radius: 20px;
    height: 240px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 20px;

}

.upload-image-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.upload-image-spinner > span {
    margin-top: 20px;
}

.number-input-wrapper img {
    cursor: pointer;
    width: 30px; /* Oder die gewünschte Größe */
    margin: 5px 0;
}

.number-input-wrapper > p {
    margin-bottom: 20px;
    margin-top: 5px;
    color: grey;
}

#userdata-box {
    margin-left: 0px;
    margin-right: 15px;
    margin-top: 5px;
}

.user-data-checks {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 10px;
    height: 42px !important;
    border-radius: 8px;
}

.upload-input {
    width: 90px !important;
    text-align: center;
    padding-left: 20px !important;
    font-size: 18px;
    margin-top: 10px;
    border-radius: 100px !important;
    pointer-events: none;
}


.bonus-top-section > button {
    background-color: transparent;
}

.bonus-top-section > button:hover {
    background-color: transparent;
}
.trash-icon {
    width: 20px;
    margin-right: -10px;
    margin-top: 5px;
    transition: ease-in-out;
    visibility: hidden;
    transition: 200ms ease-in-out;
}

#blue {
    background-color: var(--blue) !important;
}

.bonus-section:hover .trash-icon {
    opacity: 1;
    visibility: visible;
    transition: 200ms ease-in-out;
}

.question-container:hover .trash-icon {
    opacity: 1;
    visibility: visible;
    transition: 200ms ease-in-out;
}

/* Das Standard-Styling der Checkbox entfernen */
.is-active-box {
    appearance: none;
    width: 30px;
    height: 30px;
    position: relative;
    border-radius: 50%; /* Rund machen */
    outline: none; 
    cursor: pointer;
    background: transparent;
    transition: background-color 0.3s ease;
    margin-left: 10px;
    margin-top: 5px;
    border: 2px solid var(--blue);
}

/* Stil, wenn die Checkbox aktiviert ist (checked) */
.is-active-box:checked {
    background-color: var(--blue) !important; /* Von --blue zu einer dunkleren Farbe */

    /* Hintergrundfarbe wenn aktiviert */
}

/* Ein Häkchen-Symbol für das "Checked"-Zustand */
.is-active-box:checked:after {
    content: "\2713"; /* Unicode für Haken */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: white;

}







.trash-icon:hover {
    transform: scale(1.03);
}

.add_bonus_button {
    width: 100px;
    border-radius: 100px !important;
    background-color: var(--blue) !important;
    
}

.add_bonus_button-2 {
    width: 120px;
    border-radius: 100px !important;
    color: var(--blue);
    background-color: var(--blue) !important;

}
