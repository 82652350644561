.package-selection-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.719);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .package-selection-modal-content {
    background: white;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 30px;
    max-width: 540px;
    width: 100%;
    text-align: center;
    color: black; /* Textfarbe auf schwarz setzen */
  }

  .package-selection-modal-content > h2 {
      font-family: 'Jost';
      font-weight: 600;
      font-size: 32px;
  }
  
  .package-selection-modal-price-options {
    display: flex;
    justify-content: space-around;
    margin: 20px 0px;
    padding: 10px 0px;
    border-radius: 15px;
  }
  
  .package-option {
    border: 1px solid rgba(221, 221, 221, 0.329);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    width: 45%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start; 
  }

  .package-option > img {
      width: 20px;
      position: absolute;
      right: 10px;
      top: 22px;
      display: none;
  }
  .package-option.selected > img {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 22px;
    display: flex;
}

  .package-option > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding-left: 5px;
  }



  .package-option > div > h4 {
      text-align: left;
      width: 100%;
      font-family: 'Jost';
      font-weight: 600;
  }
  .package-option > div > div > h4 {
    text-align: left;
    font-family: 'Jost';
    font-weight: 600;
}

.two-years-option {
    display: flex;
    flex-direction: row !important;
}

.special-price-option {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}

.package-option > div > div > span {
    font-size: 12px;
    color: white;
}
  
  .package-option.selected {
    border: 1px solid var(--blue);
    border-radius: 10px;
  }
  
  .package-option input {
    display: none;
  }
  
  .package-option span {
    display: block;
    color: gray;
    font-size: 14px;
  }

  .special-badge {
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0.5px;
      border: 1px solid rgb(199, 199, 199);
      padding: 5px 10px;
      border-radius: 7px;
      margin-left: 10px;
  }
  
  .save-banner {
    background-color: #ff6b6b;
    color: white;
    padding: 2px 10px;
    border-radius: 100px;
    margin-left: 5px;
  }
  
  .package-features {
    text-align: left;
    margin: 20px 0;
    color: rgb(43, 43, 43); /* Textfarbe auf schwarz setzen */
  }
  
  .package-features div {
    margin-bottom: 20px;
  }

  .package-price-hr {
      margin-top: 30px;
      margin-bottom: 30px;
      border: none;
      margin-left: 10px;
      margin-right: 10px;
      height: 1px;
      background-color: rgb(230, 230, 230);
  }
  
  .package-features img {
    width: 20px;
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 10px;
    opacity: 0.8;
  }
  
  .package-total {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 10px 35px 10px;
    color: black; /* Textfarbe auf schwarz setzen */
  }
  
  .package-selection-modal-checkout-button {
    background-color: #0074d4;
    color: white;
    padding: 17px 20px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    border-radius: 100px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .package-selection-modal-button-spinner {
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #ffffff;
    animation: spin 1s linear infinite;
  }
  
  .package-secure-payment {
    margin-top: 20px;
    font-size: 12px;
    color: #666;
  }

  .package-secure-payment > img {
    width: 160px;
  }
  