.success-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }

  .success-page  h2 {
    font-family: 'Jost';
    font-size: 32px;
    color: var(--blue);
  }
  
  .success-page p {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 1rem;
    max-width: 500px;
  }

  .success-page img {
    width: 100px;
  }
  
  .success-page button {
    background-color: var(--blue); /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius:100px;
  }
  
  .success-page button:hover {
    background-color: #45a049;
  }
  