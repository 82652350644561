.add-manager-container {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
}

.add-manager-container select {
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
}

.add-manager-container button {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.add-manager-container button:hover {
    background-color: #0056b3;
}

.add-manager-container p {
    margin-top: 10px;
    color: green;
}
