.page_public {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    min-height: 100vh;
    position: relative;
    animation: fadeIn 0.1s ease forwards;
    width: 100%;
}



.whatsapp-button-live {
    position: fixed; /* Ändert die Positionierung von absolut zu fixed */
    bottom: 20px; /* Abstand vom unteren Rand des Viewports */
    right: 20px; /* Abstand vom rechten Rand des Viewports */
    width: 60px; /* Passen Sie die Größe nach Bedarf an */
    height: 60px; /* Passen Sie die Größe nach Bedarf an */
    cursor: pointer;
    z-index: 1000;
  }
  

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.widget-boss-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 600px !important;

}


#google_translate_element {
    padding: 5px;
    background-color: none !important;
    background: none
}

.page_public > img {
    position: fixed;
    top: 20px; 
    left: 20px;
    height: 40px;
}

.loading {
   height: 100vh;
   position: relative;
   width: 100vw;
}

.spinner3 {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid var(--blue);
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    top: 0% !important;
    background-color: rgb(245, 245, 245);
    margin-left: -20px;
    margin-top: -50px;

}

.loading {
display: flex;
align-items: center;
justify-content: center;
width: 100vw;
height: 100vh;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#spinnerle {
    position: absolute;
    top: 50% !important;
    left: 50%;
}


.page_public_widgets_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 100%;
}


@media (max-width: 600px) {
    
.page_public > img {
    display: none;
}
}