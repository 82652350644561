/* Grundlegendes Styling für das Widget */
#google_translate_element .goog-te-gadget-simple {
    border: none;
    font-family: 'Jost', sans-serif;
    background-color: transparent;
    align-items: center;
    font-size: 18px;
    display: flex !important;
  }



  #google_translate_element > img {
    filter: invert(0);
    width: 20px;
    margin-right: 10px;
  }
  
  /* Textanpassungen im Dropdown */
  #google_translate_element .goog-te-gadget-simple .goog-te-menu-value span {
    color: black !important;
    text-decoration: none;
  }

  #google_translate_element span[style*="border-left: 1px solid rgb(187, 187, 187);"],
  #google_translate_element span[style*="color: rgb(118, 118, 118);"],
  #google_translate_element span[style*="color: rgb(155, 155, 155);"],
  #google_translate_element span[style*="color: rgb(213, 213, 213);"]  {
    display: none;
  }

  .google_translate_wrapper {
    border: 1px solid;
    margin-top: 25px;
    margin-bottom: 15px;
    width: fit-content;
    padding: 4px 5px 4px 0;
    border-radius: 100px;
    display: flex;
    opacity: 0;
    animation: translatefadeIn 2s forwards;
    animation-delay: 1s;
  }

  .google_translate_wrapper.translate1 {
    background-color: rgba(0, 0, 0, 0.445);
    border-color: rgba(255, 255, 255, 0.527);
}

.google_translate_wrapper.translate2 {
    background-color: rgba(255, 255, 255, 0.479);
    border-color: rgba(0, 0, 0, 0.137);
}

  @keyframes translatefadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
    background-color: transparent;
    padding: 10px;
    border-radius: 1px;
    border: none !important;
    font-weight: 400;
    font-size: 18px !important;
  }

  .google_translate_wrapper > img {
    width: 25px;
    margin-left: 20px;
    margin-right: -10px;
  }

  .VIpgJd-ZVi9od-xl07Ob-lTBxed img {
    display: none;
  }

  
  /* Entfernen des Google Logos */
  .goog-te-gadget-icon {
    display: none;
  }
  
  /* Pseudo-Elemente für benutzerdefinierte Stile */

  
  /* Entfernen des Google Translate Banners */
  .goog-te-banner-frame.skiptranslate {
    display: none !important;
  }


