.page-wizard-button-2 {
    border-radius: 50%;
    width: 57px;
    height: 57px;
    background: linear-gradient(45deg, #ff6b6b, #ffcc5c, var(--blue), #56b4d3);
    background-size: 400% 400%;
    color: white;
    border: 1.5px solid white;
    position: absolute; /* Positionierung relativ zu add_page_big_cta */
    bottom: 9px;
    right: -70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    animation: gradientAnimation 10s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}



.page-wizard-button-2 > img {
    width: 23px;
    filter: invert(1);
}

.ai-info-addon {
    position: absolute;
    top: -2px;
    right: -5px;
    padding: 5px;
    background-color: var(--blue);
    border-radius: 100px;
    width: 22px;
    height: 22px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 700;
    border: 1px solid white;
}


.hotel-image-url {
    width: 70%; 
    border-radius: 25px;
}

.image-containerro {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.page-wizard-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.479);
    display: flex;
    align-items: center;
    backdrop-filter: blur(20px);
    justify-content: center;
    z-index: 1500;
}

.hotel-info-container {
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 35px;
    padding: 50px 15px;
}

.page-wizard-modal-content {
    background: linear-gradient(45deg, #ff6b6b, #ffcc5c, var(--blue), #56b4d3);
    width: 600px;
    max-height: 80vh;
    margin-top: 5%;
    border: 1px solid white;
    padding: 5px;
    min-height: 300px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;   
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    background-size: 800% 800%;
    animation: gradientAnimation 10s ease infinite;
}

.page-wizard-modal-header {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-wizard-modal-header h2 {
    margin: 0;
    text-align: center;   
    font-family: 'Jost';
    width: 100%;
    font-size: 24px;
    margin-right: 0px;
    color: white;

}


.page-wizard-close-button {
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
    filter: invert(1);
    margin-right: 5px;
    padding: 0px;
}

.page-wizard-modal-body {
    padding: 16px;
    overflow-y: auto;
    flex-grow: 1;
}

.enter-page-name-label {
    font-size: 14px;
    text-align: left !important;
    width: 100%;
    font-weight: 500;
    margin-top: 15px !important;
    color: rgb(63, 63, 63);
    color: white;
}

.page-wizard-name-input {
    width: 100%;
    height: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: rgba(255, 255, 255, 0.39);
    border: 1px solid #ffffff;
    border-radius: 12px;
    text-align: left;
    font-weight: 500;
    color: white;
    padding: 0 15px;
    font-size: 16px;
}

.fetch-hotel-info {
    width: 94%;
    background: linear-gradient(45deg, #ff6b6b, #ffcc5c, var(--blue), #56b4d3);
    color: white;
    background-size: 400% 400%;
    animation: gradientAnimation 10s ease infinite;
    border: none;
    font-weight: 500;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    height: 50px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 16px;
    position: absolute;
    bottom: 0;
    left: 3%;
}


.wizard-next-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: 1.5px solid white;
    padding: 15px 30px;
    background-color: var(--blue);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;    color: white;
    cursor: pointer;
    outline: none;
}

.page-wizard-modal-body button[disabled] {
    background-color: #b0c4de;
    cursor: not-allowed;
}



.page-wizard-modal-body h3,
.page-wizard-modal-body h4 {
    margin-top: 0;
    text-align: center;
    font-family: 'Jost';
}

.page-wizard-modal-body h4  {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
}

.page-wizard-modal-body h3 {
    margin-top: 20px;
    font-size: 24px;
}

.page-wizard-modal-body p,
.page-wizard-modal-body textarea {
    margin: 8px 0;
    text-align: center;
}

.page-wizard-modal-body p {
        text-align: center;
}


.highlights-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.highlight-box {
    display: flex;
    align-items: flex-start;
    border: 2px solid white;
    border-radius: 25px;
    padding: 20px 15px;
    margin-bottom: 25px;
    position: relative;
    background-color: transparent;
    width: 100%;
}

.highlight-content {
    flex-grow: 1;
}

.highlight-box strong {
    display: block;
    cursor: pointer;
    font-size: 22px;

}

.highlight-box p {
    margin: 8px 0;

}

.hotel-name-input {
    width: 100%;
    text-align: center;
    height: 50px;
    font-size: 20px;
    border: none;
    background: none;
    background-color: none;
    margin-top: 10px;
    font-weight: 600;
}


.highlight-box h5 {
    margin: 8px 0;
    font-weight: 400;
    font-size: 16px !important;
    color: rgb(100, 100, 100);

}

.highlight-box.selected {
    background-color: #ffffff;
    border: 1px solid white;
}

.hightlight-widget-button.active {
    background-color: var(--blue);
    color: white;
}

.highlight-widget-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hightlight-widget-button {
    margin: 5px;
    padding: 3px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
}

.hightlight-widget-button > img {
    width: 145px;
    border-radius: 18px;
}

.delete-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
}

.page-wizard-modal-footer {
    padding: 16px;
    border-top: 1px solid #eaeaea;
    display: flex;
    justify-content: flex-end;
}

.page-wizard-modal-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 8px;
}

.page-wizard-modal-button[disabled] {
    background-color: #b0c4de;
    cursor: not-allowed;
}

.loading-spinerro-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loading-spinerro-text {
    text-align: center;
    font-family: 'Jost';
    margin-bottom: 20px;
    margin-top: 15px;
    color: white;
    transition: 200ms ease-in-out;
}

.loading-spinnerro-cont {
    position: relative;
    width: 85px;
    margin-top: 10px;
}

.loading-spinnerro-cont > img {
    width: 30px;
    position: absolute;
    right: 27px;
    top: 30px;
}

.loading-spinnerro {
    border: 4px solid rgb(255, 255, 255);
    width: 85px;
    height: 85px;
    border-radius: 50%;
    border-left-color: var(--blue);
    animation: spin 1s ease infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
