.wizard-final-details {
    padding: 20px;
    border-radius: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.wizard-final-color-picker-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    border-radius: 100px;
    border: none;

}

.wizard-final-color-picker-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;

}

.wizard-final-color-picker-section p {
    margin-bottom: 10px;

}

.wizard-final-color-options {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-left: 25px;

}

.wizard-final-color-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.wizard-final-color-picker {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.wizard-final-font-style-container {
    margin-top: 20px;
    text-align: center;
}

.wizard-final-font-style-options {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

}

.wizard-final-font-style-box {
    padding: 20px 20px;
    border: 2px solid transparent;
    cursor: pointer;
    text-align: center;
    border: 1px solid rgb(231, 231, 231);
    width: 140px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.wizard-final-font-style-box.selected {
    border-color: var(--blue);
    background-color: #f0f8ff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.wizard-final-font-color-toggle {
    width: 200px;
    height: 55px;
    align-items: center;
    justify-content: center;
}

.wizard-toggle-button {
    padding: 7px 14px;
    margin: 0 5px;
    font-size: 12px;
    font-weight: 600;
}

.wizard-final-font-primary {
    font-size: 24px;
    margin-bottom: 5px;
}

.wizard-final-font-secondary {
    font-size: 16px;
}

.wizard-final-upload-container.drag-over {
    background-color: #e0e0e0;
}

.wizard-final-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    border-radius: 25px;
    border: 2px dashed grey;
    height: 130px;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
}

.wizard-final-upload-container:hover {
    background-color: rgb(233, 233, 233);
    cursor: pointer;
}

.wizard-final-upload-button-container {
    position: relative;
}

.wizard-final-upload-button {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-align: center;
    border: none;
    position: relative;
    z-index: 1;
}

.wizard-final-upload-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.wizard-final-upload-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #09f;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: wizard-final-spin 1s ease infinite;
    margin-top: 10px;
}

@keyframes wizard-final-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.wizard-final-image-preview {
    max-width: 250px;
    padding: 5px;
    height: 120px;
    border: 1px solid rgba(151, 151, 151, 0.205);
    border-radius: 20px;
    object-fit: contain;
    margin-top: 0px;
}
