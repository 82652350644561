.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s;
}

.hidden {
    visibility: hidden;
    opacity: 0;
}

.table-container {
    width: 100vw;
    overflow-x: auto;
    margin-top: 60px;
}

.premium-background-message-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white !important;
    position: absolute;
}


/* Tabelle Stile */
table {
    width: auto;  /* Lassen Sie die Tabelle ihre natürliche Breite verwenden */
    min-width: calc(12 * 180px); /* 12 Spalten * 100px pro Spalte */
    border-collapse: collapse;
    margin-top: 0px;
    border-radius: 8px;
}


th {
    white-space: nowrap;
}

th, td {
    width: 180px;  /* Setzt die Breite jeder Spalte auf 100px */
    white-space: nowrap;  /* Verhindert den Umbruch von Text */
    text-align: center;
    border-bottom: 1px solid #e2e2e2;
    overflow-x: auto;
    font-size: 12px;
}

tr:nth-child(even) {
    background-color: rgb(238, 244, 255);
}

thead {
    background-color: #f8f8f8;
    font-size: 14px;
    border: 1px solid rgb(214, 214, 214);
    height: 40px;

}

tr:hover {
    background-color: rgb(243, 243, 243);
}


/* Runde Bilder */
.image-box img {
    width: 50px;
    height: 50px;
    cursor: pointer; 
    object-fit: cover;
    border-radius: 20px;
    z-index: 1;
    transition: transform 0.3s; /* Überlappungsanimation */
    
}

.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    
}

.show-more-plus {
    width: 25px !important;
    height: 25px !important;
    margin-top: 12px;
    margin-left: 5px;
    margin-right: 20px;
}

.database-switch-buttons {
display: flex;
align-items: center;
justify-content: center;
width: 37px;
height: 37px;
background-color: rgb(255, 255, 255);
border-radius: 100px;
border: 1px solid rgba(151, 151, 151, 0.404);
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.database-switch-1 {
    width: 16px !important;
    height: 24px;
    opacity: 0.7;
}

.database-switch-2 {
    width: 16px;
    opacity: 0.7;
}


.dashboard-video {
    width: 50px;
    max-height: 50px;
    border-radius: 10px;
    border-radius: 100px;
}

.icon-button-fav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    background-color: rgb(243, 188, 188);
    box-shadow: rgba(100, 100, 111, 0.158) 0px 7px 29px 0px;

}

.icon-button-all{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(224, 224, 224);
    box-shadow: rgba(100, 100, 111, 0.171) 0px 7px 29px 0px;

}
.heart-icons-1 {
    width: 20px;
}




.heart-icons-2 {
    width: 20px;
    opacity: 0.5 !important;

}


.image-box {
    display: flex;
    align-items: left;
    justify-content: left;
    gap: 5px;
    overflow: hidden; /* Wichtig, um sicherzustellen, dass das Bild nicht außerhalb des Containers angezeigt wird */
    position: relative; /* Position relativ, damit das Bild korrekt innerhalb positioniert wird */
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
}
.button_container {
    position: relative;
}


/* Buttons */
.delete-button {
    padding: 5px 15px;
    margin: 5px;
    border: none;
    cursor: pointer;
    border-radius: 100px;
    transition: background-color 0.3s ease;
    background-color: rgb(194, 62, 62);
    color: white;
    font-size: 10px;
    margin-left: 20px;
}



.dashboard {
    max-width: 100vw !important;
    margin: 0 !important;
    overflow: hidden;
}

.db_settings_button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    padding: 0px 15px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(212, 212, 212);
    border-radius: 100px;
    margin-left: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.db_settings_button > p {
    text-transform: uppercase !important;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: black;
}

.db_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 110px;
    margin-right: 40px;
    margin-bottom: 30px;
}

.db_bar_left {
    display: flex;
}

button {
    border: none; 
    border-radius: 100px;
    padding: 5px 15px;
    transition: ease-in-out 200ms;

}

button:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    transition: opacity 0.5s, visibility 0.5s;
    visibility: visible;
    opacity: 1;
}



.loading-spinner > h1 {
    font-size: 42px;
    margin-bottom: 30px;
    margin-top: -100px;
}


.loading-spinner > h1 {
    color: #ffffff;
    font-size: 52px;
    font-weight: 700;
    background: linear-gradient(to right, #4080b0 1%, #FFE89E 40%, #4080b0 70%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1s linear infinite;
    display: inline-block;
    max-width: 85%;
    z-index: 1;
  }

  .access {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 100px;
      position: fixed;
      z-index: 500;
      background-color: white;
      height: 100vh;
      width: 100vw;
      margin-top: 88px;
      top: 0;
      opacity: 1;
  }






  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }

  
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: var(--blue) !important;
    animation: rotate 1s linear infinite;
}



@media (max-width: 800px) {
    .icon-button-all{
      
        margin-left: 20px;
    }
    .icon-button-fav {
       
        margin-left: 20px;
    
    }
}