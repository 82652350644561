.add-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 85px;
    width: 100%; /* Stellen Sie sicher, dass die Breite definiert ist */
    height: 100vh;
}
.add-page-form {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    margin-bottom: 30px;
}


.add-page-form h2 {
    margin-bottom: 10px;
}

.add-page-form input,
.add-page-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #DADADA;
    border-radius: 4px;
}

.add-page-form button {
    background: #4080B0;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.pages-display {
    width: 100%;
}

.pages-display > div > h2 {
    font-size: 28px;
    font-family: 'Jost';
    margin-top: 0px;
    font-weight: 600;
    margin-left: 30px;
}

.pages-display-header_bar {
    height: 70px;
    display: flex;
    align-items: center;
    width: 100vw;
    position: fixed;
    z-index: 30;
}

.add_page_top_header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.spinner-frame {
    display: flex;
    align-items: center;
    justify-content: center;
}
.no-pages-message {
    margin-top: 50px;
    z-index: 100;
    font-size: 42px;
    margin-bottom: 30px;
    max-width: 650px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.no-pages-message > p > span {
    font-weight: 300;


}

.no-pages-message-2 {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 2000;
    font-size: 16px;
    margin-bottom: 30px;
    max-width: 650px;
    font-weight: 500;
    bottom: 0;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.637);
    border-radius: 100px;
    padding: 10px;
    text-decoration: none;
    backdrop-filter: blur(50px);
    animation: appear 12s; 
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.no-pages-message-2 > a > p > span {
    font-weight: 300;
    margin-right: 10px;
}

.no-pages-message-2 > a > p {
    margin-right: 10px;
}


@keyframes appear {
    0% {
        opacity: 0;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.val-pic {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 100px;
    margin-right: 15px;
    border: 1px solid rgb(241, 241, 241);
}

.add_page_big_cta {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    height: 75px;
    min-width: 700px;
    background-color: rgba(255, 255, 255, 0.808) !important;
    z-index: 100;
    border-radius: 15px;
    max-width: 700px;
    margin-bottom: 0px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.5) 0px 30px 60px -30px;

}
.add-page-button-icon {
    width: 22px;
    rotate: 270deg;
    filter: invert(1);
    margin-right: -10px;
}

.add-page-highlight-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--blue); /* Stellen Sie sicher, dass --blue definiert ist */
    color: white;
    font-size: 18px;
    height: 60px;
    width: 280px;
    border-radius: 12px;
    margin-right: -27px;
    position: relative; /* Für das absolute positionierte Pseudo-Element */
    overflow: hidden; /* Stellt sicher, dass der "shiny" Effekt innerhalb der Grenzen bleibt */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.1) 0px 3px 7px -3px;

}
.add-page-highlight-button::before {
    content: "";
    position: absolute;
    top: -100%; right: -100%; bottom: -100%; left: -100%; /* Erstreckt sich über den gesamten Button */
    background: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.295), transparent);
    filter: blur(25px);
    animation: rotateGlow 5s infinite linear; /* Glatte, kontinuierliche Animation */
}

.top-text-container {
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 15px;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
    letter-spacing: 1px;
}


.inactive-qr-background {
    position: absolute;
    padding: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 15px;
    width: 180px;
    height: 180px;
    background-image: url('../img/qr.png'); /* Ersetzen Sie 'path/to/your/image.jpg' durch den Pfad zu Ihrem lokalen Bild */
    background-size: cover; /* Das Bild deckt den gesamten Bereich ab */
    filter: blur(5px); /* Wendet eine Unschärfe von 5px auf das gesamte Element an */
z-index: 0;
opacity: 0.1;
}




.add-page-highlight-button > p {
    margin-right: 20px;
    margin-bottom: 2px;
    font-weight: 500;
}
.add_page_big_cta > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add_page_big_cta > div > p {
    font-size: 18px;
    margin-right: 2px;
    font-weight: 500;
}

.add_page_big_cta > div > input {
    border: none;
    font-size: 20px;
    z-index: 100;
    background-color: transparent;
}

.add_page_top_header > h2 {
    font-family: 'Jost';
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 24px;
    display: none;
}

.add_page_top_header > p {
    max-width: 600px;
    line-height: 180%;
    margin-top: 10px;
    display: none;

}

.circle-overlay {
    position: absolute;
    top: 78px; /* Passt die Position nach Bedarf an */
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Stellen Sie sicher, dass dieser Wert den des QR-Codes übersteigt */
    background-color: rgb(255, 255, 255);
  }
  
  .circle-overlay img {
    width: 28px;
    height: auto;
    filter: invert(1);
  }

  .link-to-layouts > img {
      width: 20px;
      margin-left: 10px;
  }

  .link-to-layouts {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.459);
      z-index: 100;
      height: 70px;
      backdrop-filter: blur(50px);
      color: black;
      border: 1px solid rgba(250, 250, 250, 0.973);
      text-decoration: none;
      border-radius: 100px;
      width: 300px;
      margin-bottom: 0px;
  }


  .layouts-add {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    z-index: 10;
    height: 120px;
    margin-bottom: -50px;
}

.layouts-add > p {
    margin-bottom: 50px;
    font-weight: 500;
}

  .link-to-layouts > h2 {
      font-family: 'Jost';
      font-size: 18px;
      font-weight: 500;
  }

.pages-grid {
    display: flex;
    flex-wrap: wrap; /* Ermöglicht den Umbruch von Elementen auf die nächste Zeile */
    gap: 20px; /* Behält den Abstand zwischen den Elementen bei */
    margin: 40px auto 0; /* Zentriert den Flex-Container horizontal mit automatischen Seitenrändern und behält den oberen Rand bei */
    justify-content: center; /* Zentriert die Elemente innerhalb des Flex-Containers horizontal */
    align-items: flex-start; /* Stellt sicher, dass Elemente am Anfang der Zeile ausgerichtet sind, was bei unterschiedlichen Höhen nützlich sein kann */
    width: calc(100% - 60px);
    z-index: 100; /* oder eine spezifische Höhe, je nach Bedarf */    /* Berücksichtigt die margin-left von 30px auf beiden Seiten */
}

.premium-restriction-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
    max-width: 500px;
    margin-top: -150px;
    font-size: 28px;
    background-color: rgba(255, 255, 255, 0.24);
    padding: 50px 15px 0px 15px;
    border-radius: 25px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border: 1px solid white;

}

.premium-restriction-message > img {
    width: 350px;
}

.premium-restriction-div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.pro-feature-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white !important;
    letter-spacing: 1px;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: var(--blue);
    border-radius: 100px;
    font-size: 18px;
    margin-top: 35px;
    transition: 100ms ease-in-out;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.pro-feature-button-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.199);
    text-transform: uppercase;
    color: black !important;
    letter-spacing: 1px;
    width: 100%;
    height: 70px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
    border-radius: 100px;
    font-size: 18px;
    margin-top: 0px;
    transition: 100ms ease-in-out;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}


.pro-feature-button:hover {
      transform: scale(1.01);
}

.pro-feature-button-2:hover {
    transform: scale(1.01);
    background-color: white;

}

.bonusclub-bullet-points-container {
    width: 100%;
}

.bonusclub-column {
    text-align: left;
    width: 40%;
  }
  
  .bonusclub-bullet-point {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .bonusclub-checkmark {
    width: 20px;
    margin-right: 10px;
  }

  .bonusclub-bullet-point > span {
      font-size: 15px;
      font-family: 'Jost';
      width: 100%;
      color: rgb(71, 71, 71);
  }


  .bonusclub-bullet-points-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

.premium-restriction-message > h2 > span {
    width: 600px;
    font-weight: 600;
    font-family: 'Jost';
    margin-right: 10px;

}

.premium-restriction-message > h2 {
    width: 600px;
    font-family: 'Jost';
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 10px;
 
}


@keyframes gradientAnimation {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.premium-restriction-message > p {
    font-size: 18px;
    font-weight: 400;
    margin-top: -10px;
    margin-bottom: 20px;
}

.page-card {
    background: #ffffff3f;
    border: 1px solid rgb(231, 231, 231);
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 4px 38px rgba(0, 0, 0, 0.03);
    text-align: center;
    width: 320px;
    margin-top: 10px;
    position: relative;
    max-height: 450px;
    box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
    transition: 100ms ease-in-out;
}

.page-card:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.page-card h3 {
    margin-bottom: 10px;
    font-size: 24px;
    font-family: 'Jost';
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -10px;
}

.page-card p {
    margin-bottom: 20px;
    font-size: 16px;
    margin-left: 15px;
    margin-right: 15px;
}

.page-card-description {
    min-height: 120px;

}

.page-card > button {
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    right:5%; 
    bottom: 15px;
    width: 90%;
}

.add-new-page-btn{
    position: fixed;
    right: 30px;
    top: 97px;
    background-color: var(--blue) !important;
    padding: 13px 30px;
    color: white;
    font-size: 14px;
    z-index: 40;
    /* Weiterer Stil für Ihren Button */
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000; /* Stellen Sie sicher, dass das Modal über anderen Inhalten liegt */
}






.text-input-field {
    width: 380px;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #ffffff;
    padding-left: 20px;
    font-size: 16px;
    border: 1px solid rgb(202, 202, 202);
    font-weight: 500;
}

.text-input-field-2 {
    width: 380px;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #ffffff;
    padding-left: 20px;
    font-size: 16px;
    border: 1px solid rgb(202, 202, 202);
    font-weight: 500;
}


.text-input-field::placeholder {
    color: rgb(201, 201, 201);
}

.text-input-field-2::placeholder {
    color: rgb(201, 201, 201);
}

.modal-content-label {
    font-size: 12px;
    color: grey;
    margin-bottom: 15px;
    margin-top: 20px;
    font-family: 'Jost';
    text-transform: uppercase;
    letter-spacing: 2px;
}


.description-input-field {
    width: 380px;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #ffffff;
    padding-left: 20px;
    font-size: 16px;
    height: 200px;
    padding-top: 10px;
    margin-bottom: 40px;
    border: 1px solid rgb(202, 202, 202);
    outline: none;
    padding-right: 20px;
    resize: none; /* Deaktiviert die Möglichkeit, das Feld größer zu ziehen */
    
}


.description-input-field-2 {
    width: 380px;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #ffffff;
    padding-left: 20px;
    font-size: 16px;
    height: 100px;
    padding-top: 10px;
    margin-bottom: 40px;
    border: 1px solid rgb(202, 202, 202);
    outline: none;
    padding-right: 20px;
    resize: none; /* Deaktiviert die Möglichkeit, das Feld größer zu ziehen */
    
}

.description-input-field::placeholder {
    color: rgb(201, 201, 201);
}

.description-input-field-2::placeholder {
    color: rgb(201, 201, 201);
}


.page-card-delete-icon {
    width: 22px;
    height: 20px;
}

.page-card-copy-icon {
    width: 22px;
}

.page-card-upright-icon {
    width: 34px;
    margin-top: -6px;
    margin-right: -6px;
}

.page-card-icon-button {
    margin-right: 0px;
    margin-left: 0px;
    background: none
}


.page-card-header-left > div {
display: flex;
align-items: center;
height: 30px;
width: fit-content;
}

.page-card-header-left {
    position: absolute;
    left: 18px;
    top: 14px;
}

.page-card-header-right {
display: flex;
flex-direction: row-reverse;
position: absolute;
right: 18px;
top: 14px;
}


.page-card-header-left > div > p {
    margin-top: 20px;
    margin-left: 8px;

}

.page-card > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.page-qr-code {
    margin-top: 50px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.inactive-qr-code {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 200px;
    background-color: white;
    border-radius: 13px;
    margin-bottom: 30px;
    border: 1px solid rgb(207, 207, 207);
}

.inactive-qr-code > p {
    letter-spacing: 3px;
    margin-top: 20px;
}

.input-invalid {
    color: red !important;
    animation: shake 0.5s;
}

.input-invalid::placeholder {
    color: rgb(233, 69, 69); /* Rote Farbe für den Platzhalter */
    opacity: 1; /* Stellt sicher, dass die Farbe deutlich sichtbar ist */
    transition: 200ms ease-in-out;
}


@keyframes shake {
    0% { margin-left: 0px; }
    25% { margin-left: 5px; }
    75% { margin-left: -5px; }
    100% { margin-left: 0px; }
}



.button_page_select {
    color: var(--blue);
    background-color: white !important;
    padding: 20px 50px !important;
    border: 1px solid var(--blue) !important;
    border-radius: 100px !important;
    width: 100%;
    font-size: 16px;
}
.page-card-header-right-close {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -6px;
    opacity: 0; /* Startet unsichtbar */
    transition: opacity 0.1s ease; /* Weicher Übergang für Opazität */
    pointer-events: none; /* Deaktiviert Klick-Events, wenn unsichtbar */
}

.page-card:hover .page-card-header-right-close {
    opacity: 1; /* Wird sichtbar beim Hover über die gesamte Karte */
    pointer-events: auto; /* Aktiviert Klick-Events, wenn sichtbar */
}

.page-card-header-right-close button {
    opacity: 0; /* Startet unsichtbar */
    transition: opacity 1s ease, transform 0.3s ease; /* Weiche Übergänge für Opazität und Transformation */
    transform: translateY(-2px); /* Startposition leicht nach oben verschoben */
    transition-delay: 0s; /* Standard-Verzögerung */
}

.page-card:hover .page-card-header-right-close button {
    opacity: 1; /* Wird sichtbar beim Hover */
    transform: translateY(0); /* Kehrt zur ursprünglichen Position zurück */
}

/* Verzögerung für jedes Button-Element */
.page-card:hover .page-card-header-right-close button:nth-child(1) {
    transition-delay: 0.15s;
}

.page-card:hover .page-card-header-right-close button:nth-child(2) {
    transition-delay: 0.1s;
}

.page-card:hover .page-card-header-right-close button:nth-child(3) {
    transition-delay: 0.05s;
}
