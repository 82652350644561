.team-chat-window {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100vh - 90px);
    width: calc(100vw - 300px);
    padding: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    margin-top: 90px;
}

.team-messages-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 30px 0;
    margin-bottom: 100px;
}

.team-messages-list::-webkit-scrollbar {
    display: none; /* Für WebKit-Browser wie Chrome und Safari */
}

.team-message {
    display: flex;
    margin-bottom: 10px;
}

.team-message.right {
    justify-content: flex-end;
}

.team-message.left {
    justify-content: flex-start;
}

.team-chat-profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.team-bubble {
    max-width: 400px;
    background-color: #dddddd;
    padding: 5px 10px;
    border-radius: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    position: relative;
}

.team-message.right .team-bubble {
    background-color: var(--blue);
    color: white;
    border-bottom-right-radius: 0px !important;
    text-align: left;
}

.team-message.left .team-bubble {
    border-bottom-left-radius: 0px !important;
    text-align: left;
}

.team-bubble > img {
    width: 400px !important;
    max-height: 500px;
    object-fit: cover;
    border-radius: 10px;
}

.team-bubble > p {
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: 7px;
    margin-right: 7px;
}

.team-chat-message-input-container {
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: calc(100% - 300px);
    padding: 10px 15px;
    margin-left: -15px;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px -7px 29px 0px;
    border-top: 1px solid #ccc;
}

.team-chat-message-input {
    border-radius: 15px;
    background-color: var(--black);
    padding: 15px 150px 15px 15px;
    color: black !important;
    outline: none;
    font-family: 'Jost';
    font-size: 16px;
    resize: none; /* or vertical, both, none */
}

.team-chat-message-input input {
    flex-grow: 1;
    padding: 8px;
    margin-right: 10px;
    width: 100%;
    background: grey;
}

.team-chat-image-upload {
    position: absolute;
    right: 115px;
    bottom: 18px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: none;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-chat-image-upload:hover > img {
    opacity: 1;
    cursor: pointer;
}

.team-chat-image-upload > img {
    filter: invert(1);
    opacity: 0.8;
    user-select: none;
    width: 20px;
}

.team-chat-profile-image.right {
    margin-left: 10px;
    margin-right: 0px;
}

.team-chat-image-send-button {
    margin-left: 10px;
    height: 40px !important;
    border-radius: 100px;
    border: none;
    outline: none;
    background-color: var(--black);
    display: flex !important;
    align-items: center;
    rotate: 45deg;
    user-select: none;
    justify-content: center;
    transition: background-color 0.3s ease; /* Übergang für die Hintergrundfarbe */
}

.team-chat-image-send-button:hover {
    cursor: pointer;
    background-color: var(--black-hover); /* Hintergrundfarbe ändert sich beim Hovern */
}

.team-chat-image-send-button:active {
    transform: scale(0.9); /* Skalierungseffekt beim Klicken */
}

.team-chat-image-send-button > img {
    width: 25px !important;
}

.team-load-more-btn {
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
    background-color: var(--black);
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    font-family: 'Jost';
    text-transform: uppercase;
    color: rgb(187, 187, 187);
}

.team-read-status {
    position: absolute;
    bottom: -22px;
    font-size: 13px;
    right: 0;
}

.team-new-order-bubble  {
    width: 100% !important;
    background-color: rgb(230, 230, 230);
    text-align: center;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
}

.team-new-order-top-bar > p > strong > img {
    width: 15px !important;
    margin-right: 10px;
    margin-top: 5px;
    filter: invert(1);
}

.team-new-message-text {
    font-style: italic;
}

.team-new-order-top-bar > h3 {
    font-family: 'Jost';
    margin-right: 10px;
}

.team-delivery-truck-bar {
    display: flex;
    color: white;
    font-size: 13px;
    align-items: center;
    background-color: var(--blue);
    width: fit-content;
    justify-content: center;
    padding: 2px 10px;
    border-radius: 10px;
    
}

@media (max-width: 1000px) {
    .team-chat-image-upload {
        position: absolute;
        right: 95px !important;
        bottom: 18px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        border: none;
        background-color: var(--black);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 700px) {
    .team-chat-message-input textarea {
        padding: 15px 15px 15px 15px;
    }

    .team-superchat-open-button {
        display: none !important;
    }

    .team-chat-image-upload {
        right: 60px !important; 
    }

    .team-chat-prev-upladed-img {
        right: 60px;
        bottom: 8px;
    }

    .team-chat-image-upload {
        display: none;
    }

    .team-bubble > img {
        width: 150px !important;
        max-height: 200px;
        object-fit: cover;
        border-radius: 10px;
    }
}
