.page-settings-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  scroll-behavior: smooth;
}

.add-widgets,
.widget-edit {
  width: 28%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  scroll-behavior: smooth; /* Glattes Scrollen */
}

.loading_screen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.add-widgets::-webkit-scrollbar,
.widget-edit::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

.page-preview {
  width: 44%;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  scroll-behavior: smooth; /* Glattes Scrollen */
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}

.page-preview::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

.page-builder-header {
  position: fixed; 
  height: 60px;
  width: 100vw;
  background-color: white;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: left;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}

.page-builder-header > h1 {
  font-family: 'Jost' !important;
  margin-top: 20px;
  margin-left: 30px;
}

.page-preview-popup {
  position: fixed;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  left: 50%;
  transition: ease-in-out 200ms;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.185);
  backdrop-filter: blur(2px);
  padding: 10px;
  border-radius: 25px;
  height: 80px;
  font-family: 'Jost';
  font-size: 20px;
  text-transform: uppercase;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  color: rgb(172, 172, 172);
  z-index: 1000; /* Hoch genug, um über anderen Elementen zu erscheinen */
  animation: zoomFadeInFadeOut 3s ease-out forwards;

}

.page-preview-popup > img {
  margin-top: -10px;
  width: 40px;
  opacity: 0.3;
  margin-left: 5px;
  animation: bounce 2s ease infinite;

}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}

@keyframes zoomFadeInFadeOut {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0.8);
  }
  5% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  90% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(1);
  }
}
