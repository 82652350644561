.image-size-slider-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 10px;
}

.slided-label {
  margin-right: 10px;
  font-size: 12px;
  font-family: 'Jost';
  text-transform: uppercase;
  color: grey;
  margin-top: 10px;
}

.slided {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slided:hover {
  opacity: 1;
}

.slided::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
}

.slided::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #000;
  cursor: pointer;
}

.slided-value-input {
  width: 50px;
  padding: 5px 5px 5px 10px;
  margin-left: 10px;
  border: 1.5px dashed rgb(231, 231, 231);
  border-radius: 100px;
}
