.digital-fonts {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.digital-fonts > h2 {
    font-family: 'Jost';
}

.fonts-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.font-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: column;
    width: 500px;
    border-radius: 15px;
    position: relative;
    border: 1px dashed rgb(224, 224, 224);
}

.font-item > p {
    margin-right: 10px;
    max-width: 500px;
    height: 60px;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 35px;
    text-align: center;
}


.fonts-delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.branding-delete-button {
    width: 30px;
    height: 30px;
}

.font-actions {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.font-actions button {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.add-font {
    margin-top: 20px;
    border: 1px dashed lightgray;
    padding: 10px;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 500px;
}

.add-font input[type="file"] {
    display: none;
}

.file-input-label {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    font-family: 'Jost';
}

.font-preview {
    max-height: 100px;
}

.fonts-plus-icon {
    width: 20px;
    margin-right: 10px;
}

.add-font input[type="text"] {
    margin-right: 10px;
    padding: 5px;
    margin-top: 10px;
    width: 250px;
    border: 1px solid rgb(219, 219, 219);
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
}

.add-font button {
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100px;
    height: 50px;
}
