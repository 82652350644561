.page_preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    min-height: 100vh; 


}

.page_preview > h2 {
    margin-bottom: 30px;
    margin-top: 30px;
    font-family: 'Jost';
}

.page_preview_content {
    display: flex;
    align-items: center;
    justify-content: center;
}



.page_preview_header  {
height: 140px;
width: 100%;
display: flex;
align-items: flex-end;
justify-content: center;
border-bottom: 1px solid rgb(226, 226, 226);
color: rgb(179, 179, 179);
background-color: #ffffff; /* Basis-Hintergrundfarbe */
background-image: repeating-linear-gradient(
    45deg, /* Rotation des Musters */
    rgb(248, 248, 248),
    rgb(230, 230, 230) 1px,
    transparent 1px,
    transparent 6px /* Größerer Abstand zwischen den Linien */
);
background-size: 10px 10px; 
border-radius: 5px;
}

.page_preview_header > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    
}

.page_preview_header > div > img {
    height: 30px;
    opacity: 0.2;
}

.page_preview_header > div > p {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 3px;
}
  
.inner_preview_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.outer-preview-div {
    min-width: 100%;
}

.widget-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    flex-direction: column;
}

/* In Ihrer PagePreview.css Datei */
@keyframes shrinkAndFade {
    from {
      transform: scale(1);
      opacity: 1;
      height: auto;
    }
    to {
      transform: scale(0.5);
      opacity: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }
  }
  
  .exiting-widget {
    animation: shrinkAndFade 0.5s forwards;
  }
  


.widget-placeholder-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.widget-placeholder-top > h4 {
    font-family: 'Jost';
    font-weight: 400;
    color: rgb(179, 179, 179);
}

.widget-placeholder-bottom > h1 {
    font-family: 'Jost';
    font-size: 42px;
    color: lightgray;
    font-weight: 500;
}

@keyframes moveArrow {
    0%, 100% {
      left: -28px; /* Start- und Endposition, entspricht der ursprünglichen Position */
    }
    50% {
      left: -36px; /* Der Pfeil bewegt sich 12px nach links */
    }
  }
  
  .widget-placeholder-top > img {
      width: 25px;
      transform: rotate(90deg);
      position: absolute;
      left: -28px;
      opacity: 0.3;
      animation: moveArrow 2s infinite; /* Wende die Animation an, Dauer 2 Sekunden, unendliche Wiederholung */
  }
  