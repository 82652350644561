.digital-moods {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.digital-moods > h2 {
    font-family: 'Jost';
}

.moods-container {
    display: flex;
    margin-top: 20px;
    margin-left: -10px; /* Adjust to remove gutter */
    width: auto;
}

.moods-column {
    padding-left: 10px; /* Adjust to remove gutter */
    background-clip: padding-box;
}

.mood-item {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px dashed rgb(167, 167, 167);
    border-radius: 10px;
    position: relative;
}

.mood-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
}

.mood-preview {
    max-width: 100px;
    max-height: 100px;
    margin-top: 10px;
}

.branding-delete-button {
    height: 20px !important;
    position: absolute;
}

.branding-delete-button:hover {
    cursor: pointer;
}

.add-mood {
    margin-top: 20px;
    border: 1px dashed lightgray;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 500px;
    border-radius: 15px;
    text-align: center;
}

.add-mood > h3 {
    font-family: 'Jost';
}

.add-mood input[type="file"] {
    display: none;
}

.file-input-label {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    font-family: 'Jost';
}

.moods-plus-icon {
    width: 20px;
    margin-right: 10px;
}

.add-mood input[type="text"] {
    margin-right: 10px;
    padding: 5px;
    margin-top: 10px;
    width: 100%;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
}

.add-mood button {
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100px;
    height: 50px;
}

.moods-download-icon {
    position: absolute;
    bottom: 10px;
    font-size: 12px;
    font-family: 'Jost';
    border: 1px solid rgb(151, 151, 151);
    border-radius: 15px;
    right: 10px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.moods-download-icon > img {
    width: 14px;
}

.mood-item > p {
    margin-top: 10px;
}

@media (min-width: 1300px) {
    .moods-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
