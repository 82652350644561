.upload-image-preview-2 > img {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.upload-image-preview-2  {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}