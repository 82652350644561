/* WelcomeInfos.css */
.welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.welcome-form {
    background: rgba(255, 255, 255, 0.37);
    backdrop-filter: blur(50px);
    border-radius: 35px;
    padding: 40px;
    width: 500px;
    text-align: center;
    display: flex;
    align-items: left;
    flex-direction: column;
    z-index: 100;
    min-height: 640px;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.welcome-container > img {
    width: 200px;
    margin-bottom: 50px;
    z-index: 100;
}

.welcome-form h1 {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 28px;
    font-family: 'Jost';
}

.welcome-form p {
    margin-bottom: 40px;
    color: grey;
    font-size: 15px;
    margin-top: 0px;
}

.welcome-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 7px;
    border: 1px solid #ddd;
    background: rgba(255, 255, 255, 0.26);
    font-size: 16px;
}
.welcome-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    width: 100%;
    font-family: 'Jost';
}

.welcome-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-checkbox {
    display: flex;
    margin-right: 10px;
    height: 30px;
    width: 30px !important;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 50%;
    position: relative;
    transition: background-color 0.3s, border-color 0.3s;
}

.welcome-checkbox  > p {
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-right: 0px;

}

.welcome-checkbox input:checked + .custom-checkbox {
    background-color: var(--blue);
    border-color: var(--blue);
}

.custom-checkbox::after {
    content: '';
    position: absolute;
    display: none;
}

.welcome-checkbox input:checked + .custom-checkbox::after {
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    filter: invert(1);
    background: url('../../public/img/tick.svg') no-repeat center center;
    background-size: contain;
}

.welcome-button {
    width: 94%;
    background-color: var(--blue);
    color: white;
    border: none;
    border-radius: 100px;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    left: 3%;
    height: 60px;
}

.welcome-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

.type-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: left;
    margin-bottom: 20px;
}

.select-genre-list {
    height: 45px;
    padding: 0 15px;
    border-radius: 100px;
    color: rgb(56, 56, 56);
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 5px;
    font-family: 'Jost';
    font-size: 14px;
    outline: none;
    border: 1px solid rgba(66, 66, 66, 0.342);
    cursor: pointer;
}

.select-genre-list.selected {
    background-color: var(--blue);
    color: white;
}

@media (max-width: 500px) {
    .welcome-form {
        width: 90%;
    }

    .select-genre-list {
        width: 30%;
    }
}
