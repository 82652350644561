.header_component {
    width: 100vw;
    height: 110px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(17, 12, 46, 0.030) 0px 48px 100px 0px;
}
.header_img {
    max-height: 90px;
    max-width: 240px;
}

.header_img {
    opacity: 1; /* Bild anfangs versteckt */
    transition: opacity 100ms ease-out; /* Transition für das Einfaden */
}

.header_img.visible {
    opacity: 1; /* Bild sichtbar, wenn geladen */
}
