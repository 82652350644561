.marketing-database-container {
    padding: 20px;
}

.marketing-database-container > button {
    margin-right: 10px;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border-radius: 100px;
    color: white;
    cursor: pointer;
    font-weight: 500;
    background-color: var(--blue);
    width: 100%;
    font-size: 17px;
}


.delete-button-background {
    background-color: rgb(134, 130, 130);
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
}

.import-name-filter > select {
    height: 35px;
    margin-top: -5px;
    width: 150px;
    border: 1px solid grey;
    border-radius: 5px;
    margin-left: 10px;
}

.marketing-top-row {
    display: flex;
    margin-bottom: 20px;
    min-width: 100%;
}

.select-country-dropdown  {
    min-width: 150px;
    border-radius: 5px;
    margin-left: 15px;
    border: 1px solid gray;
}

.marketing-top-row > button {
    background-color: var(--blue) !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    color: white;
    min-width: 150px;
}

.marketing-top-row > button > img {
    margin-left: 10px;
}

.delete-button-background:hover {
    background-color: #b30000;
}

.marketing-modal button {
    margin-right: 10px;
    padding: 10px 20px;
    background-color: var(--blue);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.marketing-modal button:hover {
    background-color: #0056b3;
}

.marketing-table {
    width: 100%;
    border-collapse: collapse;
    min-width: none !important;
}



.marketing-table th, .marketing-table td {
    padding: 8px;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    border: 1px solid #ddd;
    min-width: none !important;
}

.marketing-table th {
    background-color: rgb(228, 226, 226);
    font-weight: 600;
    padding: 10px;
    color: rgb(65, 65, 65);
}

.marketing-table td img {
    max-width: 150px;
    height: auto;
    border-radius: 10px;
}

.marketing-table .scrollable {
    max-width: 200px;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.marketing-table .scrollable::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.marketing-table .no-scroll {
    overflow: hidden;
}

.marketing-table .limited-width {
    width: 100px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.marketing-table .button-cell, .marketing-table .checkbox-cell {
    width: auto;
}

.green-circle {
    width: 15px;
    height: 15px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
}

.marketing-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.marketing-modal h2 {
    margin-top: 0;
}

.marketing-table img {
    width: 150px;
    height: 150px;
    max-height: 150px;
    object-fit: cover;
}


.sort-buttons > button {
    width: 80px;
    height: 40px;
}

.sort-buttons {
    margin-bottom: 10px;
    margin-left: -10px;
}


/* MarketingDatabase.css */
.active-sort-button {
    border: 2px solid var(--blue);
    font-weight: 500;
    color: rgb(56, 56, 56);
}

.sort-buttons > button:hover {
    background-color: #0057b32c;
}
