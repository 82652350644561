.icon_feature {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97%;
    position: relative;
    padding: 0 10px 20px 10px;
    border-radius: 25px;
    overflow: hidden;
    transition: 0.5s ease-out;
}

.icon_feature-2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 97%;
    position: relative;
    padding: 0 40px 20px 40px;
    border-radius: 25px;
    overflow: hidden;
}
.icon_feature-4 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 97%;
    height: 300px;
    position: relative;
    padding: 0 40px 20px 40px;
    border-radius: 25px;
    overflow: hidden;
}
.icon_feature_5 {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 97%;
    height: 120px;
    position: relative;
    padding: 0 35px 0px 35px;
    border: 1px dashed rgb(196, 196, 196);
    border-radius: 100px;
    position: relative;
}

.icon_feature_7 {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 97%;
    position: relative;
    padding: 0 35px 0px 35px;
    border: 1px dashed rgb(196, 196, 196);
    position: relative;
    min-height: 160px;
}



.icon_feature_6 {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 97%;
    height: 90px;
    position: relative;
    padding: 0 35px 0px 35px;
    border: 1px dashed rgb(196, 196, 196);
    position: relative;

}

.icon_feature_8 {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 60px;
    position: relative;
    border: 1px dashed rgb(196, 196, 196);
    position: relative;

}


.icon-feature-right-3 {
    width: 100%;
    border-radius: 25px;
    padding-top: 10px;
    margin-right: 25px;
}

.icon_feature_10 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97%;
    position: relative;
    padding: 0 10px 20px 10px;
    border-radius: 25px;
    overflow: hidden;
    transition: 0.5s ease-out;
}

.icon-feature-right-3:hover {
    cursor: pointer;
}

.icon-feature-right-20 {
    width: 100%;
    border-radius: 25px;
    position: relative;
}

.icon-feature-right-5 {
    display: flex;
    align-items: center;
    margin-left: -20px;    
}

.icon-feature-right-9 {
    display: flex;
    align-items: center;
    margin-left: -20px;  
    max-width: 130px;
    overflow: hidden;
}

.icon-button-text {
    margin-left: 0px;
    text-align: left;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
    width: 100%;
}

.icon-button-text-2 {
    width: 75%;
    margin-left: 20px;
    min-height: auto;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-right: 5%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.icon-button-text-3 {
    width: 100%;
    margin-left: 0px;
    text-align: center;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-button-text-4 {
    margin-left: 0px;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-button-text-beginning {
    width: 50%;
}

.icon-button-imprint-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.imprint-bottom-components {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 50%;
}



.icon-feature-right-3 > div > h4 {
    font-family: 'Jost';
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 20px;
}


.icon-feature-right-20 > div > h4 {
    font-family: 'Jost';
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
}

.toggle_button_faq {
    position: absolute;
    right: 10px; /* Oder einen anderen Wert, je nach Bedarf */
    top: 25px;
    background: transparent;
    border: none;
    cursor: pointer;
}

.toggle_button_faq > img {
    width: 30px;
    transition: transform 0.3s ease;
    margin-right: -30px;
}

.faq_text {
    transition: max-height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
}

.faq_text.expanded {
    max-height: 500px; /* Maximalhöhe anpassen */
    opacity: 1;
}

.icon-feature-right-2 > div > h4 {
    font-family: 'Jost';
    margin-top: 20px;
}

.icon-feature-right-2 > div > h5 {
    font-family: 'Jost';
    margin-top: 20px;
    padding: 7px 20px 7px 20px;
    width: fit-content;
    border-radius: 10px;
    letter-spacing: 1px;
}


.icon-feature-right-2 > div > p {
    font-family: 'Jost';
    line-height: 140%;
    letter-spacing: 1px;
}

.icon-feature-right-3 > div > p {
    font-family: 'Jost';
    margin-top: 5px;
    line-height: 140%;
    letter-spacing: 1px;
}


.icon-feature-right-20 > div > p {
    font-family: 'Jost';
    margin-top: 10px;
    line-height: 140%;
    letter-spacing: 1px;
}

.icon-feature-right {
    width: 35%;
    display: flex;
    align-items: center;
}

.icon-feature-right-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.icon-feature-right-5 {
    display: flex;
    align-items: center;
}




.icon_feature_img {
    object-fit: contain;
} 
.icon_feature_img_5{
    object-fit: contain;
} 

.icon_feature_img_7{
    object-fit: cover;
    height: 120px !important;
    width: 120px !important;
    border-radius: 20px !important;
} 

.icon_feature_img_12{
    object-fit: contain;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 170px;
} 

.icon_feature_img_6 {
    position: absolute;
    object-fit: cover;
    height: 30px !important;
    border-radius: 100px !important;
    margin-left: 0px;
    right: 20px;
    top: 44px;
    opacity: 0.8;
}

.icon_feature_img_13 {
    position: absolute;
    object-fit: cover;
    height: 30px !important;
    border-radius: 100px !important;
    margin-left: 0px;
    right: 20px;
    opacity: 0.8;
}

.icon_feature_img_11 {
    position: absolute;
    object-fit: cover;
    height: 30px !important;
    border-radius: 100px !important;
    margin-left: 0px;
    right: 20px;
    top: 58px;
    opacity: 0.8;
}

.icon_feature_img_2 {
    object-fit: contain;
    opacity: 0.1;
} 
.icon_feature_img_3{
    object-fit: contain;
    opacity: 0.1;
} 


.icon_border_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    border-radius: 100px !important;
    border: 1px solid rgba(255, 255, 255, 0);
    margin-top: 15px;
}

.icon-stopper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden !important;

}

.icon_border_3 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 170px;
    object-fit: cover;
    border: 1px solid rgba(255, 255, 255, 0);
}

.icon_border_7 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 170px;
    min-width: 100px;
    object-fit: cover;
    border: 1px solid rgba(255, 255, 255, 0);
}


.icon_border_7 > img {
      object-fit: cover;
}

.icon_border_6 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 90px;
    max-width: 90px;
    min-width: 90px;
    border-radius: 100px;
    object-fit: cover;
    border: 1px solid rgba(255, 255, 255, 0);
}

.icon_border_4 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    border-radius: 100px !important;
    border: 1px solid rgba(255, 255, 255, 0);
    margin-top: 15px;
    position: absolute;
    right: 20px;
    
}



.icon_feature > div > h2 {
    font-size: 28px;
    margin-top: 20px;
}



.icon_feature > div > p {
    font-size: 20px;
    margin-top: 10px;
    text-align: left;
    margin-right: 5px;
    letter-spacing: 1px;
    line-height: 140%;
}


.icon-buttontext-inhalt > h5 {
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
}

.icon-buttontext-inhalt {
    display: flex;
    align-items: left;
    text-align: left;
    justify-content: center;
    flex-direction: column;
    min-height: 160px;
    width: 100%;
}