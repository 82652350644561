.email-templates-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.save-button {
    background-color: var(--blue);
    color: white;
    height: 42px;
}


.email-templates-header {
    background-color: rgb(248, 248, 248);
    height:70px;
    width: 100vw;
    position: sticky;
    top: 60px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.email-templates-header > h2 {
    margin-left: 30px;
    font-family: 'Jost';
    color: #2c3e50;
    font-size: 18px;
    font-weight: 500;
}

.option_box_wrapper > .option-box:nth-child(1) {
    background-image: url('../img/bonusclub_mail__1.webp'); /* Ersetzen Sie 'path/to/your/image.jpg' durch den Pfad zu Ihrem lokalen Bild */
    background-size: cover; /* Damit wird das Bild skaliert, um den Container zu füllen */
    background-position: center; /* Zentriert das Bild */
}

.option-box:nth-child(2) {
    background-image: url('../img/bonusclub_mail__2.webp'); /* Ersetzen Sie 'path/to/your/image.jpg' durch den Pfad zu Ihrem lokalen Bild */
    background-size: cover; /* Damit wird das Bild skaliert, um den Container zu füllen */
    background-position: center; /* Zentriert das Bild */
}

.option-box:nth-child(3) {
    background-image: url('../img/bonusclub_mail__3.webp'); /* Ersetzen Sie 'path/to/your/image.jpg' durch den Pfad zu Ihrem lokalen Bild */
    background-size: cover; /* Damit wird das Bild skaliert, um den Container zu füllen */
    background-position: center; /* Zentriert das Bild */
}

.option-box:nth-child(3) > .option-cta {
    display: none;
}

.option-box:nth-child(2) > .option-cta {
    display: none;
}

.option_box_wrapper > .option-box:nth-child(1) > .option-cta {
    display: none;
}

.option-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.option-cta > p {
    text-align: center;
    margin: 20px 10px;
    color: rgb(87, 87, 87);
}

.option-cta > h3 {
    font-family: 'Jost';
    color: var(--blue);
}
.option-cta > a {
    text-decoration: none;
    color: var(--blue);
    background-color: white;
    padding: 5px 15px; 
    border-radius: 100px;
    font-weight: 500;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}


.option-box-text {
    border: 1px solid #000; /* Ändern Sie die Randfarbe und -stärke nach Bedarf */
    padding: 5px 10px; /* Ein wenig Padding um den Text */
    position: absolute;
    bottom: -40px;
    border-radius: 100px;
    font-size: 15px;
    display: none;
}

.option-box-text.selected {
    background-color: var(--blue);
    border: none;
    color: white;
    display: block;
}

.option-box:nth-child(4) {
    background-color: white;
}


.email-templates-header > button {
    margin-right: 3vw;
}

.option-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 19vw;
    max-width: 290px;
    height: 450px;
    margin: 10px;
    cursor: pointer;
    border-radius: 15px;
    position: relative;
}

.option_box_wrapper {
    display: flex;
    margin-top: 140px;
}

.option-box.selected {
    border: 3px solid var(--blue);
}

.custom-input-section {
    margin-top: -65px;
    bottom: 0px;
    z-index: 5;
}

.custom-input-section > input {
    width: 17vw;
    max-width: 270px;
   border: none;
   border-radius: 100px;
   height: 40px;
   padding-left: 30px;
}

.success-message {
    position: absolute;
    margin-top: 30px;
    right: 12%;
    margin-top: 10px;
    color: rgb(82, 82, 82);
}

.error-message {
    position: absolute;
    margin-top: 30px;
    right: 12%;
    margin-top: 10px;
    color: rgb(82, 82, 82);
}

.custom-box-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}


@media (max-width: 1250px) {
    .success-message {
        position: absolute;
        margin-top: 30px;
        right: 25%;
        margin-top: 10px;
        color: rgb(82, 82, 82);
    }
    
    .error-message {
        position: absolute;
        margin-top: 30px;
        right: 25%;
        margin-top: 10px;
        color: rgb(82, 82, 82);
    }

    .option-box {
       width: 19vw;
        max-width: 290px;
        height: 350px;
        margin: 10px;
        cursor: pointer;
        border-radius: 15px;
        position: relative;
    }
}

