.orb-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}



.orb_overlay {
  width: 100%;
  max-width: 1140px;
  max-height: 640px;
  padding: 8rem 6rem;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.375);
  box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.orb_overlay__inner {
  max-width: 36rem;
}

.orb_overlay__title {
  font-size: 1.875rem;
  line-height: 2.75rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  margin-bottom: 2rem;
}



.orb_overlay__description {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 3rem;
}

.orb_overlay__btns {
  width: 100%;
  max-width: 30rem;
  display: flex;
}

.orb_overlay__btn {
  width: 50%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--light-color);
  background: var(--dark-color);
  border: none;
  border-radius: 0.5rem;
  transition: transform 150ms ease;
  outline-color: hsl(var(--hue), 95%, 50%);
}

.orb_overlay__btn:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.orb_overlay__btn--transparent {
  background: transparent;
  color: var(--dark-color);
  border: 2px solid var(--dark-color);
  border-width: 2px;
  margin-right: 0.75rem;
}

.orb_overlay__btn-emoji {
  margin-left: 0.375rem;
}



@media (prefers-contrast: high) {
  .orb-canvas {
    display: none;
  }
}

@media only screen and (max-width: 1140px) {
  .orb_overlay {
    padding: 8rem 4rem;
  }
}

@media only screen and (max-width: 840px) {

  .orb_overlay {
    padding: 4rem;
    height: auto;
  }

  .orb_overlay__title {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  .orb_overlay__description {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 2.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .orb_overlay {
    padding: 1.5rem;
  }

  .orb_overlay__btns {
    flex-wrap: wrap;
  }

  .orb_overlay__btn {
    width: 100%;
    font-size: 0.75rem;
    margin-right: 0;
  }

  .orb_overlay__btn:first-child {
    margin-bottom: 1rem;
  }
}
