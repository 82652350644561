.end_widget {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100% !important;
    position: relative;
    padding: 0 0% 10px 0%;
    border-radius: 25px;
    overflow: hidden;
}

.end_widget_top {
    margin-top: 0px;
    margin-bottom: 30px;
    text-align: center;
}

.end_widget_contact,
.end_widget_contact_2 {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
    overflow: hidden;
}

.end_widget_contact > p,
.end_widget_contact_2 > p {
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 40px); /* Ensures the text does not exceed the container */
    flex-grow: 1;
}

.end_widget_logo_img {
    display: flex;
    height: 30px;
    margin-left: 10px;
}
