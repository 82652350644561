.buttonNav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    border-radius: 100px;
    margin-left: 10px;
    background-color: var(--blue);
    margin-right: 10px;
    box-shadow: rgba(100, 100, 111, 0.089) 0px 7px 29px 0px;

}

.buttonNav > img {
    width: 15px;
    filter: invert(1);

}