

.layouts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: center; /* Zentriert die Items horizontal */
    align-items: flex-start; /* Beginnt die Elemente am Anfang der Flex-Containerzeile */
    width: 100%; /* Stellen Sie sicher, dass die Breite definiert ist */
    z-index: 10 !important;
  }

.layout-component {
    display: flex;
    flex-direction: column; /* Richtet Kinder vertikal aus */
    align-items: center; /* Zentriert Kinder horizontal */
    justify-content: center; /* Zentriert Kinder vertikal, wenn es genug Platz gibt */
    width: 100%; /* Nimmt die volle Breite */
    min-height: 100vh; /* Mindestens die volle Höhe des Viewports, kann angepasst werden */
    padding: 20px; /* Fügt etwas Abstand rundherum hinzu */
  }

.pseudo_input::placeholder {
  color: rgb(185, 185, 185);
}

.framey {
    width: 400px !important;
    height: 690px !important;
    border: 1px solid white; /* Entfernt den Standardrahmen des Iframes */
    border-radius: 25px;
    margin: 10px;
    -ms-overflow-style: none;  /* Für Internet Explorer und Edge */
    scrollbar-width: none; /* Für Firefox */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;  
}

  .layout_top_text {
      margin-top: 120px;
      text-align: center;
      z-index: 10;
      font-size: 42px;
      font-family: 'Jost';
  }

  .framey::-webkit-scrollbar {
    display: none; /* Für Chrome, Safari und Opera */
  }

  body::-webkit-scrollbar {
    display: none; /* Für Webkit-Browser wie Chrome und Safari */
  }

  .framey-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      height: 50px;
  }

  .framey_button {
    display: flex;
    margin: 0px;
    padding: 6px 10px;


  }

  .framey_button > img {
    width: 20px;
    margin-left: 5px;
    margin-right: 0px;
  }

  #left {
      margin-left: 10px !important;
  }

  .framey-component {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }


