.contact-stats {
    display: flex;
    margin-left: 100px;
}

.contact-stats > p {
    padding: 2px 15px;
    background-color: rgb(238, 238, 238);
    color: black;
    border-radius: 30px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

}

.contact-stats > p > span {
    font-size: 16px;
    font-weight: 500;
    margin: 0 -8px 0 10px;
    padding: 5px;
    color: var(--blue);
    background-color: rgb(255, 255, 255);
    border-radius: 100px;
    min-width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}