.wizard-magic-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.wizard-magic-loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #09f;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: wizard-magic-spin 1s linear infinite;
}

@keyframes wizard-magic-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
