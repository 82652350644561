.top-widget-edit-container {
    width: 24vw;
    margin: 20px auto;
    border-radius: 10px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    position: relative;
}

.input-group {
    margin-bottom: 20px;
}

.input-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333;
    font-size: 14px;
}

.input-group input[type="text"],
.input-group input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
}

.image-preview {
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    padding: 10px;
    text-align: center;
}

.image-preview img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.widget_upload {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    width: 100%;
    height: 220px;
    border-radius: 10px;
    position: relative;
    text-align: left;
}

.widget_upload > label {
    text-transform: uppercase;
    font-size: 12px;
    color: grey;
    font-family: 'Jost';
}

.input-group > label {
    text-transform: uppercase;
    font-size: 12px;
    color: grey;
    font-family: 'Jost';
    font-weight: 400;
    font-size: 12px;
}

#alt-label {
    font-size: 10px;
}

.upload-image-preview > span {
    text-align: center;
}

.save-button {
    background-color: var(--blue);
    color: white;
    padding: 12px 35px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    transition: background-color 0.3s ease;
    position: fixed;
    top: 10px;
    z-index: 10000;
    right: 10px;
    height: 45px;
}

#double_color_picker {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

#double_color_picker > div > label {
    text-align: center;
}

.color-picker-icon {
    width: 30px;
    position: absolute;
    top: 33%;
    right: 33%;
    z-index: 100;
    opacity: 0.3;
    pointer-events: none;
}

.widgetInputField {
    height: 50px;
    border-radius: 8px !important;
    padding-left: 20px !important;
    font-size: 15px !important;
    font-weight: 500;
}

.widgetDescriptionField {
    height: 150px;
    border-radius: 12px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 10px !important;
    font-size: 14px !important;
    font-weight: 300;
    width: 100%;
    border: 1px solid rgb(219, 219, 219) !important;

}


.save-button:hover {
    background-color: #0056b3;
}


.input-container {
    display: grid;
    text-align: center;
    padding: 20px 20px 0px 20px;
    border-radius: 15px;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    margin-bottom: 10px;
    margin-top: 10px;
    gap: 18px; /* Abstand zwischen den Grid-Elementen */
    margin-bottom: 20px; /* Abstand nach unten für den gesamten Container */
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    position: relative
  }
  
  .input-custom-component {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 28px;
      height: 90px;
      padding-left: 0px;
      padding-right: 10px;
      background-color: rgb(255, 255, 255);
      border-radius: 15px;
      border: 1px solid rgb(218, 218, 218);
      position: relative;
  }

  .label-icon {
      position: absolute;
      width: 20px;
      right: 15px;
      margin-top: 40px;
  }

  .hrrrrr {
      width: 1px;
      height: 35px;
      border: none;
      background-color: rgb(218, 218, 218);
      margin-right: 2px;
  }

  .hrrrr {
      border: none; 
      height: 1px !important;
      width: 100%;
      background-color: rgb(197, 197, 197);
      margin-top: 10px;
  }

  #text1Size {
      font-weight: 600;
      font-size: 16px;
  }

  #text2Size {
    font-weight: 600;
    font-size: 16px;
}
.widget-trenner  > h3{
    font-family: 'Jost';
    font-weight: 400;
    color: rgb(163, 163, 163);
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.picker-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Zentriert die Kinder (IconPicker und Button) horizontal */
    gap: 10px; /* Fügt Abstand zwischen dem IconPicker und dem Button hinzu */
    position: relative; /* Setzt den Kontext für absolute Positionierung der Kinder */

}
  
  .confirm-button {
    position: absolute;
    bottom: 20px; /* Positioniert den Button 20px unter dem Container */
    z-index: 1000;
    background-color: var(--blue);
    color: white;
    bottom: 10px;
    right: 10px;
  }

  .input-custom-component > div > input {
      text-align: center;
  }

  .input-custom-component > h3 {
      position: absolute;
      top: -25px;
      font-size: 12px;
      margin-left: 10px;
      font-weight: 400;
      color: grey;
      text-transform: uppercase;
      font-family: 'Jost';
  }

  .text-component-1 {
      border: none !important;
      width: 65px !important;
  }

  .text-component-2 {
    border: none !important;
    width: 50px !important;
    margin-left: 0px;
    font-size: 16px !important;
}


.text-align-buttons {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

.text-align-buttons button {
    padding: 5px 12px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
}

.text-align-buttons button.active {
    background-color: var(--blue);
    color: #fff;
}



