/* IhrCssDateiname.css */

.bonus-ideas-container {
    /* Stil für den äußeren Container */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
    z-index: 10; 
    background-color: transparent !important;
  }

  .filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; /* Abstand zwischen den Filtern und der Liste */
    border-radius: 4px; /* Abgerundete Ecken */
    height: 160px;
    z-index: 100;
  }
  
  
  .filter-section {
    /* Stil für die Filter-Container */
    display: flex;
    flex-wrap: wrap; /* Lässt die Buttons in die nächste Zeile umfließen, wenn der Platz nicht ausreicht */
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin: 0px 100px;
  }

  .filter-section:nth-child(1) {
      display: none;
  }


  .filter-section:nth-child(2) {
    display: none;
}
  
  .filter-label {
    /* Stil für die Filter-Checkbox-Labels */
    margin-right: 10px;
  }

  #top-filter-2 {
      margin-top: 0px;
  }

  #top-filter {
      margin-top: 10px;
  }

  #top-filter > button {
      font-size: 14px;
      padding: 10px 20px;
  }
  
  .ideas-list {
    /* Stil für die Ideen-Liste */
    list-style: none;
    padding: 0;
    margin: 40px 0 0 0;
  }
  
  .idea-item {
    /* Stil für jedes Ideen-Element in der Liste */
    padding: 30px 50px;
    margin: 30px 0;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 550px;
    height: 200px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }

  .idea-item-cat {
      display: flex;
  }

  .idea-item-cat > p {
      display: flex;
      flex-direction: column;
      margin: 0 20px;
      padding: 3px 7px;
      font-size: 10px !important;
      margin-top: 15px;
  }

   
  .idea-item h2 {
    /* Stil für die Titel */
    font-size: 24px;
    margin: 0;
    font-family: 'Jost';
    text-align: center;
    color: var(--blue);
  }
  
  .idea-item > p {
    /* Stil für die Beschreibungen */
    font-size: 16px;
    margin-top: 5px;
    max-width: 500px;
    margin-top: 20px;
    text-align: center;
  }
  
  .idea-item small {
    /* Stil für die darüberliegenden Werte */
    font-size: 12px;
    color: #888;
  }
  .filter-button {
    padding: 8px 12px;
    margin: 5px;
    border: 1px solid var(--blue) !important;
    background-color: #ffffff !important;
    color: var(--blue) !important;
    border-radius: 100px !important;
    cursor: pointer;
    transition: background-color 0.3s;
    outline: none;
    font-size: 10px;
}

.filter-button:hover {
    background-color: #e0e0e0;
}

.filter-button.active {
    background-color: var(--blue) !important;
    color: #ffffff !important;
    border-color: var(--blue) !important;
}
