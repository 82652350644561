.fontSelector_1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 Spalten */
    gap: 10px; /* Abstand zwischen den Elementen */
    justify-content: center; /* Zentriert die Schriftartenauswahl horizontal */
    max-width: 100%; /* Verhindert, dass die Schriftartenauswahl zu breit wird */
}

.font-option {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
}



/* ... Ihre bestehenden Schriftart-Styles ... */
.modal-background-big {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Stellen Sie sicher, dass das Modal über anderen Inhalten liegt */
    overflow-y: auto;
}


.modal-content-big > h2 {
    font-family: 'Jost';
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left; 
    margin-left: 20px;
    font-size: 24px;
    font-weight: 400;
    
}


.modal-content-big {
    background: #f7f7f7f6;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 1001; /* Modal soll über dem modal-backdrop liegen */
    /* Weiterer Stil für Ihr Modal */
    text-align: center;
    width: 93%;
    height: 93%;
    border-radius: 25px;
    max-width: 1400px;
    max-height: 1000px;
    overflow: hidden;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.modal-content-inner {
    max-height: 90%; /* Maximalhöhe für den scrollbaren Bereich */
    overflow-y: auto; /* Fügt Scrollbar hinzu, wenn nötig */
    width: 100%;
    display: flex;
    justify-content: top;
}

.modal-content-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40%;
    min-height: 100%;
    min-width: 400px;
    overflow-y: auto; /* Ermöglicht Scrollen, falls der Inhalt länger als der Container ist */

}


.modal-content-right {
    width: 60%;
    min-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1; /* Stellt sicher, dass beide Seiten flexibel sind, aber den verfügbaren Platz ausfüllen */
    overflow-y: auto; /* Ermöglicht Scrollen, falls der Inhalt länger als der Container ist */
    padding: 20px; 
}

.settings-group {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.settings-group > input {
    height: 50px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    padding-left: 15px;
    font-family: 'Jost';
    letter-spacing: 1px;
}

.settings-group > h3 {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 25px;
    font-family: 'Jost';
}

.settings-group > div > input {
    height: 50px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    padding-left: 0px;
    font-size: 15px;
    font-family: 'Jost';
    letter-spacing: 1px;
    font-weight: 500;
    font-family: 'Jost';
}


.settings-group > textarea {
    height: 80px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    padding-left: 15px;
    font-family: 'Jost';
    letter-spacing: 1px;
    padding-top: 10px;
}

.settings-group > label {
    text-align: left;
    font-size: 14px;
    font-family: 'Jost';
    font-weight: 400 !important;
    letter-spacing: 1px;
    margin-top: 10px;
}
 
.modal-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
}

modal-content-big input[type="text"],
.modal-content-big textarea,
.modal-content-big label {
    display: block;
    margin-bottom: 10px;
    /* Weitere Stile für Input-Felder und Label */
}

.modal-content-big label {
    font-weight: bold;
    /* Weitere Stile für Label */
}



/* Stile für die Schriftartenauswahl */
.fontSelector_1 {
    /* Stile für die Schriftartenauswahl */
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* Weitere Stile für die Schriftartenauswahl */
}

.modal-content-right::-webkit-scrollbar {
    display: none; /* Für Webkit-Browser */
}

.modal-content-left::-webkit-scrollbar {
    display: none; /* Für Webkit-Browser */
}


.font-option {
    /* Stile für einzelne Schriftartenoptionen */
    cursor: pointer;
    /* Weitere Stile für Schriftartenoptionen */
}



#save-button {
    background-color: var(--blue) !important;
    border: 1px solid var(--blue);
    color: white;
}
/* Stile für die Buttons */

.modal-buttons button {
    margin-left: 10px;
    border: 1px solid rgb(131, 131, 131);
    /* Weitere Stile für die Buttons */
}

.custom-domain-input {
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: rgb(255, 255, 255);
    padding-top: 10px;
    border-radius: 10px;
    height: 50px;
}


.static-text {
    display: flex;
    align-items: center;
    padding: 0 5px; /* Abstand zwischen dem statischen Text und dem Textfeld */
    font-weight: bold; /* Falls gewünscht, können Sie die Schriftart anpassen */
    height: 40px;
    margin-top: -10px;
    margin-right: -4px;
    font-size: 14px;
    font-family: 'Jost';
    font-weight: 500;
    padding-left: 20px;
    color: gray;
}
.settings-fonts {
    /* Stile für den gesamten Abschnitt der Schriftartenauswahl */
    margin-top: 10px;
    display: flex;
    align-items: center;
}

h3 {
    /* Stile für die Überschrift (Fonts) */
    font-size: 18px;
}


.select-font-text {
    display: flex;
    align-items: left;
  width: 520px;
  margin-top: 40px;
  font-size: 18px !important;
  font-weight: 600;
}

.font-grid {
    /* Stile für das Grid der Schriftarten */
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 Spalten pro Zeile */
    gap: 20px; /* Abstand zwischen den Elementen */
}

.font-option {
    /* Stile für einzelne Schriftartenoptionen */
    padding: 15px 30px;
    border: 1px solid rgb(255, 255, 255);
    text-align: center;
    cursor: pointer;
    font-size: 24px;
}


/* Weitere Stile für Schriftarten, falls erforderlich */
