.uploader-master {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    animation: fade-in 1s; 
}

.progress-bar {
    margin-top: 80px;
    margin-bottom: -60px;
    background-color: #e0e0e0;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.progress {
    height: 100%;
    background-color: #4caf50;
    width: 0;
    transition: width 0.3s;
}


.uploader-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 800px;
    min-height: 350px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 100px;
}



.upload-status {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
}

.file-drop-area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 750px;  /* or whatever width you like */
    height: 340px; /* or whatever height you like */
    border: 3px dashed rgb(218, 218, 218);
    border-radius: 25px;
    margin-bottom: 30px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 200ms ease-out;
    background-color: rgb(240, 240, 240);
    position: relative;
}

.upload-more-text {
    color: var(--blue) !important; /* Blau */
    cursor: pointer;
    margin-top: 60px;
    margin-bottom: -68px;
    font-size: 16px !important;
    animation: fade-in 1s; 
    opacity: 1;
    font-weight: 500 !important;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pulsate {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.01);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.5;
    }
  }
  
  .pulsating-text {
    animation: pulsate 1.5s infinite;
    color: var(--blue);
    font-weight: 600;
    font-size: 18px;
    margin: 0 30px;
    margin-top: -60px;
  }

.file-drop-area:hover {
    background-color: rgb(226, 242, 247);
}

.file-drop-area > img {
    width: 90px;
    margin-bottom: 0px;
    pointer-events: none;
}

.file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.custom-file-input {
    pointer-events: none;
    width: 400px;  /* ensures that mouse events pass to the file input */
    text-align: center;
}

.custom-file-input > p {
    color: rgb(156, 156, 156);
    font-size: 20px;
    font-weight: 600;
    margin-top: 60px;
    margin-bottom: -55px;
}

.files-list {
    margin-bottom: 20px;
}

.file-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 720px;
    padding: 0 15px;
    border-top: 1px solid rgb(219, 219, 219)
}



.file-name {
    flex: 1;
    font-size: 14px;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.file-preview {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
    border-radius: 100px;
    margin: 10px 10px 0px 10px;
}

.delete-btn {
    background-color: #e74c3c;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 100px;
    cursor: pointer;
}

.delete-btn:hover {
    background-color: #c0392b;
}

.upload-btn {
    background-color: #2ecc71;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
}

.upload-btn:hover {
    background-color: #27ae60;
}

.custom-file-input {
    font-size: 18px;
    font-weight: 200;
}


.upload-status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
}

.uploader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.upload-status > p {
    margin-bottom: -20px;
    font-size: 20px;
    margin-left: 40px;
    margin-right: 40px;
    font-family: 'Jost';
}

    .loading-quote {
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    margin: 0 10%;
    font-size: 20px;
    width: 80vw;
    min-height: 300px;
    margin-top: 50px;
    border-radius: 20px;
    color: rgb(32, 32, 32);
    font-family: 'Jost';
    animation: fade-in 2s;
    background-color: white !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    text-align: center;
}

.loading-quote > h3 {
    margin-bottom: 30px;
    font-family: 'Jost';
}

.loading-quote > img {
    width: 50px;
    margin-bottom: 10px;
    margin-top: 45px;
}

.loading-quote > p {
    font-weight: 400;
    margin: 0 40px;
   min-height: 80px;
   font-size: 18px;
}




.lottie_animation {
    width: 100%;
    margin-top: -40px;
}

@media (max-width: 800px) {
    
    .custom-file-input {
        font-size: 16px;
        font-weight: 200;
    }

    .progress-container-uploading {
        width: 90vw;
    }
    
    
    .file-drop-area {
        width: 80vw; 
        height: 350px; /* or whatever width you like */
        margin-top: 0px;
    }

.uploader-container {
    box-shadow: none;
    width: 100vw;
    margin-top: 30px;
}
.file-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 80vw;
    padding: 0 15px;
    border-top: 1px solid rgb(219, 219, 219)
}

    
    .custom-file-input {
        font-size: 16px;
        font-weight: 200;
    }
    
    .file-drop-area {
        width: 85vw; 
        height: 350px; /* or whatever width you like */
        margin-top: -20px;
    }

.uploader-container {
    box-shadow: none;
    width: 100vw;
}


    

}

@media (max-width: 500px) {
    .progress-bar {
        margin-left: 5%;
        width: 360px !important;
    }
}

@media (max-width: 430px) {
    .progress-bar {
        margin-left: 13%;
        width: 290px !important;
    }
}