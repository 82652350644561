.bonus_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 97%;
    position: relative;
    padding: 0 20px 10px 20px;
    border-radius: 25px;
    margin-bottom: 20px;
}

.top_bonus_banner {
    background-color: rgb(255, 255, 255);
    padding: 5px 15px;
    border-radius: 100px;
    top: -20px;
    position: absolute;
    z-index: 100;
    font-size: 16px;
    letter-spacing: 2px;

}


.bonus_1_logo_img {
    max-width: 500px;
    margin-top: 60px;
    margin-bottom: 60px;
    border-radius: 20px;
}

.bonus_1 > h2 {
    font-size: 34px;
    text-align: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.bonus_1 > p {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    letter-spacing: 1px !important; 
}

.bonus_1 > h5 {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    letter-spacing: 1px !important; 
}





.icon_feature_10 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;
        position: relative;
        padding: 0 20px 0px 20px;
        border-radius: 100px;
        position: relative;
}

#button_h5_text_2 {
    font-size: 20px !important;
    width: 100%;
    text-align: center;
}

.icon_feature_img_10 {
    height: 30px;
    width: 30px;
}


