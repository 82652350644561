.top_widget {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
}

.style-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 12px; /* Abstand zwischen den Boxen */
    margin-bottom: 20px;
  }
  
  .style-option {
    width: 65px;
    height: 50px;
    padding: 5px; /* Abstand zwischen Inhalt und Rand der Box */
    display: flex;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(255, 255, 255); /* 1px Rand in light gray */
    border-radius: 10px; /* Gerundete Ecken */
    cursor: pointer;
    color: grey;
    letter-spacing: 0.5px;

  }
  
  .style-option img {
    width: 28px; /* Breite des Icons */
    height: auto;
    margin-bottom: 7px;
    margin-top: 7px;
    opacity: 0.4;
  }



  .top-widget-edit-container > label {
      font-size: 12px;
      text-transform: uppercase;
      color: rgb(105, 105, 105);
      font-family: 'Jost';
  }

  .style-option.active img {
    opacity: 1; /* Volle Opazität für Icons innerhalb einer aktiven Option */
  }
  
  .style-option.active {
    border-color: var(--blue); /* Blau oder eine andere Farbe für den aktiven Zustand */
    color: black;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Box-Shadow */

}
  

/* WidgetStyle.css */
.background_shape_style.style1 {
    border-radius: 0px;
  }
  
  .background_shape_style.style4 {
    border-radius: 25px;
    margin: 2%;
  }
  
  .background_shape_style.style3 {
    border-radius: 10px;
    margin: 2%;
  }
  
  .background_shape_style.style2 {
    border-radius: 0px;
    margin: 2%;
  }
  
  .background_shape_style.style5 {
    margin: 2%;
    border-radius: 50px;
}
  


.top_widget_banner_img {
    width: 100%;
    max-width: 750px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    max-height: 320px;
    object-fit: cover;
} 

.top_widget_banner_img_2 {
    max-width: 600px;
    min-width: 100px;
    margin-bottom: 40px;
    border-radius: 25px;
    width: 90%;
}


.top_widget_banner_img.style4 {
    width: 100%;
    border-radius: 0;
  }
  

  .top_widget_banner_img.style1 {
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    width: 100%;
  }
  
  .top_widget_banner_img.style2{
    width: 95%;
    border-radius: 25px;
    margin-top: 10px;
    
}
  
  .top_widget_banner_img.style3 {
    width: 95%;
    border-radius: 0px;
    margin-top: 10px;
    border-radius: 10px;
  }

  .top_widget_banner_img.style5 {
    width: 100%;
    height: 200px;
    border-radius: 0px;
  }
  
.top_widget_banner_img_2.style1 {
    max-height: 200px;
    max-width: 100%;
    border-radius: 0px !important;
    object-fit: cover;
}

.top_widget_banner_img_2.style2 {
    max-width: 100%;
    max-height: 350px;
    border-radius: 0px !important;
    object-fit: cover;
}


.top_widget_banner_img_2.style3 {
    max-width: 100%;
    border-radius: 10px;
    object-fit: cover;
}


.top_widget_banner_img_3 {
    width: 100%;
    max-width: 100%;
    max-height: 320px;
    object-fit: cover;
} 

.top_widget_logo_img {
    margin-top: 40px;
    margin-bottom: 40px;
    object-fit: contain;
}

.top_widget > h2 {
    font-size: 42px;
    text-align: center;
    margin-bottom: 15px;
    margin-left: 12px;
    margin-right: 12px;
}

.top_widget > h5 {
    font-size: 42px;
    text-align: center;
    margin-bottom: 15px;
}

.top_widget > p {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
    line-height: 140%;
    letter-spacing: 1px;
    margin: 0 15px;

}

