/* StepIndicator.css */

.step-indicator {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 40%;
    margin-left: 30%;
    margin-top: -10px;
}

.step-indicator__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex-shrink: 0;
    font-family: 'Jost';
    font-weight: 500;
}

.step-indicator__step .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ccc;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.step-indicator__step .label {
    font-size: 12px;
    color: #333;
}

.step-indicator__step.active .circle {
    background-color: var(--blue);
}

.step-indicator__line {
    height: 1px;
    flex-grow: 1;
    background-color: #ccc;
    margin: 0 10px;
}

.step-indicator__line.active {
    background-color: var(--blue);
}
