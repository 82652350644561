.widget_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    margin-top: 80px;
    height: 100%;
    margin-left: 85px;

}

.add-widgets-layout {
    display: flex;
    justify-content: space-between;
}

.h2_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: grey !important;
    color: rgb(0, 0, 0);
}

.h2_container > img {
    opacity: 0.3;
}


.widget-cat-icons {
    background: none;
    margin-top: 20px;
    margin-bottom: 4px;
    padding: 15px;
    max-width: 58px;
    border-radius: 15px;
    opacity: 0.2;
    
}

.widget-cat-icons:hover {
    opacity: 1;

}

#baba-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    padding: 0;
    margin: 0;
    width: 70px;
}

.selected-category-text {
    color: black !important;
}

.widget-cat-text {
    color: rgb(156, 156, 156);
}


.widget-cat-button {
    background: none;
    width: 100%;
} 


.widget-categories {
    width: 85px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100vh;
    left: 0;
    z-index: 10;
    margin-top: 40px;
    padding-bottom: 90px;
    box-shadow: rgba(17, 12, 16, 0.05) 0px 48px 100px 0px;

    max-height: 100vh;
    overflow-y: auto; /* Beibehalten für Inhalte, die größer als der Viewport sind */
    scrollbar-width: none; /* Scrollbar in Firefox ausblenden */
    -ms-overflow-style: none; /* Scrollbar in IE und Edge ausblenden */
}

/* Versteckt den Scrollbalken in WebKit-Browsern und behält die Funktionalität bei */
.widget-categories::-webkit-scrollbar {
    display: none;
}

.selected-category-icon {
    /* Beispiel: Hinzufügen eines Schattens oder Ändern der Größe, um hervorzuheben */
    filter: drop-shadow(0 0 5px rgb(238, 238, 238));
    background-color: white;
    border: 1px solid rgb(199, 199, 199);
    opacity: 1;


  }

.widget_container > h2 {
    margin-bottom: 30px;
    font-family: 'Jost';
    font-size: 18px;
    font-weight: 400;
}

.widget-preview {
    width: 20vw;
    border-radius: 15px;
    border: 1px solid rgb(240, 240, 240);
}
.widget-button {
    position: relative;
}

.widget-button:hover .widget-overlay {
    opacity: 1;
    border-radius: 15px;
    height: 99%;
}


.widget-overlay-text {
    display: none;
    font-size: 15px;
    color: grey;
}

.widget-button:hover .widget-overlay-text {
    display: block;
    font-size: 15px;
}

.image-container {
    position: relative;
    display: inline-block;
}

.widget-overlay > img {
    width: 28px;
    margin-bottom: 10px;
    opacity: 0.6;
}

.widget-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    border: 1px solid rgb(235, 235, 235);
    background-color: rgba(255, 255, 255, 0.596);
    transition: opacity 0.3s ease;
    backdrop-filter: blur(5px); /* Unschärfe-Effekt hinzufügen */
    border-radius: 15px;
}

.image-container:hover .widget-overlay {
    opacity: 1;
}

.widget-button.clicked {
    animation: pulse 0.3s ease;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}
