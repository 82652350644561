/* AddPageComponent.css */

.input-group-country {
    margin: 20px 0;
}

.input-group-country label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
    font-family: 'Jost';
    text-align: left;
}

.input-group-country select {
    width: 100%;
    padding: 12px 10px;
    font-weight: 500;
    font-size: 15px;
    outline: none !important; 
    border: 1px solid #ccc;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.363);
    transition: border-color 0.3s ease;
}

.input-group-country select:focus {
    outline: none;
}


.input-group-country option {
    padding: 10px;
    background-color: #fff;
    color: #333;
}
