.thank_you_text {
    display: flex;
    align-items: center;
    justify-content: center;
text-align: center;
font-family: 'Jost';
letter-spacing: 0.8px;
font-size: 18px;
}


.thank_you {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 5%;
}

.thank_you > p {
    max-width: 600px;
    margin: 10px;
}

.thank_you > div {
    min-height: 400px;
}

.thank_you > div > img {
    width: 100vw !important;
    height: 300px !important;
    object-fit: cover;
    animation: fade-in 1s;
}

.image_container {
    height: 300px;
    background-color: lightgray;
}

.thank_you > h2 {
    font-size: 42px;
    margin-bottom: 20px;
}

.sent_bird {
    max-width: 450px;
    max-height: 300px !important;
    object-fit: cover;
    border-radius: 15px;
    margin-top: 40px;
    margin-bottom: 10px !important;
}

@media (max-width: 700px) {
    .thank_you > div > img {
        height: 400px !important;
    }
    .sent_bird {
        width: 90%;
        margin-bottom: -20px;
        margin-top: 30px;
    }
.image_container {
  height: 400px;
}
    .thank_you_text {
        text-align: center;
        margin: 20px;
        font-family: 'Jost';
        letter-spacing: 0.8px;
        }
        
}