.fancy_bullet {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 97%;
    position: relative;
    border: 1px dashed rgb(196, 196, 196);
    border-radius: 25px;

}

.inverted {
    filter: invert(1);
}


.fancy_bullet.style1 {
    width: 100%;
    border-radius: 0px;
}

.fancy_bullet.style2 {
    border-radius: 0px;
}

.fancy_bullet.style3 {
    border-radius: 20px;
}

.fancy_bullet.style4 {
    border-radius: 50px;
}

.fancy_bullet.style5 {
    border-radius: 100px;
    width: 100%;
}

.fancy-underline {
    align-self: center;
    margin: 30px 40px 10px 40px;
    border: 0;
    height: 1px;
    background-color: rgb(209, 209, 209);
}

.fancy_bullet_img {
    object-fit: contain;
} 

.icon_border {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    border-radius: 100px !important;
    border: 1px solid;
    overflow: hidden;
}



.fancy_bullet > h2 {
    font-size: 42px;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
}

.fancy_bullet > p {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
    letter-spacing: 1px;
    line-height: 140%;
}