
.auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 7px;
  font-size: 16px;
}
.fade-in {
  opacity: 0;
  transform: translateY(-30px); /* Startposition etwas oberhalb */
  animation: fadeIn ease 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translateY(-30px);
  }
  40% {
    opacity: 0;
    transform: translateY(-30px);
}

  100% {
      opacity: 1;
      transform: translateY(0);
  }
}



.header_component {
  width: 100vw;
  height: 110px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(17, 12, 46, 0.030) 0px 48px 100px 0px;
}


.buttonClassy {
  margin: 15px 10px 10px 10px;
  padding: 20px 0px;
  background-color: var(--blue);
  color: white;
  border: none;
  cursor: pointer;
  transition: ease-in-out 200ms;
  border-radius: 100px;
  justify-content: space-between; /* Verteilt die Buttons gleichmäßig innerhalb des Containers. */
  width: 100%;
  flex-grow: 1;
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Jost';
  letter-spacing: 1px;

}

.buttonClassySolo {
  margin: 15px 10px 10px 10px;
  padding: 20px 0px;
  background-color: var(--blue);
  color: white;
  border: none;
  cursor: pointer;
  transition: ease-in-out 200ms;
  border-radius: 100px;
  justify-content: space-between; /* Verteilt die Buttons gleichmäßig innerhalb des Containers. */
  max-width: 500px;
  flex-grow: 1;
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Jost';
  letter-spacing: 1px;
  z-index: 1000; 
}

.buttonClassy-disabled {
  background-color: #e0e0e0; /* Ein heller Grauton */
  color: #a0a0a0; /* Ein dunklerer Grauton für den Text */
  cursor: not-allowed; /* Zeigt den nicht erlaubten Cursor */
  pointer-events: none; /* Verhindert, dass der Benutzer darauf klicken kann */
  box-shadow: unset !important;
}

.buttonClassySolo-disabled {
  background-color: #e0e0e0; /* Ein heller Grauton */
  color: #a0a0a0; /* Ein dunklerer Grauton für den Text */
  cursor: not-allowed; /* Zeigt den nicht erlaubten Cursor */
  pointer-events: none; /* Verhindert, dass der Benutzer darauf klicken kann */
}



.bottom-buttons {
  bottom: 0;
  width: 700px;
  display: flex;
  justify-content: space-between; /* Verteilt die Buttons gleichmäßig innerhalb des Containers. */
  padding: 0 5%; /* Ein kleiner Seitenabstand, damit die Buttons nicht direkt am Rand sind. */
  margin-bottom: 10px;
  margin-top: 10px;
  z-index: 1000; 
}

.bottom-button-solo {
  bottom: 0;
  display: flex;
  justify-content: center; /* Verteilt die Buttons gleichmäßig innerhalb des Containers. */
  margin-bottom: 10px;
  margin-top: 10px;
  z-index: 1000; 
}


.buttonClassyBack {
  margin: 10px;
  padding: 20px 40px;
  background-color: white;
  border: 1px solid var(--blue);
  color: var(--blue);
  cursor: pointer;
  transition: ease-in-out 200ms;
  border-radius: 100px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Jost';
  letter-spacing: 1px;
  z-index: 1000; 

}


.buttonClassy:hover {
  background-image: linear-gradient(var(--blue), #24242418); /* Von --blue zu einer dunkleren Farbe */
  color: white !important;
}

.surveyHeader {
  font-size: 32px;
  margin-top: 5vh;
  font-family: 'Voyage';
  margin-left: 60px;
  margin-right: 60px;
  text-align: center;
}

.surveyParagraph {
  max-width: 600px;
  text-align: center;
  margin: 20px 40px; 
  color: #6b6b6b;
  font-family: 'Jost';
  letter-spacing: 0.8px;
}

@media (max-width: 1000px) {

  .buttonClassySolo {
    margin: 5px 10px 10px 10px;
    padding: 20px 0px;
    background-color: var(--blue);
    color: white;
    border: none;
    cursor: pointer;
    transition: ease-in-out 200ms;
    border-radius: 100px;
    justify-content: space-between; /* Verteilt die Buttons gleichmäßig innerhalb des Containers. */
    max-width: 90%;
    flex-grow: 1;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Jost';
    letter-spacing: 1px;
  }


.buttonClassySolo-disabled {
  background-color: #e0e0e0; /* Ein heller Grauton */
  color: #a0a0a0; /* Ein dunklerer Grauton für den Text */
  cursor: not-allowed; /* Zeigt den nicht erlaubten Cursor */
  pointer-events: none; /* Verhindert, dass der Benutzer darauf klicken kann */
}
  

  .surveyParagraph {
    max-width: 700px;
    text-align: center;
    margin: 20px 40px; 
  }


  .buttonClassy {
    width: 48% !important;
    height: 80px;
    z-index: 1000; /* Optional, um sicherzustellen, dass der Button über anderen Elementen bleibt. */
    border-radius: 100px;
    flex-grow: 1;
    
}

.bottom-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  width: 100% !important;
  justify-content: space-between; /* Verteilt die Buttons gleichmäßig innerhalb des Containers. */
  padding: 0 3%; /* Ein kleiner Seitenabstand, damit die Buttons nicht direkt am Rand sind. */
  position: fixed;     /* macht den Button fixiert */
  left: 0;             /* positioniert den Button am linken Rand */
  right: 0;            /* positioniert den Button am rechten Rand */
  background-color: #fff;  /* Hintergrundfarbe, um den Inhalt darunter zu verdecken. Sie können dies nach Bedarf ändern */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
  height: 110px;
  z-index: 999;
}

.bottom-button-solo {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center; /* Verteilt die Buttons gleichmäßig innerhalb des Containers. */
  padding: 0 0; /* Ein kleiner Seitenabstand, damit die Buttons nicht direkt am Rand sind. */
  margin-bottom: 10px;
}



.buttonClassyBack {
  width: 48% !important;
  bottom: 0;
  height: 80px;
  z-index: 1000; /* Optional, um sicherzustellen, dass der Button über anderen Elementen bleibt. */
  border-radius: 100px;
  flex-grow: 1;
  
}



}

@media (max-width: 700px) {

  .buttonClassy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72% !important;
    bottom: 0;
    height: 75px;
    z-index: 1000; /* Optional, um sicherzustellen, dass der Button über anderen Elementen bleibt. */
    border-radius: 100px;
    flex-grow: 1;
    box-shadow: rgba(50, 50, 93, 0.10) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;    
}
.buttonClassyBack {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16% !important;
  bottom: 0;
  height: 65px;
  z-index: 1000; /* Optional, um sicherzustellen, dass der Button über anderen Elementen bleibt. */
  border-radius: 100px;
  flex-grow: 1;
  
  
}

.bottom-buttons {
  padding: 0 3%; /* Ein kleiner Seitenabstand, damit die Buttons nicht direkt am Rand sind. */
}

.surveyParagraph {
  max-width: 700px;
  text-align: center;
  margin: 20px 15px; 
}


.buttonClassySolo-disabled {
  background-color: #e0e0e0; /* Ein heller Grauton */
  color: #a0a0a0; /* Ein dunklerer Grauton für den Text */
  cursor: not-allowed; /* Zeigt den nicht erlaubten Cursor */
  pointer-events: none; /* Verhindert, dass der Benutzer darauf klicken kann */
}


}