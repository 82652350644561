/* Dashboard.css */
.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 255, 255);
    color: white;
    max-width: 100vw;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: rgba(99, 99, 99, 0.05) 0px 2px 4px 0px;
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 1000;
    left: 0;
    height: 85px;
}




.dashboard_header_links {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 14px;
    margin-left: 40px;
}

.invisible_icon {
    width: 26px;
    margin-bottom: 5px;

}

.selector {
    color: black;
    font-weight: 600;
    border-bottom: 2px var(--blue) solid;
    padding: 17px 0;
    opacity: 1 !important;
}



.logo-and-menu {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_logo_1 > span {
    
    color: var(--blue);
    font-size: 36px;
    margin-left: 30px;
}

.header_logo_1 > img {
    width: 40px;
    margin-left: 25px;
    
}

.logo {
    width: 160px;
    margin-right: 40px;
    margin-left: 40px;
}

.menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: rgb(59, 59, 59);
    width: 120px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    margin: 0px 15px;
    opacity: 0.5;
}

.no-unread-messages {
    display: none;
}

.unread-notification {
    position: absolute;
    top: -8px;
    right: 3px;
    background-color: rgb(245, 69, 69); /* oder eine andere Farbe zur Hervorhebung */
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
}


.menu-item.selected {
    color: red !important;
}

.dashboard-menu-slice {
    display: flex;
    align-items: center;
    border-radius: 100px;
    margin: 10px;
    border: 1px solid rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
}

.exiting {
    animation: modalExit 0.2s forwards; /* Schnellere Ausblendung */
}


.dashboard-menu-slice:hover {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(199, 199, 199);
    transition: 100ms ease-in-out;
}

.sign_out {
    text-align: center;
    border-top: 1px solid rgb(233, 233, 233);
}

.dashboard-menu-slice > img {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    padding: 4px 8px;
    border-radius: 100px;
    transition: 200ms ease-in-out;
}

.sign-out-key {
    background-color: transparent;
    text-decoration: underline;
}


.profile-section {
    display: flex;
    align-items: center;
}

.profile-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 25px;
    border-radius: 100px;
    width: 75px;
    border: 1px solid rgb(190, 190, 190);
    color: white;
    transition: 100ms ease-in-out;
    z-index: 1000;
    background-color: white;
}

.profile-section-wrapper:hover {
    box-shadow: rgba(149, 157, 165, 0.04) 0px 8px 24px;
    border: 1px solid rgb(146, 146, 146);
    cursor: pointer;
}

.profile-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 0px;
    transition: 100ms ease-in-out;
    margin-right: 5px;
    object-fit: cover;
}

.profile-pic-2 {
    width: 15px;
    height: 35px;
    border-radius: 50%;
    margin-left: 0px;
    transition: 100ms ease-in-out;
    margin-left: 15px;
}

.profile-pic:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-top: 15px;
    padding-top: 30px;
    padding: 0px 0px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 100px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 2px 0px;
}

.modal-switch > button {
    margin: 0 5px;
}



.button-inactive {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0) !important;
    color: rgb(121, 121, 121) !important;
    border-radius: 100px !important;
    font-size: 12px;
    padding: 0 8px !important;

}

.button-active {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 100px !important;
    background-color: rgba(238, 238, 238, 0.26) !important; 
    color: var(--blue) !important;
    font-weight: 500;
    height: 25px;
    padding: 0 8px !important;


} 



.modal-content {
    background-color: rgba(255, 255, 255, 0.897);
    padding: 20px;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.486);
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    display: flex;
    backdrop-filter: blur(20px);
    flex-direction: column;
    align-items: center;
    animation: modalEnter 0.2s forwards; /* Apply the animation */
    width: 470px;
    height: 650px;
    color: var(--blue);
    
}


.modal-content-2 {
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: modalEnter 0.4s forwards; /* Apply the animation */
    color: var(--blue);
}

.modal-link-container {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 25px;
    height: 50px;
    position: relative;
}


.copy-link-img {
    width: 22px;
    height: 28px;
    margin-left: -15px;
}

.modal-link-container > button {
    background-color: transparent !important;
}

.qr-modal-close {
    margin-top: 40px;
    font-size: 15px;
    color: var(--blue) !important;
    background-color: white !important;
    width: 200px;
    height: 54px;
    border-radius: 100px !important;
    margin-right: 10px;
    border: 1px solid var(--blue) !important;
}

.qr-modal-open {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    font-size: 15px;
    color: white;
    background-color: var(--blue);
    width: 200px;
    height: 54px !important;
    border-radius: 100px;
    text-decoration: none;
    transition: ease-in-out 200ms;
}


.qr-modal-open:hover {
    transform: scale(1.02);
}

.qr-buttons {
    display: flex;
    flex-direction: row-reverse;
}




.modal-link-container > input {
    width: 352px;
    border: none !important;
    border-radius: 25px !important;
    margin-bottom: -0px !important;
    margin-left: 10px !important;

}

.modal-link-container-2 > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px !important;
    height: 50px;
    position: absolute;
    right: -250px;
    top: -110px;
}

.copy-link-img-2 {
    width: 22px;
    height: 28px;
}


.modal-qr-code {
    padding: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 15px;
    margin-bottom: 30px;
}

.modal-qr-code-2 {
    padding: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 15px;
    margin-bottom: 30px;
    width: 200px;
    height: 200px;
    background-color: white;
}

.modal-qr-code-3 {
    padding: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 15px;
    margin-bottom: 30px;
    width: 256px;
    height: 256px;
}

.modal-content > h2 {
    font-size: 34px;
    margin-top: 28px;
    margin-bottom: 40px;
    font-weight: 500;
    font-family: 'Jost';
    text-align: center;
}

.modal-qr-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}



@keyframes modalEnter {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* ... Dein vorhandenes CSS ... */

.profile-section {
    position: relative;  /* Stellt sicher, dass das Popup-Menü relativ zum Profilbild positioniert wird */
}

.profile-pic-rotate {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}

.profile-menu-item-2 {
    font-size: 13px;
    margin-top: 5px;
    font-family: 'Jost';
    font-weight: 500;
    margin-left: 20px;

}

.profile-menu-item-3 {
    font-size: 11px;
    margin-top: 1px;
    font-family: 'Jost';
    font-weight: 300;
    margin-left: 20px;

}

.profile-menu-info {
    border-top: 1px solid rgb(221, 221, 221);
    margin-top: 5px;
}


.profile-menu {
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    position: absolute;  /* Das Popup-Menü wird absolut positioniert */
    top: -15px; 
    padding-top: 40px;          /* Direkt unter dem Profilbild */
    right: -10px;            /* Rechtsbündig mit dem Profilbild */
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(219, 219, 219);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 200px;        /* Oder gewünschte Breite */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  /* Ein leichter Schatten für das Popup */
    z-index: -2;   
    margin-right: 20px;
    margin-top: 10px;   
    animation: modalEnter 0.1s forwards; /* Apply the animation */
    backdrop-filter: blur(10px); /* Unschärfeeffekt */
}

.profile-menu > p {
text-align: center;
margin-top: 30px;
margin-bottom: 10px;
font-size: 12px;
}

.profile-menu a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 12px;
    color: black;
    border-bottom: 1px solid #eaeaea;  /* Eine dünne Trennlinie zwischen den Menüpunkten */
    transition: background 0.2s;  /* Sanfter Übergang für den Hover-Effekt */
}

.profile-menu a:last-child {
    border-bottom: none;  /* Keine Trennlinie für den letzten Menüpunkt */
}




/* Weitere Stile nach Bedarf hinzufügen */


@media (max-width: 800px) {
    .dashboard_header_links {
       display: none;
    }
    
}