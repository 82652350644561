.news_page {
    display: flex;
    align-items: center;
    justify-items: top;
    flex-direction: column;
}

.news_page > h2 {
    font-size: 38px;
    font-weight: 500;
    max-width: 500px;
    text-align: center;
    margin: 50px 20px 0px 20px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Vollständige Bildschirmhöhe */
    background-color: rgba(255, 255, 255, 0.8); /* Weißer Hintergrund mit leichter Transparenz */
}

.loading-spinner {
    text-align: center;
    font-size: 1.5em;
    /* Weitere Stile für den Lade-Text oder Spinner */
}

.news_page > p {
    max-width: 500px;
    text-align: center;
    margin-top: 10px;
    font-size: 17px;
    font-weight: 300;
    margin: 20px 20px 0px 20px;
}

.news_header_image {
    width: 90%;
    max-width: 460px;
    border-radius: 20px;
    max-height: 900px;
    margin-top: 30px;
}

.feature > li > img {
    min-width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 10px;
}

.listlist {
    padding-left: 0px;
}

.feature > li {
    display: flex;
    align-items: left;

}

.iframe_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 450px;
    height: 480px;
    border: 0;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 20px;
    margin-top: 50px;
    margin-bottom: 100px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
}

.iframe_wrapper > p {
    width: 100%;
    margin-bottom: -30px;
    margin-top: 40px;
    z-index: 1000;
    text-align: center;
    font-weight: 600;
    max-width: 300px;
    font-size: 24px;
}

.iframe_wrapper > h5 {
    position: absolute;
    top: -15px;
    padding: 5px 10px;
    background-color: white;
    border: 1px solid rgb(206, 206, 206);
    color: var(--blue);
    border-radius: 100px;
    font-size: 13px;
    overflow: hidden; /* Wichtig, um sicherzustellen, dass der Schein innerhalb des Elements bleibt */
}

.iframe_wrapper > h5::after {
    content: "";
    position: absolute;
    top: 0;
    left: -150%; /* Startet außerhalb des Elements */
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.6), transparent);
    animation: shine 2s infinite linear;
}

@keyframes shine {
    to {
        left: 150%; /* Bewegt sich komplett über das Element hinaus */
    }
}


.iframe_box {
    width: 100%;
    height: 600px;
    border: 0;
}

.feature > li > div > h4 {
    font-family: 'Jost';
    font-weight: 600;
    font-size: 18px;
    color: rgb(46, 46, 46);
}

.feature > li > div > p {
    font-weight: 300;
}

.news-feature-list {
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature {
    display: flex;
    align-items: left;
    background-color: rgb(255, 255, 255);
    max-width: 450px;
    margin-top: 30px;
}

.feature > li > div {
    margin-left: 16px;

}


@media (max-width:900px) {

.feature {
    width: 90%;
    margin-top: 50px;
    min-height: 90px;
}

.news-feature-list {
    width: 100%;
    margin-left: 40px;
}

.iframe_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 560px;
    border: 0;
    border-top: 1px solid rgb(224, 224, 224);
    border-radius: 0px;
    margin-top: 50px;
    margin-bottom: 0px;
    margin-top: 60px;
    box-shadow: rgba(17, 12, 46, 0.05) 0px 48px 100px 0px;
        position: relative;
    
}
}