.editor-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    border-radius: 8px;
}

.editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    font-family: 'Jost';
}

.editor-header > h1 {
    font-family: 'Jost';
    margin-top: 0px;
    font-size: 32px;

}

.save-confirmation {
    position: absolute;
    top: 25px; /* oder passen Sie es an Ihre Bedürfnisse an */
    right: 100px; /* rechts neben dem Button */
    transform: translate(-50%, -50%);
    color: rgb(85, 85, 85); /* oder eine Farbe Ihrer Wahl */
    font-size: 15px;
}

.editor-save-btn  {
    background-color: #0077b6;
    color: #ffffff;
    padding: 10px 25px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: -20px;
}

.custom-upload-btn {
    background-color: #0077b6;
    color: #ffffff;
    padding: 10px 25px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 20px;
    margin-bottom: 20px;
}

.editor-add-feature-btn {
    background-color: #0077b6;
    color: #ffffff;
    padding: 10px 25px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 20px;
    margin-bottom: 20px;
}

.editor-save-btn:hover, .editor-add-feature-btn:hover {
    background-color: #005792;
}

.editor-main-image {
    width: 100%;
    max-width:1200px;
    margin-bottom: 20px;
    border-radius: 8px;
    margin-top: -30px;
}

.editor-label {
    font-size: 13px;
    margin-top: 15px;
}

.editor-upload-status {
    color: #0077b6;
    font-style: italic;
}

.editor-upload {
    margin-bottom: 20px;
    padding: 10px 10px;
    background-color: green;
    border-radius: 5px;
}

.editor-upload {
    display: none;
}


.custom-upload-btn:hover {
    background-color: #005792;
}


.editor-fields {
    margin-bottom: 20px;
}

.image_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.editor-input, .editor-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #ffffff;
    transition: border-color 0.2s;
}

.editor-input:focus, .editor-textarea:focus {
    border-color: #0077b6;
    outline: none;
}

.editor-features {
    margin-bottom: 20px;
}

.editor-feature {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    position: relative; 
}

.editor-feature-image {
    width: 100px;
    max-height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

.editor-delete-feature-btn {
    background-color: #ff4d4d;
    color: #ffffff;
    padding: 5px 9px !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.2s;
    width: 85px;
    position: absolute;
    right: 10px;
    font-size: 10px;
    border-radius: 100px;
}

.editor-delete-feature-btn:hover {
    background-color: #cc0000;
}

.feature-upload-button {
    width:400px;
    margin-bottom: 30px;
    margin-top: 20px;
}
