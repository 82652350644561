/* SelectBonus.css */

.select-bonus-container {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
font-family: 'Jost';
}

.spinner {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 29%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}
  
  .bonus-list {
    display: flex;
    justify-content: center;
  }
  
  .bonus-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 2px solid rgb(238, 238, 238);
    padding: 10px 10px 30px 10px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    width: 300px;
    min-height: 400px;
    border-radius: 25px;
    position: relative;
    transition: 200ms ease-in-out;
  }
  
  .bonus-item:hover {
    background-color: #f8f8f8;
    transform: scale(1.01);
  }
  
  .selected {
    border: 2px solid var(--blue);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    }

    .bonus-description {
      font-weight: 400;
      margin: 10px 25px 0px 25px;
      text-align: center;
      line-height: 130%;
      font-size: 16px;
    }

  .bonus-image {
    width: 180px;
    height: 180px;
    border-radius: 100px;
    object-fit: cover;
    margin-bottom: 20px;
    margin-top: 12%;
  }
  
  .bonus-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 5px;
    font-size: 18px;
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    min-height: 40px;
  }
  
  .next-button {
    margin-top: 20px;
    padding: 20px 40px;
    background-color: var(--blue);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  .bonus-type {
    border: 2px solid var(--blue);
    padding: 5px 15px;
    border-radius: 100px;
    margin-top: -22px;
    right: 10px;
    position: absolute;
    font-size: 12px;
    background-color: rgb(235, 235, 235);
  }
 
  


  @media (max-width: 700px) {
    .bonus-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 70px;
    }

    .bonus-image {
      margin-top: 10%;
    }

    .bonus-item {
      width: 80vw;
      min-height: 380px;
      position: relative;
    }

    .bonus-item:last-child {
      margin-bottom: 50px;
    }

  }