/* BackgroundColorChanger.css */
.background-color-changer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.background-color-picker {
    width: 70px !important;
    height: 70px !important;
    margin-top: 10px;
}


.background-color-changer > h3 {
    font-family: 'Jost';
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
}
