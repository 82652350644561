.buy-premium-text-wrapper {
    z-index: 5500;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90vh;
    width: 100%;
}

.buy-premium-text-wrapper > h2 {
    z-index: 1000;
    font-family: 'Jost';
}


.buy-premium-text-wrapper > p {
    z-index: 1000;
    margin-bottom: 20px;
    margin-top: 20px;
    color: rgb(73, 73, 73);
}

.buy-premium-text-wrapper > a {
    z-index: 1000;
}