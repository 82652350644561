.delete_button {
    position: absolute;
    z-index: 100;
    right: 10px;
    top: 10px;
    height: 45px;
    background-color: white;
    border: 1px solid rgb(218, 218, 218);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    opacity: 0;
    transition: 200ms ease-in-out;
    transform: scale(0.6);
}

.delete_logo {
    width: 15px;
    margin-top: 4px;
}


.icon_feature-2:hover .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }

  .icon_feature-3:hover .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }
  .icon_feature-4:hover .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }

  .icon_feature_5:hover .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }
  .icon_feature_6:hover .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }
  .icon_feature_8:hover .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }

  .icon_feature_7:hover .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }

  .top_widget:hover .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }

  .bonus_1:hover .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }

  .end_widget:hover  .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }

  .fancy_bullet:hover  .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }


  .icon_feature:hover .delete_button {
    display: block; /* Button bei Hover sichtbar */
    opacity: 1;
    transform: scale(1);

  }