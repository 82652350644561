.thumbnail-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
}

.thumbnail-grid img {
    width: 100%;
    height: auto;
    height: 500px;
}


.master_dashboard_header > h1 {
    font-family: 'Jost';
    font-size: 40px;
}