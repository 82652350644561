.whatsapp-text-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 25px;
}

.whatsapp-text-modal .whatsapp-text-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.whatsapp-text-modal-content > h2 {
    font-family: 'Jost';
    margin-bottom: 20px;
}

.whatsapp-text-modal-content textarea {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-width: 300px;
}

.whatsapp-text-modal-buttons > button {
    padding: 10px 20px;
    background-color: var(--blue);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 100px;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
}

.whatsapp-text-modal-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.whatsapp-text-modal-buttons button:hover {
    background-color: #0056b3;
}
