.digital-logos {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    border-radius: 20px;
}


.digital-logos > h2 {
    font-family: 'Jost';
}
.logos-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

}

.logo-actions {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.logo-actions > button {
    background: none ;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 5px !important;
    font-size: 14px;
    padding: 4px 10px;
}


.logo-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: column;
    width: 500px;
    border-radius: 15px;
    position: relative;
    border: 1px dashed rgb(221, 221, 221);
}

.logo-item > img {
    margin-right: 10px;
    max-height: 120px;
    object-fit: contain;
    max-width: 500px;
    min-height: 100px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.add-logo {
    margin-top: 20px;
    border: 1px dashed lightgray;
    padding: 10px;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 500px;
}

.add-logo input[type="file"] {
    display: none;
}

.file-input-label {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    font-family: 'Jost';
}

.digitals-logo-preview {
    max-height: 100px;
}

.digitals-plus-icon {
    width: 20px;
    margin-right: 10px;
}

.add-logo select {
    margin-right: 10px;
    padding: 5px;
    margin-top: 10px;
    width: 250px;
    border: 1px solid rgb(219, 219, 219);
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
}

.add-logo button {
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100px;
    height: 50px;
}