.digital-colors {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;

}

.digital-colors > h2 {
    font-family: 'Jost';
}

.colors-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 20px 10px 0px 10px;

}

.color-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid grey;
    width: 120px;;
    border-radius: 15px;
    margin: 0 10px 15px 10px;
    position: relative;
    border: 1px dashed rgb(167, 167, 167);
    padding: 10px;
    height: 195px;
}

.color-preview {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px dashed rgb(167, 167, 167);
    margin-bottom: 10px;
    margin-top: 10px;
}

.color-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.color-info > p:nth-child(2) {
    margin-top: 20px;
    font-weight: 600;

}

.colors-delete-icon {
    position: absolute;
    top: 10px;
    right: 30px;
    cursor: pointer;
}

.branding-delete-button {
    width: 30px;
    height: 30px;
}

.add-color {
    margin-top: 20px;
    border: 1px dashed lightgray;
    padding: 10px;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 500px;
}

.add-color-nema {
    margin-top: 18px;
    border: 1px dashed lightgray;
    display: flex;
    border-radius: 15px;
    font-family: 'Jost';
    height: 60px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: 500px;
}

.color-picker {
    width: 53px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    appearance: none;
    -webkit-appearance: none;
    background-color: #ffffff; /* Ensure the color picker has a visible background */
    outline: none;
}

.color-picker::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 50%;
}

.color-picker::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}

.file-input-label {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    font-family: 'Jost';
}

.colors-plus-icon {
    width: 20px;
    margin-right: 10px;
}

.color-hex-input {
    margin-right: 10px;
    padding: 5px;
    margin-top: 10px;
    width: 100px;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
}

.add-color input[type="text"] {
    margin-right: 10px;
    padding: 5px;
    margin-top: 10px;
    width: 250px;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
}

.add-color button {
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100px;
    height: 50px;
}
