.checkout-button {
    position: relative;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 600;
    color: var(--blue);
    background: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: none;
    border-radius:10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkout-button:disabled {
    cursor: not-allowed;
  }
  
  .button-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-left-color: var(--blue);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  .premium-icon-bottom  {
      width: 18px;
      margin-left: 5px;
  }