.db-search-container {
    display: flex;
    align-items: center;
    justify-content: center;
}



.db-date {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100px;
    border-radius: 100px;
    background-color: rgb(255, 255, 255) !important;
    font-size: 12px;
    height: 39px;
    border: none; 
    background: transparent; 
    cursor: pointer; /* Zeigen Sie den Hand-Cursor an, um zu signalisieren, dass darauf geklickt werden kann */
    

}



.db-date-container > img {
    width: 15px !important;
    margin-left: 15px;
    opacity: 0.4;
    margin-right: -10px;
    
}

.db-date-container {
    display: flex;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 100px;
    margin-left: 10px;
}

.db-date::-webkit-calendar-picker-indicator {
    display: none; /* Für WebKit-Browser */
}


.db-search {
    width: 40vw;
    border-radius: 100px;
    margin-right: 5px;
    height: 35px;
    border: none; 
}


.fake-search-bar {
    display: flex;
    border: 1px solid rgb(218, 218, 218);
    padding: 2px 13px;
    margin-left: 30px;
    border-radius: 100px;

}

.fake-search-bar > input::placeholder {
    color: black;
}

.fake-search-bar > img {
    width: 15px !important;
    margin-left: 5px;
    margin-right: 15px;
    opacity: 0.3;
}



@media (max-width: 800px) {
    .db-search-container {
        display: none;
    }
    
    
}