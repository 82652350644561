.chat-window {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 70%;
    padding: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;    margin-top: 20px;
    border-radius: 25px;
}

.messages-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0px 0;
}

.messages-list::-webkit-scrollbar {
    display: none; /* Für WebKit-Browser wie Chrome und Safari */
}


.message {
    display: flex;
    margin-bottom: 10px;
}

.message.right {
    justify-content: flex-end;
}

.message.left {
    justify-content: flex-start;
}

.chat-profile-image{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.bubble {
    max-width: 400px;
    background-color: #dddddd4b;
    padding: 5px 10px;
    border-radius: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    position: relative;
}

.message.right .bubble {
    background-color: var(--blue);
    color: white;
    border-bottom-right-radius: 0px !important;
    text-align: left;

}

.message.left .bubble {
    border-bottom-left-radius: 0px !important;
    text-align: left;

}

.bubble > img {
    width: 400px !important;
    max-height: 500px;
    object-fit: cover;
    border-radius: 10px;
}


.bubble > p {
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: 7px;
    margin-right: 7px;
}



.chat-message-input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 10px;
}


.chat-message-input textarea {
    width: 100%;
    border-radius: 15px;
    background-color: var(--black);
    padding: 15px 150px 15px 15px;
    color: black !important;
    outline: none;
    font-family: 'Jost';
    font-size: 16px;
    resize: none; /* or vertical, both, none */

}

.chat-message-input input {
    flex-grow: 1;
    padding: 8px;
    margin-right: 10px;
    width: 100%;
    background: grey;
}

.chat-image-upload {
    position: absolute;
    right: 115px;
    bottom: 18px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: none;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-image-upload:hover > img {
    opacity: 1;
    cursor: pointer;
}

.chat-image-upload > img {
    filter: invert(1);
    opacity: 0.8;
    user-select: none;
    width: 20px;
}

.chat-profile-image.right {
    margin-left: 10px;
    margin-right: 0px;
}


.chat-image-send-button {
    margin-left: 10px;
    height: 40px !important;
    border-radius: 100px;
    border: none;
    outline: none;
    background-color: var(--black);
    display: flex !important;
    align-items: center;
    rotate: 45deg;
    user-select: none;
    justify-content: center;
    transition: background-color 0.3s ease; /* Übergang für die Hintergrundfarbe */
}

.chat-image-send-button:hover {
    cursor: pointer;
    background-color: var(--black-hover); /* Hintergrundfarbe ändert sich beim Hovern */
}

.chat-image-send-button:active {
    transform: scale(0.9); /* Skalierungseffekt beim Klicken */
}

.chat-image-send-button > img {
    width: 25px !important;
}


.load-more-btn {
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
    background-color: var(--black);
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    font-family: 'Jost';
    text-transform: uppercase;
    color: rgb(187, 187, 187);
}

.read-status {
    position: absolute;
    bottom: -22px;
    font-size: 13px;
    right: 0;
}

.new-message-text {
    margin-top: 0px;
}

.new-order-bubble > img {
    max-width: 400px;
    border-radius: 10px;
    object-fit: cover;
    max-height: 700px;
    margin-bottom: 20px;
}


.new-order-bubble  {
    width: 100% !important;
    text-align: center;
    border-radius: 25px;
    display: flex;
    align-items: center;
    background-color: rgba(245, 245, 245, 0.644);
    justify-content: center;
    flex-direction: column;
    padding: 30px 15px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.new-order {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.delivery-info {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-right: 10px;
}

.strategy-call-info{
    display: flex;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.strategy-call-info > p {
    color: rgb(128, 128, 128);
}

.strategy-call-info > img {
    width: 15px;
    opacity: 0.5;
    margin-right: 10px; 
}

.delivery-info > img {
    width: 20px;
    margin-right: 10px; 
    opacity: 0.5;
    
}

.delivery-info > p  {
    color: rgb(128, 128, 128);
}


.new-order > h3 {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Jost';
}

.new-order-top-bar > p > strong > img {
    width: 15px !important;
    margin-right: 10px;
    margin-top: 5px;
    filter: invert(1);
}



@keyframes goldShimmer {
    0% {
        background-position: -100%;
    }
    100% {
        background-position: 200%;
    }
}

@keyframes shimmer {
    0% {
      background-position: -100%;
    }
    100% {
      background-position: 100%;
    }
  }
  
  .new-order-top-bar > h3 {
    font-family: 'Jost';
    font-size: 24px;
    margin-top: 20px;
    border-top: 1px solid rgb(221, 221, 221);
    padding-top: 10px;
    margin-bottom: 20px;
    background: linear-gradient(90deg, rgb(0, 0, 0), var(--blue), rgb(0, 0, 0));
    background-size: 200% auto;
    color: black;
    animation: shimmer 2s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  

.delivery-truck-bar {
    display: flex;
    color: white;
    font-size: 13px;
    align-items: center;
    background-color: var(--blue);
    width: fit-content;
    justify-content: center;
    padding: 2px 10px;
    border-radius: 10px;
    
}


@media (max-width: 1000px) {
    .chat-image-upload {
        position: absolute;
        right: 95px !important;
        bottom: 18px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        border: none;
        background-color: var(--black);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 700px) {
    .chat-message-input textarea {
        padding: 15px 15px 15px 15px;
    }

    .superchat-open-button {
        display: none !important;
    }

    .chat-image-upload {
        right: 60px !important; 
    }

    .chat-prev-upladed-img {
        right: 60px;
        bottom: 8px;
    }

    .chat-image-upload {
        display: none;
    }

    .bubble > img {
        width: 150px !important;
        max-height: 200px;
        object-fit: cover;
        border-radius: 10px;
    }
    
    
}