.widget-editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.widget-editor > h2 {
    font-family: 'Jost';
}

.widget-color-previews {
    display: flex;
    margin-left: 10px;
}

.widget-color-preview {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 5px 5px 0px 0px;
}