.style-filter-scrollbox {
    display: flex;
    overflow-x: auto !important;
    white-space: nowrap !important;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 10px 10px 10px;
    background-color: rgb(255, 255, 255);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border: 1px solid rgb(238, 238, 238);
    overflow: hidden;
  }

  .iconPicker {
      width: 100%;
  }
  
  .style-filter-button {
    flex: 0 0 auto;
    margin-right: 5px;
    padding: 5px 10px;
    background-color: #ffffff !important;
    border: 1px solid #d0d0d0;
    cursor: pointer;
  }
  
  .style-filter-button:hover {
    background-color: #e0e0e0;
  }
  
  /* Falls bereits vorhanden, lassen Sie diese Stile unverändert */
  .icon-picker-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 140px; /* Statt max-height, zum Testen */
    overflow-y: auto;
    background-color: rgb(250, 250, 250);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: inset 0 -10px 10px -10px rgba(0,0,0,0.2);

  }

  .animation-icon {
    width: 70px !important; /* Größere Breite für animierte Icons */
    height: auto; /* Erhält das Seitenverhältnis */
  }
  
  .icon-style {
    width: 40px;
    margin: 5px;
    cursor: pointer;
    border-radius: 10px; /* Markieren Sie das ausgewählte Icon */
  }
  
  .icon-style.selected {
    border: 1px solid rgb(216, 216, 216);
    border-radius: 10px; /* Markieren Sie das ausgewählte Icon */
  }
  
  .icon-picker-container::-webkit-scrollbar {
      display: none;
  }

  .style-filter-container::-webkit-scrollbar {
    height: 8px;
    display: none !important;

  }

  
  .style-filter-container::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 4px;
  }
  