/* AddService.css */

.add-service-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.add-service-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.add-service-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

.form-input, .form-textarea, .form-select, .form-file-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-input:focus, .form-textarea:focus, .form-select:focus {
    border-color: #007bff;
    outline: none;
}

.form-textarea {
    resize: vertical;
    height: 100px;
}

.add-feature-button, .add-delivery-button, .submit-button, .add-image-button, .remove-image-button {
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.add-feature-button:hover, .add-delivery-button:hover, .submit-button:hover, .add-image-button:hover, .remove-image-button:hover {
    background-color: #0056b3;
}

.image-preview-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.image-preview-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
}

.image-preview-wrapper img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 10px;
}

.image-preview-wrapper .remove-image-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff0000;
    padding: 5px 10px;
}

.add-image-button {
    margin-top: 10px;
}

.delivery-option {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}


.uploader-profile-placeholder-2 {
    padding: 20px;
    background-color: #007bff;
    color: white;
    width: fit-content;
}