.marketing-header {
    text-align: left;
    margin-bottom: 20px;
}

.marketing-header h1 {
    font-size: 42px;
    margin-bottom: 5px;
    font-family: 'Jost';
    font-weight: 500;
}

.marketing-header p {
    font-size: 18px;
    color: #666;
}
